import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useContext } from "react";
import { DocumentCreatorContext } from "../../../context";
import { DocumentCreatorPayloadTypes } from "../../../context/actions";
import { FormNode, NodeType } from "@/apollo/queries/forms/formTypes";
import InputForm from "./InputForm";
import CreateIcon from "@mui/icons-material/Create";

type InputProps = {
  preloadedData?: FormNode;
};

export type InputNodeInputProps = {
  extendsDocumentName: {
    format: string;
    enabled: boolean;
  };
};

export function isInputNode(formNode: FormNode) {
  return formNode.type === NodeType.Input;
}

export default function Input({ preloadedData }: InputProps) {
  const { dispatch: documentCreatorDispatch } = useContext(
    DocumentCreatorContext
  );

  const addQuestionToDocument = () => {
    documentCreatorDispatch({
      type: DocumentCreatorPayloadTypes.TOGGLE_INPUT_MODAL,
      payload: {
        toggleInputModal: true,
      },
    });

    documentCreatorDispatch({
      type: DocumentCreatorPayloadTypes.SET_INPUT_MODAL_CHILDREN,
      payload: {
        inputModalChildren: <InputForm preloadedData={preloadedData} />,
      },
    });
  };

  const label = preloadedData?.variableName || "Pole wejściowe";

  return (
    <ListItemButton onClick={addQuestionToDocument}>
      <ListItemIcon>
        <CreateIcon />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
}
