import FormContainer from "@/components/utils/Form/formContainer";
import { FormData } from "@/utils/form";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import useDocumentCreator from "../../../hooks/useDocumentCreator";
import {
  FormNode,
  FormNodeField,
  NodeType,
} from "@/apollo/queries/forms/formTypes";
import Form from "@/components/utils/Form";
import { FormState } from "@/components/utils/Form/form";
import { CompanyTypes } from "@/apollo/queries/user/types";

type CompanyInsertFormProps = {
  preloadedData?: FormNode;
};

export default function CompanyInsertForm({
  preloadedData,
}: CompanyInsertFormProps) {
  const { addDocumentCreatorFormNodes, documentCreatorState } =
    useDocumentCreator();

  const [formNodeField, setFormNodeField] = useState<FormNodeField>(
    new FormNodeField({ label: "", value: "" })
  );

  useEffect(() => {
    if (preloadedData?.fields) {
      const field = preloadedData.fields[0];
      setFormNodeField(field);
    }
  }, [preloadedData]);

  const handleOnFormChange = useCallback(
    (formState: FormState) => {
      setFormNodeField(new FormNodeField({ ...formNodeField, ...formState }));
    },
    [formNodeField]
  );

  const parseData = (data: FormData): FormNode[] => {
    const dataSelectedToImport: string[] = [];
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        dataSelectedToImport.push(key);
      }
    });

    return dataSelectedToImport.map(
      (key) =>
        new FormNode({
          id: key,
          type: NodeType.CompanyInsert,
          variableName: `Company.${key}`,
        })
    );
  };

  const isCorporationDocument =
    documentCreatorState.targetCompanyType === CompanyTypes.CORPORATION;

  const fields: Array<[string, string, boolean?]> = useMemo(
    () => [
      ["Nazwa firmy", "companyName"],
      ["NIP", "nip"],
      ["REGON", "regon"],
      ["KRS", "krs", !isCorporationDocument],
      ["Ulica", "street"],
      ["Nr budynku", "buildingNumber"],
      ["Nr lokalu", "apartmentNumber"],
      ["Kod pocztowy", "zip"],
      ["Miasto", "city"],
      ["Reprezentanci", "representatives", !isCorporationDocument],
      ["Imię", "name"],
      ["Nazwisko", "lastName"],
      ["Kapitał zakładowy", "shareCapital", !isCorporationDocument],
      ["Sąd rejestracyjny", "registrationCourt", !isCorporationDocument],
    ],
    [isCorporationDocument]
  );

  return (
    <Form
      onSubmit={(formData) => {
        const parsedData = parseData(formData);
        addDocumentCreatorFormNodes(parsedData);
        // if (preloadedData?.id) {
        //   updateDocumentCreatorFormNodes(parsedData);
        // } else {
        // }
      }}
      onChange={handleOnFormChange}
    >
      <FormContainer>
        <Box>
          <Typography sx={{ marginBottom: 2 }} variant="h2">
            Importuj dane firmy
          </Typography>
          <Box
            sx={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}
          >
            {fields.map(
              (field) =>
                !field[2] && (
                  <FormControlLabel
                    label={field[0]}
                    control={<Checkbox id={field[1]} />}
                  />
                )
            )}
          </Box>
        </Box>
        <Button type="submit">Importuj</Button>
      </FormContainer>
    </Form>
  );
}
