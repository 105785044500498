import { CONFIRM_USER_ACCOUNT } from "@/apollo/queries/user/userQuery";
import routes from "@/router/routes";
import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AccountConfirmationPage() {
  const url = new URL(window?.location.href);
  const navigate = useNavigate();
  const [confirmUserAccount] = useMutation(CONFIRM_USER_ACCOUNT);
  const params = new URLSearchParams(url.search);
  const token = params.get("t");

  const onSubmit = async () => {
    try {
      await confirmUserAccount({
        variables: {
          token,
        },
      });
      navigate(routes.signIn);
    } catch (e) {
      toast.error("Aktywacja konta nie powiodła się.");
    }
  };

  return (
    <Card sx={{ minHeight: "4rem", position: "relative", margin: "2rem" }}>
      <CardHeader title="Potwierdzenie konta" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {token ? (
            <>
              <Typography>Potwierdz konto swoje konto</Typography>
              <Button variant="contained" onClick={onSubmit}>
                Potwierdź
              </Button>
            </>
          ) : (
            <>
              <Link to={routes.signIn}>
                <Typography>Wróć do strony głównej.</Typography>
              </Link>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
