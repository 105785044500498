import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useDocumentCreator from "../hooks/useDocumentCreator";
import { CompanyTypes } from "@/apollo/queries/user/types";
import { LegalDocument } from "@/apollo/queries/documents/types";

export default function DocumentCreatorSettings_CompanyType({
  preloadedData,
}: {
  preloadedData?: LegalDocument;
}) {
  const { setDocumentTargetCompanyType } = useDocumentCreator();

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="company-type">Rodzaj przedsiębiorstwa</InputLabel>
      <Select
        label="Rodzaj przedsiębiorstwa"
        labelId="package-label"
        defaultValue={
          preloadedData?.targetCompanyType || CompanyTypes.SOLE_PROPRIETORSHIP
        }
        onChange={(event) => {
          const value = event.target.value as CompanyTypes;
          setDocumentTargetCompanyType(value);
        }}
      >
        <MenuItem value={CompanyTypes.SOLE_PROPRIETORSHIP}>Firma</MenuItem>
        <MenuItem value={CompanyTypes.CORPORATION}>Spółka</MenuItem>
      </Select>
    </FormControl>
  );
}
