import React, {
  createContext,
  useReducer,
  Dispatch,
  PropsWithChildren,
} from "react";
import {
  MenuDrawerReducerActions,
  menuDrawerReducer,
} from "./menuDrawerReducer";

export type MenuDrawerContextInitialState = {
  toggled: boolean;
};
const initialState: MenuDrawerContextInitialState = {
  toggled: false,
};

const MenuDrawerContext = createContext<{
  state: MenuDrawerContextInitialState;
  dispatch: Dispatch<MenuDrawerReducerActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const MenuDrawerContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(menuDrawerReducer, initialState);

  return (
    <MenuDrawerContext.Provider value={{ state, dispatch }}>
      {children}
    </MenuDrawerContext.Provider>
  );
};

export { MenuDrawerContextProvider, MenuDrawerContext };
