import { LegalDocument } from "@/apollo/queries/documents/types";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useDocumentCreator from "../hooks/useDocumentCreator";

export default function DocumentCreatorSettings_PackageRank({
  preloadedData,
}: {
  preloadedData?: LegalDocument;
}) {
  const { setDocumentPackageRank } = useDocumentCreator();

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="package-label">Pakiet</InputLabel>
      <Select
        label="Pakiet"
        labelId="package-label"
        defaultValue={preloadedData?.packageRank || 1}
        onChange={(event) => {
          const value = event.target.value as number;
          setDocumentPackageRank(value);
        }}
      >
        <MenuItem value={1}>Pakiet 1</MenuItem>
        <MenuItem value={2}>Pakiet 2</MenuItem>
        <MenuItem value={3}>Pakiet 3</MenuItem>
      </Select>
    </FormControl>
  );
}
