import {
  GET_PRODUCTS,
  GetProducts,
} from "@/apollo/queries/products/productsQuery";
import { useQuery } from "@apollo/client";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Spinner from "../../Spinner/spinner";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import routes from "@/router/routes";

export default function PackagesList() {
  const { data, loading } = useQuery<GetProducts>(GET_PRODUCTS, {
    fetchPolicy: "cache-and-network",
  });
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      <Spinner loading={loading} data={data} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {data?.products.map((product) => (
          <Box
            key={product.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              padding: "1rem",
              background: grey[50],
              borderRadius: ".5rem",
              border: "1px solid #c9c9c9",
              boxShadow: "0 0 10px #00000015",
              width: {
                xs: "100%",
              },
            }}
          >
            <Box>
              <Typography variant="h5">Pakiet {product.packageRank}</Typography>
              <Typography variant="caption">{product.name}</Typography>
              <Box>
                <Typography variant="caption">
                  {`${product.price.monthly}zł miesiecznie, `}
                </Typography>
                <Typography variant="caption">
                  {`${product.price.annual}zł rocznie`}
                </Typography>
              </Box>
              <Typography>{product.shortDescription}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={() =>
                  navigate(routes.client.packages.slug.make(product.slug))
                }
              >
                Kup
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
}
