import {
  GET_LEGAL_DOCUMENTS_GROUPED_BY_CATEGORY,
  GetLegalDocumentsGroupedByCategory,
} from "@/apollo/queries/documents/documentsQuery";
import { LegalDocument } from "@/apollo/queries/documents/types";
import Spinner from "@/components/Spinner/spinner";
import ExpandSubscriptionPackagesList from "@/components/lists/packages/ExpandSubscriptionPackagesList";
import PackagesList from "@/components/lists/packages/PackagesList";
import { UserContext } from "@/context/User/userContext";
import routes from "@/router/routes";
import { PageName } from "@/utils/app";
import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default function ClientDocumentsPage() {
  const [isSubscribeModalToggled, toggleSubscribeModal] = useState(false);
  const [isExtendedSubscriptionModalToggled, toggleExtendSubscriptionModal] =
    useState(false);
  const { state: userState } = useContext(UserContext);
  const { data, loading } = useQuery<GetLegalDocumentsGroupedByCategory>(
    GET_LEGAL_DOCUMENTS_GROUPED_BY_CATEGORY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const navigate = useNavigate();

  const createDocument = useCallback(
    async (id: string) => {
      try {
        navigate(routes.client.forms.id.make(id));
      } catch (e) {
        console.error(e);
      }
    },
    [navigate]
  );

  const handleCreateDocumentButton = useCallback(
    (legalDocument: LegalDocument) => {
      if (userState?.subscription) {
        if (
          userState?.subscription.product.packageRank <
          legalDocument.packageRank
        ) {
          return toggleExtendSubscriptionModal(true);
        }

        return createDocument(legalDocument.id);
      } else {
        return toggleSubscribeModal(true);
      }
    },
    [createDocument, userState?.subscription]
  );

  return (
    <Box>
      <PageName>Przeglądaj</PageName>
      <Spinner loading={loading} data={data} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {data?.legalDocumentsGroupedByCategory.categories.map((categories) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="h2">{categories.category.label}</Typography>
              <Grid container spacing={2}>
                {categories.legalDocuments.map((legalDocument) => (
                  <Grid
                    item
                    key={legalDocument.id}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={3}
                  >
                    <Box
                      sx={{
                        padding: "1rem",
                        background: "white",
                        boxShadow: "0 0 10px #0000000a",
                        borderRadius: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        minHeight: "9rem",
                        maxHeight: "9rem",
                      }}
                      gap={1}
                    >
                      <Typography variant="h6">{legalDocument.name}</Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          maxHeight: "100%",
                          overflow: "hidden",
                        }}
                      >
                        {legalDocument.description}
                      </Typography>
                      <Box sx={{ gap: 1, display: "flex" }}>
                        <Button
                          onClick={() =>
                            handleCreateDocumentButton(legalDocument)
                          }
                          variant="contained"
                        >
                          Utwórz
                        </Button>
                        {userState?.subscription && (
                          <Button
                            onClick={() =>
                              navigate(
                                routes.client.documents.id.make(
                                  legalDocument.id
                                )
                              )
                            }
                            variant="outlined"
                          >
                            Twoje dokumenty
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          );
        })}
      </Box>
      <Modal
        open={isSubscribeModalToggled}
        onClose={() => toggleSubscribeModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            display: "flex",
            alignSelf: "center",
            justifySelf: "center",
            padding: "1rem",
            borderRadius: "1rem",
            height: {
              md: "auto",
              xs: "90%",
            },
            maxHeight: "90%",
            width: {
              xs: "80%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "2.5rem",
              flexDirection: "column",
              padding: "1rem",
              width: "100%",
              overflowY: "scroll",
            }}
          >
            <Typography variant="h1">Dostępne pakiety</Typography>
            <PackagesList />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={isExtendedSubscriptionModalToggled}
        onClose={() => toggleExtendSubscriptionModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            display: "flex",
            alignSelf: "center",
            justifySelf: "center",
            padding: "1rem",
            borderRadius: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "2.5rem",
              flexDirection: "column",
              padding: "1rem",
            }}
          >
            <Typography variant="h1">Poszerz subskrypcję</Typography>
            <ExpandSubscriptionPackagesList />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
