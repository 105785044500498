import { useEffect, useState, useContext } from "react";
import { jsPDF } from "jspdf";
import { $isRootTextContentEmpty } from "@lexical/text";
import { $generateHtmlFromNodes } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Button } from "@mui/material";
import {
  EXPORT_GENERATED_DOCUMENT,
  ExportGeneratedDocument,
} from "@/apollo/queries/generatedDocuments/generatedDocumentsQuery";
import { useMutation } from "@apollo/client";
import { GeneratedDocument } from "@/apollo/queries/generatedDocuments/types";
import { LexicalEditorContext } from "@/context/LexicalEditor/context";
import classes from "./reader.module.scss";
// const saveAs = require("save-file");
import saveFile from "save-file";
import { toast } from "react-toastify";
import { ConsolasHex } from "@/jsPDF/font";

export type ReaderProps = {
  generatedDocumentId?: string;
  documentId?: string;
  json?: string;
  html?: string;
} & GeneratedDocument;

export default function Reader(data: ReaderProps): JSX.Element {
  const id = data?.generatedDocumentId ?? data?.documentId;
  const [HTML, setHTML] = useState("");
  const { state, dispatch } = useContext(LexicalEditorContext);
  const [exportGeneratedDocument, { data: exportedGeneratedDocument }] =
    useMutation<ExportGeneratedDocument>(EXPORT_GENERATED_DOCUMENT);
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor?.update(() => {
      if (!$isRootTextContentEmpty(false) || !data.json) return;
      const editorStateFromJson = editor.parseEditorState(data.json);
      editor.setEditorState(editorStateFromJson);

      editorStateFromJson.read(() => {
        setHTML($generateHtmlFromNodes(editor));
      });
    });
  }, [data, editor]);

  const saveToDoc = async (format: string) => {
    try {
      const exportDocument = await exportGeneratedDocument({
        variables: {
          exportGeneratedDocumentInput: {
            id,
            html: HTML,
            format,
          },
        },
      });

      const file = exportDocument.data?.exportGeneratedDocument.file;
      if (!file) return;

      saveFile(JSON.parse(file), data.legalDocument.name + "." + format);
      toast.success(`Zapisano plik ${data.legalDocument.name}.${format}`, {
        autoClose: 5000,
      });
    } catch (e) {
      toast.error("Nie udało się zapisać pliku.");
    }
  };

  const saveToPDF = async () => {
    try {
      const semiHtml = document.createElement("html");
      semiHtml.innerHTML = `<div 
        style="
          width: 543px;
          font-size: 11px;
          font-family: Arial;
          letter-spacing: 0.2px;
        ">${HTML}</div>`;
      const paragraphs = semiHtml.getElementsByTagName("span");
      Array.from(paragraphs).forEach((paragraph) => {
        const fontSizeWithUnits = paragraph.style.fontSize;
        if (!fontSizeWithUnits) return;
        const size = Number(fontSizeWithUnits.split("px")[0]);
        paragraph.style.fontSize = size - 5 + "px";
      });
      const doc = new jsPDF({ format: "a4", unit: "pt", precision: 100 });
      doc.html(semiHtml.innerHTML, {
        callback: (doc) => doc.save(`${data.legalDocument.name}.pdf`),
        margin: [30, 40],
        autoPaging: "text",
        fontFaces: [
          {
            family: "Arial",
            src: [
              {
                format: "truetype",
                url: "/fonts/arial.ttf",
              },
            ],
            style: "normal",
          },
        ],
      });
      toast.success(`Zapisano plik ${data.legalDocument.name}.pdf}`, {
        autoClose: 5000,
      });
    } catch (e) {
      console.log(e);
      toast.error("Nie udało się zapisać pliku.");
    }
  };

  return (
    <div className={classes.root}>
      <div
        dangerouslySetInnerHTML={{
          __html: HTML,
        }}
      />
      <div className={classes.saveButtons}>
        <Button onClick={() => saveToDoc("docx")} variant="outlined">
          Zapisz do .doc
        </Button>
        <Button onClick={saveToPDF} variant="outlined">
          Zapisz do .pdf
        </Button>
      </div>
    </div>
  );
}
