import { FormConfig } from "../forms/formTypes";
import { CompanyTypes } from "../user/types";

export class CreateDocumentInput {
  [key: string]: any;
  name: string;
  description: string;
  packageRank: number;
  targetCompanyType: CompanyTypes;
  html: string;
  json: string;
  form: FormConfig;
  isPublic: boolean;

  constructor({
    name,
    html,
    form,
    json,
    description,
    packageRank,
    targetCompanyType,
    isPublic,
  }: CreateDocumentInput) {
    this.name = name;
    this.description = description;
    this.packageRank = packageRank;
    this.targetCompanyType = targetCompanyType;
    this.html = html;
    this.form = new FormConfig(form);
    this.json = json;
    this.isPublic = isPublic;
  }
}

export class UpdateDocumentInput extends CreateDocumentInput {
  id: string;

  constructor({ id, ...input }: UpdateDocumentInput) {
    super(input);
    this.id = id;
  }
}

export class FindDocumentInput {
  id?: string;

  constructor({ id }: FindDocumentInput) {
    this.id = id;
  }
}
