import {
  GET_PRODUCTS,
  GetProducts,
} from "@/apollo/queries/products/productsQuery";
import { useQuery } from "@apollo/client";
import { Badge, Box, Button, Typography, useTheme } from "@mui/material";
import Spinner from "../../Spinner/spinner";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { UserContext } from "@/context/User/userContext";
import { useContext, useMemo } from "react";
import routes from "@/router/routes";

export default function ExpandSubscriptionPackagesList() {
  const { state: userState } = useContext(UserContext);
  const { data, loading } = useQuery<GetProducts>(GET_PRODUCTS, {
    fetchPolicy: "cache-and-network",
  });
  const navigate = useNavigate();
  const theme = useTheme();

  const filteredProducts = useMemo(() => {
    if (!userState?.subscription || !data?.products) return;
    console.log(data?.products);
    console.log(userState?.subscription);
    return data?.products.filter(
      (product) =>
        product.packageRank > userState?.subscription?.product?.packageRank
    );
  }, [data?.products, userState?.subscription]);

  const userProduct = useMemo(
    () => userState?.subscription.product,
    [userState?.subscription.product]
  );

  if (!userProduct) {
    return <Typography>Nie posiadasz żadnego produktu.</Typography>;
  }

  return (
    <>
      <Spinner loading={loading} data={data} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        {filteredProducts?.map((product) => (
          <Box
            key={product.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              padding: "1rem",
              background: grey[50],
              borderRadius: ".5rem",
              border: "1px solid #c9c9c9",
              boxShadow: "0 0 10px #00000015",
            }}
          >
            <Box sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
              <Typography variant="h5">Pakiet {product.packageRank}</Typography>
              <Typography variant="caption">{product.name}</Typography>
              <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
                <Box>
                  <Typography variant="caption">
                    <Badge
                      badgeContent={`${-userProduct.price.monthly}zł`}
                      color="info"
                    >
                      {`${
                        product.price.monthly - userProduct.price.monthly
                      }zł miesiecznie`}
                    </Badge>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption">
                    <Badge
                      badgeContent={`${-userProduct.price.annual}zł`}
                      color="info"
                    >
                      {`${
                        product.price.annual - userProduct.price.annual
                      }zł rocznie`}
                    </Badge>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Button
              variant="contained"
              onClick={() =>
                navigate(routes.client.packages.slug.make(product.slug))
              }
            >
              Kup
            </Button>
          </Box>
        ))}
      </Box>
    </>
  );
}
