import DropDown, { DropDownItem } from "@/components/Lexical/ui/DropDown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNodeByKey, $getSelection } from "lexical";
import { $isTableCellNode } from "@/components/Lexical/packages/customizableTable";
import DropdownColorPicker from "@/components/Lexical/ui/DropdownColorPicker";

export default function CustomizableTablePluginToolbarFeatures_Dropdown() {
  const [editor] = useLexicalComposerContext();

  return (
    <>
      <DropDown
        buttonClassName="toolbar-item spaced"
        buttonLabel=""
        buttonAriaLabel="Formatting options for additional text styles"
        buttonIconClassName="icon dropdown-more"
      >
        <DropdownColorPicker
          buttonClassName="toolbar-item color-picker"
          buttonAriaLabel="Formatting text color"
          buttonIconClassName="icon font-color"
          color={"000fff"}
          onChange={(value: string) => {
            editor.update(() => {
              const selection = $getSelection();
              if (!selection) return;
              const nodes = selection.getNodes();
              for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];
                if ($isTableCellNode(node)) {
                  node.setBorderStyle({ color: value });
                }
              }
            });
          }}
          title="text color"
        />
      </DropDown>
    </>
  );
}

export function CustomizableTablePluginToolbarFeature_Color() {
  const [editor] = useLexicalComposerContext();

  return (
    <DropdownColorPicker
      buttonClassName="toolbar-item color-picker"
      buttonAriaLabel="Formatting text color"
      buttonIconClassName="icon font-color"
      color={"000fff"}
      onChange={(value) => {
        editor.update(() => {
          const selection = $getSelection();
          if (!selection) return;
          const nodes = selection.getNodes();
          for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            if (node.__parent) {
              node = $getNodeByKey(node.__parent) ?? nodes[i];
            }
            if ($isTableCellNode(node)) {
              node.setBorderStyle({ color: value });
            }
          }
        });
      }}
      title="text color"
    />
  );
}
