import { FormNode } from "@/apollo/queries/forms/formTypes";
import { TextField } from "@mui/material";

export default function Input({ fields, errors }: FormNode) {
  if (!fields) return null;
  const { id, label, value } = fields[0];
  return (
    <TextField
      id={id}
      label={label}
      defaultValue={value}
      error={!!errors?.get(id)}
      helperText={errors?.get(id)}
    />
  );
}
