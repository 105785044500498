import classes from "./styles.module.scss";

export default function Spinner({
  loading,
  data,
}: {
  loading?: boolean;
  data?: any;
}) {
  if (loading !== undefined) {
    if (!loading || !!data) return null;
  }

  const color = "rgb(33, 150, 243)";

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 10,
      }}
    >
      <div className={classes.ldsRing}>
        <div
          style={{
            borderColor: `${color} transparent transparent transparent`,
          }}
        ></div>
        <div
          style={{
            borderColor: `${color} transparent transparent transparent`,
          }}
        ></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
