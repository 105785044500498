import {
  DOWNLOAD_INVOICE,
  DownloadInvoice,
  GET_PAYMENT_TRANSACTIONS,
  GetPaymentTransactions,
} from "@/apollo/queries/paymentTransactions/paymentTransactionsQuery";
import Spinner from "@/components/Spinner";
import { getFormattedDate } from "@/utils/date";
import { useMutation, useQuery } from "@apollo/client";
import { Button, List, ListItem, ListItemText } from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import saveFile from "save-file";

export default function UserTransactionsList() {
  const params = useParams();
  const queryVariables = params.id
    ? { getPaymentTransactionsInput: { id: params.id } }
    : undefined;
  const { data, loading } = useQuery<GetPaymentTransactions>(
    GET_PAYMENT_TRANSACTIONS,
    {
      variables: queryVariables,
    }
  );

  const [
    downloadInvoice,
    { loading: downloadInvoiceLoading, data: downloadInvoiceData },
  ] = useMutation<DownloadInvoice>(DOWNLOAD_INVOICE);

  const saveInvoice = async (row: any) => {
    try {
      const { data } = await downloadInvoice({
        variables: {
          downloadInvoiceInput: {
            paymentTransactionId: row.id,
          },
        },
      });
      if (!data) return;
      const fileData = JSON.parse(data?.downloadInvoice.file);
      toast.success(`Faktura ${fileData.fileName} została zapisana!`, {
        autoClose: 5000,
      });
      saveFile(fileData.buffer.data, fileData.fileName);
    } catch (e) {
      toast.error(
        "Nie udało się pobrać faktury. Odczekaj chwilę lub skontaktuj się z nami.",
        {
          autoClose: 8000,
        }
      );
    }
  };

  const rows = data?.paymentTransactions || [];

  return (
    <List>
      <Spinner loading={loading} data={data} />
      <Spinner loading={downloadInvoiceLoading} />
      <ListItem sx={{ gap: "2%", userSelect: "none", pointerEvents: "none" }}>
        <ListItemText
          sx={{
            width: "15%",
            display: {
              xs: "none",
              md: "initial",
            },
          }}
        >
          Created at
        </ListItemText>
        <ListItemText sx={{ width: "55  %" }}>Nazwa produktu</ListItemText>
        <ListItemText sx={{ width: "15%" }}>Status</ListItemText>
        <ListItemText sx={{ width: "15%" }}></ListItemText>
      </ListItem>
      {rows.map((row) => (
        <ListItem key={row.id} sx={{ gap: "2%", overflowX: "auto" }}>
          <ListItemText
            sx={{
              width: "15%",
              display: {
                xs: "none",
                md: "initial",
              },
            }}
          >
            {getFormattedDate(row.createdAt, "DD/MM/YYYY HH:mm:ss")}
          </ListItemText>
          <ListItemText sx={{ width: "55%" }}>{row.product.name}</ListItemText>
          <ListItemText sx={{ width: "15%" }}>{row.status}</ListItemText>
          <ListItemText sx={{ width: "15%" }}>
            {row.status === "SUCCESS" && (
              <Button onClick={() => saveInvoice(row)}>Pobierz fakturę</Button>
            )}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}
