import PackagesList from "@/components/lists/packages/PackagesList";
import { PageName } from "@/utils/app";
import { Box } from "@mui/material";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default function ClientPackagesPage() {
  return (
    <Box sx={{ position: "relative", minHeight: "2rem" }}>
      <PageName>Pakiety</PageName>
      <PackagesList />
    </Box>
  );
}
