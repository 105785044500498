export class CreateLegalDocumentCategoryInput {
  id?: string;
  label: string;
  slug: string;

  constructor({ id, label, slug }: CreateLegalDocumentCategoryInput) {
    this.id = id;
    this.label = label;
    this.slug = slug;
  }
}
