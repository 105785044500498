import { PropsWithChildren, useContext } from "react";
import classes from "./layout.module.scss";
import AdminSideBar from "@/components/admin/Layout/SideBar/SideBar";
import TopBar from "@/components/layout/TopBar/topBar";
import { UserContext } from "@/context/User/userContext";
import { USER_ROLES } from "@/apollo/queries/user/types";
import ClientSideBar from "@/components/client/Layout/SideBar";

export default function LayoutWrapper({ children }: PropsWithChildren) {
  const { state: userState } = useContext(UserContext);
  if (!userState?.access_token) return <>{children}</>;

  const SideBar =
    userState.role === USER_ROLES.ADMIN ? AdminSideBar : ClientSideBar;

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.gridRoot}>
        <SideBar />
        <div className={classes.children}>{children}</div>
      </div>
    </div>
  );
}
