import { PropsWithChildren } from "react";
import classes from "./formContainer.module.scss";
import { Box, SxProps } from "@mui/material";

type FormContainerProps = {
  sx?: SxProps;
};

type Props = FormContainerProps & PropsWithChildren;

export default function FormContainer({ children, sx }: Props) {
  return (
    <Box sx={sx} className={classes.fieldsWithGap}>
      {children}
    </Box>
  );
}
