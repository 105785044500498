import {
  UPDATE_USER_COMPANY_DATA,
  UpdateUserCompanyData,
} from "@/apollo/queries/user/userQuery";
import { UserContext } from "@/context/User/userContext";
import { FormErrors, getFormErrorsFromValidation } from "@/utils/form";
import { useMutation } from "@apollo/client";
import { useContext, useMemo, useState } from "react";
import { object, string } from "yup";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import Form from "../utils/Form";
import { UserReducerPayloadTypes } from "@/context/User/userReducer";
import useFormRenderer from "./hooks/useFormRenderer";
import { DocumentForm } from "@/apollo/queries/forms/formTypes";
import Spinner from "../Spinner";

export type DataCompatibilityModalProps = {
  documentForm: DocumentForm | null;
};

export function DataCompatibilityModal({
  documentForm,
}: DataCompatibilityModalProps) {
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const [userDataUpdateErrors, setUserDataUpdateErrors] =
    useState<FormErrors>();
  const [updateUserCompanyData, { loading: updatingUserCompanyData }] =
    useMutation<UpdateUserCompanyData>(UPDATE_USER_COMPANY_DATA);

  const { compatiblityCheck, updateRepresentatives } = useFormRenderer({
    formNodes: documentForm?.formNodes || [],
  });

  const userCompanyDataFormSchema = useMemo(() => {
    const formSchemaObject = {};

    compatiblityCheck?.invalidFields?.forEach((formNode) => {
      if (formNode.id.includes("representative")) {
        const number = Number(formNode.id.split(".")[1]);

        if (number > 0) return;
      }

      (formSchemaObject as any)[formNode.id] = string().required();
    });

    return object(formSchemaObject);
  }, [compatiblityCheck]);

  return (
    <Modal
      open={compatiblityCheck !== null && !!!compatiblityCheck?.isCompatible}
    >
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          overflowY: "scroll",
          maxHeight: "90vh",
        }}
      >
        <Spinner loading={updatingUserCompanyData} />
        <Form
          onSubmit={async (formData) => {
            try {
              await userCompanyDataFormSchema.validate(formData, {
                abortEarly: false,
              });

              const representatives: string[] = [];
              Object.keys(formData).forEach((key) => {
                if (key.includes("representatives")) {
                  formData[key].trim() && representatives.push(formData[key]);
                  delete formData[key];
                }
              });

              const { data } = await updateUserCompanyData({
                variables: {
                  updateUserCompanyDataInput: { ...formData, representatives },
                },
              });

              if (!data) {
                throw new Error("User was not updated");
              }

              userDispatch({
                type: UserReducerPayloadTypes.UPDATE_USER,
                payload: data?.updateUserCompanyData,
              });
            } catch (error) {
              setUserDataUpdateErrors(getFormErrorsFromValidation(error));
            }
          }}
        >
          <Typography variant="h2">Brak wymaganych danych</Typography>
          {compatiblityCheck?.invalidFields.map((field) => (
            <TextField
              sx={{ width: "20rem" }}
              key={field.id}
              id={field.id}
              label={field.id}
              placeholder={field.id}
              error={!!userDataUpdateErrors?.get(field.id)}
              helperText={userDataUpdateErrors?.get(field.id)}
              onChange={(event) => {
                const key = event.target.id;
                if (key.includes("representatives")) {
                  updateRepresentatives(event.target.id);
                }
              }}
            />
          ))}
          <Button type="submit" variant="contained">
            Zapisz dane
          </Button>
        </Form>
      </Box>
    </Modal>
  );
}
