import Form from "@/components/utils/Form/form";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";

import classes from "./classes.module.scss";
import { Suspense, useEffect, useState } from "react";
import Loading from "../loading";
import { SIGN_UP, SignUpMutation } from "@/apollo/queries/user/userQuery";
import { object, string } from "yup";
import {
  FormErrors,
  getFormErrorsFromResponse,
  getFormErrorsFromValidation,
} from "@/utils/form";
import { CompanyTypes } from "@/apollo/queries/user/types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PageName } from "@/utils/app";
import routes from "@/router/routes";

export default function SignUpPage() {
  const navigate = useNavigate();
  const signUpSchema = object({
    email: string().email().required(),
    password: string().required(),
    companyType: string().oneOf(Object.values(CompanyTypes)).required(),
  });

  const [errors, setErrors] = useState<FormErrors>();

  const companyOptions = [
    {
      label: "Jednoosobowa działalność gospodarcza",
      value: CompanyTypes.SOLE_PROPRIETORSHIP,
    },
    { label: "Spółka", value: CompanyTypes.CORPORATION },
  ];

  const [selectedCompanyType, selectCompanyType] = useState(companyOptions[0]);

  const [signUp, { data: signUpData, error }] =
    useMutation<SignUpMutation>(SIGN_UP);

  useEffect(() => {
    if (signUpData && !error) {
      toast.success(
        "Konto założone pomyślnie. Na podany adres e-mail wysłaliśmy wiadomość z linkiem do potwierdzenia konta.  ",
        {
          autoClose: false,
        }
      );

      toast.info("Za chwilę nastąpi przekierowanie.", { autoClose: 4000 });

      setTimeout(() => {
        navigate(routes.signIn);
      }, 4000);
    }
  }, [signUpData, error, navigate]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    setErrors(getFormErrorsFromResponse(error));
  }, [error]);

  return (
    <Suspense fallback={<Loading />}>
      <PageName>Sign up</PageName>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "99vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            justifyContent: "center",
            alignItems: "center",
            width: "20rem",
          }}
        >
          <Typography variant="h1">Rejestracja</Typography>
          <Form
            style={{
              width: "100%",
            }}
            classes={classes}
            onSubmit={async (formData) => {
              const submitData = {
                ...formData,
                companyType: selectedCompanyType.value,
              };
              try {
                await signUpSchema.validate(submitData, {
                  abortEarly: false,
                });

                signUp({
                  variables: {
                    signUpInput: submitData,
                  },
                });
              } catch (error) {
                setErrors(getFormErrorsFromValidation(error));
              }
            }}
          >
            <TextField
              id="email"
              label="Email"
              error={!!errors?.get("email")}
              helperText={errors?.get("email")}
              sx={{ width: "20rem" }}
            />
            <TextField
              id="password"
              label="Hasło"
              type="password"
              error={!!errors?.get("password")}
              helperText={errors?.get("password")}
              sx={{ width: "20rem" }}
            />
            <Autocomplete
              autoHighlight
              id="companyType"
              options={companyOptions}
              onInputChange={(event) => {
                const label = (event.target as HTMLInputElement).innerText;
                selectCompanyType(
                  companyOptions.filter((option) => option.label === label)[0]
                );
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Typ działalności"}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  error={!!errors?.get("companyType")}
                  helperText={errors?.get("companyType")}
                  sx={{ width: "20rem" }}
                />
              )}
            />
            <Button type="submit" variant="contained" sx={{ width: "20rem" }}>
              Zarejestruj
            </Button>
          </Form>
          <Button
            type="submit"
            sx={{ width: "20rem" }}
            onClick={() => navigate(routes.signIn)}
          >
            Wróć
          </Button>
        </Box>
      </Box>
    </Suspense>
  );
}
