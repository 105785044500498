"use client";

import { FindGeneratedDocumentInput } from "@/apollo/queries/generatedDocuments/generatedDocumentInputTypes";
import {
  GET_GENERATED_DOCUMENT_WITH_FORM,
  GetGeneratedDocumentWithForm,
} from "@/apollo/queries/generatedDocuments/generatedDocumentsQuery";
import FormRenderer from "@/components/FormRenderer/FormRenderer";
import { useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { Suspense } from "react";
import Loading from "../loading";
import { useParams } from "react-router-dom";

export default function AdminGeneratedDocumentFormPage() {
  const params = useParams<"id">();
  const { data, loading } = useQuery<GetGeneratedDocumentWithForm>(
    GET_GENERATED_DOCUMENT_WITH_FORM,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        findGeneratedDocumentInput: new FindGeneratedDocumentInput({
          id: params.id,
        }),
      },
    }
  );

  const submittedForm =
    data?.generatedDocumentWithForm?.generatedDocument?.submittedForm;
  const form = data?.generatedDocumentWithForm.form;

  if (!submittedForm || !form) return null;

  return (
    <Suspense fallback={<Loading />}>
      <Card sx={{ minHeight: "4rem", position: "relative" }}>
        <CardHeader title="Form" />
        <Divider />
        <CardContent>
          <FormRenderer hideValidation preloadedForm={form} />
        </CardContent>
      </Card>
    </Suspense>
  );
}
