import { useCallback, useContext, useEffect } from "react";
import { $generateHtmlFromNodes } from "@lexical/html";

import { DocumentCreatorPayloadTypes } from "../context/actions";
import { LexicalEditorContext } from "@/context/LexicalEditor/context";
import {
  $getSelection,
  RangeSelection,
  $getRoot,
  $createTextNode,
} from "lexical";
import { DocumentCreatorContext } from "../context";
import { useMutation } from "@apollo/client";
import {
  AddDocument,
  PUBLISH_DOCUMENT,
  PublishDocument,
  SAVE_DOCUMENT,
  UPDATE_DOCUMENT,
  UpdateDocument,
} from "@/apollo/queries/documents/documentsQuery";
import {
  CreateDocumentInput,
  UpdateDocumentInput,
} from "@/apollo/queries/documents/documentInputTypes";
import { debounce } from "@mui/material";
import { LegalDocument } from "@/apollo/queries/documents/types";
import { FormConfig, FormNode } from "@/apollo/queries/forms/formTypes";
import {
  $createDocumentFormInputNode,
  $isDocumentFormInputNode,
  DocumentFormInputNode,
} from "@/components/Lexical/nodes/DocumentFormInputNode/DocumentFormInputNode";
import { toast } from "react-toastify";
import { CompanyTypes } from "@/apollo/queries/user/types";
import { useNavigate } from "react-router-dom";
import { isCompanyInsertNode } from "../Nodes/lib/CompanyInsert/CompanyInsert";
import routes from "@/router/routes";

type useDocumentCreatorProps = {
  preloadedData?: LegalDocument;
};

export default function useDocumentCreator(props?: useDocumentCreatorProps) {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(DocumentCreatorContext);

  const [addDocument, { error: addDocumentError }] =
    useMutation<AddDocument>(SAVE_DOCUMENT);
  const [
    updateDocument,
    { loading: updatingDocument, error: updateDocumentError },
  ] = useMutation<UpdateDocument>(UPDATE_DOCUMENT);
  const [publishDocumentMutation, { error: publishDocumentError }] =
    useMutation<PublishDocument>(PUBLISH_DOCUMENT);

  const { state: lexicalEditorState } = useContext(LexicalEditorContext);

  const preloadedData = props?.preloadedData;

  useEffect(() => {
    const error = addDocumentError ?? updateDocumentError;
    if (error) toast.error(error.message);
  }, [addDocumentError, updateDocumentError]);

  useEffect(() => {
    if (!updatingDocument) return;
    toast("Saving");
  }, [updatingDocument]);

  const setDocumentName = useCallback(
    (documentName: string) => {
      dispatch({
        type: DocumentCreatorPayloadTypes.SET_DOCUMENT_NAME,
        payload: {
          documentName,
        },
      });
    },
    [dispatch]
  );
  const setDocumentNameDebounce = debounce(setDocumentName, 250);

  const setDocumentPackageRank = useCallback(
    (packageRank: number) => {
      dispatch({
        type: DocumentCreatorPayloadTypes.SET_DOCUMENT_PACKAGE_RANK,
        payload: {
          packageRank,
        },
      });
    },
    [dispatch]
  );

  const setDocumentTargetCompanyType = useCallback(
    (targetCompanyType: CompanyTypes) => {
      dispatch({
        type: DocumentCreatorPayloadTypes.SET_DOCUMENT_TARGET_COMPANY_TYPE,
        payload: {
          targetCompanyType,
        },
      });
    },
    [dispatch]
  );

  const setDocumentPublicState = useCallback(
    (publicState: boolean) => {
      dispatch({
        type: DocumentCreatorPayloadTypes.SET_DOCUMENT_PUBLIC_STATE,
        payload: {
          isPublic: publicState,
        },
      });
    },
    [dispatch]
  );

  const setDocumentDescription = useCallback(
    (description: string) => {
      dispatch({
        type: DocumentCreatorPayloadTypes.SET_DOCUMENT_DESCRIPTION,
        payload: {
          description,
        },
      });
    },
    [dispatch]
  );

  const setDocumentDescriptionDebounce = debounce(setDocumentDescription, 450);

  const setDocumentId = useCallback(
    (documentId: string) => {
      dispatch({
        type: DocumentCreatorPayloadTypes.SET_DOCUMENT_ID,
        payload: {
          documentId,
        },
      });
    },
    [dispatch]
  );

  const toggleInputModal = useCallback(
    (toggleState: boolean) => {
      dispatch({
        type: DocumentCreatorPayloadTypes.TOGGLE_INPUT_MODAL,
        payload: {
          toggleInputModal: toggleState,
        },
      });
    },
    [dispatch]
  );

  const setDocumentBaseData = useCallback(
    (data: LegalDocument) => {
      dispatch({
        type: DocumentCreatorPayloadTypes.SET_DOCUMENT_BASE_DATA,
        payload: {
          form: new FormConfig(data.form),
          // formNodes: data.form.formNodes,
          isPublic: data.isPublic,
          version: data.version,
          publishedVersion: data.publishedVersion,
          documentName: data.name,
          description: data.description,
          documentId: data.id,
          packageRank: data.packageRank,
          targetCompanyType: data.targetCompanyType,
        },
      });
    },
    [dispatch]
  );

  const addDocumentCreatorFormNodes = useCallback(
    (rawData: FormNode | FormNode[]) => {
      const formNodes = rawData instanceof Array ? rawData : [rawData];

      lexicalEditorState.editor?.update(() => {
        formNodes.forEach((formNode, fnIndex) => {
          dispatch({
            type: DocumentCreatorPayloadTypes.ADD_FORM_NODE,
            payload: {
              formNode,
            },
          });

          const selection = $getSelection() as RangeSelection;
          const p = $createDocumentFormInputNode({
            label: formNode.variableName,
            id: formNode.id,
            inputProps: formNode.inputProps,
          });

          const root = $getRoot();

          if (!selection) {
            root.append(p);
          } else {
            selection.insertNodes([p]);
          }

          const isCurrNodeCompanyInsertNode = isCompanyInsertNode(
            formNodes[fnIndex]
          );
          const isNextNodeCompanyInsert = isCompanyInsertNode(
            formNodes[fnIndex + 1]
          );
          if (isCurrNodeCompanyInsertNode && isNextNodeCompanyInsert) {
            if (!selection) {
              root.append($createTextNode(", "));
            } else {
              selection.insertText(", ");
            }
          }
        });
      });

      toggleInputModal(false);
    },
    [dispatch, lexicalEditorState.editor, toggleInputModal]
  );

  const updateDocumentCreatorFormNodes = useCallback(
    (data: FormNode) => {
      const editor = lexicalEditorState.editor;
      if (!editor) return;

      editor.update(() => {
        const editorState = editor.getEditorState();
        editorState._nodeMap.forEach((node) => {
          if ($isDocumentFormInputNode(node)) {
            const documentFormInputNode = node as DocumentFormInputNode;
            if (documentFormInputNode.props.id === data.id) {
              documentFormInputNode.setLabel(data.variableName);
            }
          }
        });
      });

      dispatch({
        type: DocumentCreatorPayloadTypes.UPDATE_FORM_NODES,
        payload: {
          formNodes: data,
        },
      });

      toggleInputModal(false);
    },
    [dispatch, lexicalEditorState.editor, toggleInputModal]
  );

  const deleteDocumentCreatorFormNode = useCallback(
    (id: string) => {
      const foundFormNodeIndex = state.form.formNodes.findIndex(
        (formNode) => formNode.id === id
      );

      if (foundFormNodeIndex < 0) return;
      const editor = lexicalEditorState.editor;

      if (!editor) return;

      editor.update(() => {
        const editorState = editor.getEditorState();

        editorState._nodeMap.forEach((node) => {
          if ((node as unknown as DocumentFormInputNode).props?.id === id) {
            node.remove();
          }
        });
      });

      dispatch({
        type: DocumentCreatorPayloadTypes.DELETE_FORM_NODE,
        payload: {
          formNode: id,
        },
      });
    },
    [dispatch, lexicalEditorState.editor, state.form.formNodes]
  );

  const pasteDocumentCreatorFormNode = useCallback(
    (formNode: FormNode) => {
      const editor = lexicalEditorState.editor;

      if (!editor) return;

      editor.update(() => {
        const selection = $getSelection() as RangeSelection;
        const p = $createDocumentFormInputNode({
          label: formNode.variableName,
          id: formNode.id,
          inputProps: formNode.inputProps,
        });

        const root = $getRoot();

        if (!selection) {
          root.append(p);
        } else {
          selection.insertNodes([p]);
        }
      });
    },
    [lexicalEditorState.editor]
  );

  const saveDocument = useCallback(() => {
    if (!lexicalEditorState.editor) return;
    try {
      const editor = lexicalEditorState.editor;
      editor._editorState.read(async () => {
        const editorState = editor.getEditorState();
        const parsedFormNodes: FormNode[] = [];

        state.form.formNodes.forEach((formNode) => {
          const variableName = formNode.variableName;

          let shouldFormNodeBeIncluded = !!Array.from(
            editorState._nodeMap
          ).find((node) => node[1].__text === variableName);

          if (!variableName.includes("Company.")) {
            shouldFormNodeBeIncluded = true;
          }

          if (shouldFormNodeBeIncluded) {
            parsedFormNodes.push(new FormNode(formNode));
          }
        });

        const json = JSON.stringify(editorState.toJSON());

        const html = $generateHtmlFromNodes(editor);
        const documentId = state.documentId;

        const baseData: CreateDocumentInput = {
          isPublic: state.isPublic,
          packageRank: state.packageRank,
          targetCompanyType: state.targetCompanyType,
          html,
          json,
          name: state.documentName,
          description: state.description,
          form: new FormConfig({ ...state.form, formNodes: parsedFormNodes }),
        };

        if (!documentId) {
          const addDocumentData = await addDocument({
            variables: {
              createDocumentInput: new CreateDocumentInput(baseData),
            },
          });

          if (!addDocumentData.data) return;
          const documentId = addDocumentData.data.addDocument.id;

          setDocumentId(documentId);
          setDocumentBaseData(addDocumentData.data.addDocument);
          toast("Created document");
          navigate(routes.admin.documents.id.make(documentId));

          return;
        }

        const updateDocumentData = await updateDocument({
          variables: {
            updateDocumentInput: new UpdateDocumentInput({
              id: documentId,
              ...baseData,
            }),
          },
        });

        if (!updateDocumentData.data) return;
        setDocumentBaseData(updateDocumentData.data?.updateDocument);

        return;
      });
    } catch (error) {
      toast.error("Nie udało się zapisać dokumentu. Skontaktuj się z nami.");
    }
  }, [
    addDocument,
    lexicalEditorState.editor,
    navigate,
    setDocumentBaseData,
    setDocumentId,
    state.description,
    state.documentId,
    state.documentName,
    state.form,
    state.isPublic,
    state.packageRank,
    state.targetCompanyType,
    updateDocument,
  ]);

  const publishDocument = useCallback(async () => {
    const { data } = await publishDocumentMutation({
      variables: {
        publishDocumentInput: {
          id: state.documentId,
        },
      },
    });

    if (!data) return;

    dispatch({
      type: DocumentCreatorPayloadTypes.SET_DOCUMENT_PUBLISHED_VERSION,
      payload: {
        publishedVersion: data?.publishDocument.version,
      },
    });

    toast.success("Najnowsza wersja dokumentu została opublikowana");
  }, [dispatch, publishDocumentMutation, state.documentId]);

  useEffect(() => {
    if (preloadedData) {
      dispatch({
        type: DocumentCreatorPayloadTypes.SET_DOCUMENT,
        payload: {
          html: preloadedData.html,
          json: preloadedData.json,
        },
      });

      if (preloadedData) {
        setDocumentBaseData(preloadedData);
      }
    }
  }, [dispatch, preloadedData, setDocumentBaseData]);

  return {
    documentCreatorState: state,
    documentFormNodes: state.form.formNodes,
    updateDocumentCreatorFormNodes,
    addDocumentCreatorFormNodes,
    toggleInputModal,
    saveDocument,
    setDocumentName,
    setDocumentNameDebounce,
    setDocumentDescriptionDebounce,
    deleteDocumentCreatorFormNode,
    pasteDocumentCreatorFormNode,
    updatingDocument,
    setDocumentPackageRank,
    setDocumentTargetCompanyType,
    publishDocument,
    setDocumentPublicState,
  };
}
