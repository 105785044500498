import { useContext } from "react";
import { DocumentCreatorContext } from "../context";
import { FormNode, NodeType } from "@/apollo/queries/forms/formTypes";
import MultipleSelectionList from "./lib/MultipleSelectionList";
import SingleSelectionList from "./lib/SingleSelectionList";
import Input from "./lib/Input";

import classes from "./nodesOutputList.module.scss";
import useDocumentCreator from "../hooks/useDocumentCreator";
import DateInsertField from "./lib/DateInsert/DateInsert";
import DateInput from "./lib/DateInput/DateInput";
import ConditionalMultipleSelectionList from "./lib/ConditionalMultipleSelectionList/conditionalMultipleSelectionList";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";

export default function NodesOutputList() {
  const { state } = useContext(DocumentCreatorContext);
  const { deleteDocumentCreatorFormNode, pasteDocumentCreatorFormNode } =
    useDocumentCreator();

  const {
    form: { formNodes },
  } = state;

  const renderFormNodeComponent = (formNode: FormNode) => {
    switch (formNode.type) {
      case NodeType.MultipleSelectionList: {
        return <MultipleSelectionList preloadedData={formNode} />;
      }
      case NodeType.ConditionalMultipleSelectionList: {
        return <ConditionalMultipleSelectionList preloadedData={formNode} />;
      }
      case NodeType.SingleSelectionList: {
        return <SingleSelectionList preloadedData={formNode} />;
      }
      case NodeType.Input: {
        return <Input preloadedData={formNode} />;
      }
      case NodeType.DateInput: {
        return <DateInput preloadedData={formNode} />;
      }
      case NodeType.DateInsert: {
        return <DateInsertField preloadedData={formNode} />;
      }
    }
  };

  return (
    <div className={classes.root}>
      {formNodes
        .filter((formNode) => !formNode.type.includes(NodeType.CompanyInsert))
        .map((formNode) => {
          return (
            <div key={formNode.id} className={classes.formNode}>
              <div>{renderFormNodeComponent(formNode)}</div>
              <div className={classes.formNodeActions}>
                <div
                  className={classes.pasteFormNode}
                  onClick={() => {
                    pasteDocumentCreatorFormNode(formNode);
                  }}
                >
                  <ContentPasteGoIcon />
                </div>
                <div
                  className={classes.deleteFormNode}
                  onClick={() => {
                    deleteDocumentCreatorFormNode(formNode.id);
                  }}
                >
                  <DeleteForeverIcon />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
