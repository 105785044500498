export const toKebabCase = (string: string) =>
  string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();

export const booleanFromString = (string?: string) => {
  if (typeof string === "boolean") return string;
  if (!string) return;
  string = string.toLowerCase();
  if (string === "true") return true;
  if (string === "false") return false;
};
