import { ActionMap } from "@/types/context";
import { MenuDrawerContextInitialState } from "./menuDrawerContext";

export enum MenuDrawerReducerPayloadTypes {
  TOGGLE,
  SET_TOGGLE_STATE,
}

type MenuDrawerReducerPayload = {
  [MenuDrawerReducerPayloadTypes.TOGGLE]: null;
  [MenuDrawerReducerPayloadTypes.SET_TOGGLE_STATE]: {
    toggle: boolean;
  };
};

export type MenuDrawerReducerActions =
  ActionMap<MenuDrawerReducerPayload>[keyof ActionMap<MenuDrawerReducerPayload>];

export const menuDrawerReducer = (
  state: MenuDrawerContextInitialState,
  action: MenuDrawerReducerActions
): MenuDrawerContextInitialState => {
  switch (action.type) {
    case MenuDrawerReducerPayloadTypes.TOGGLE:
      return {
        ...state,
        toggled: !state.toggled,
      };
    case MenuDrawerReducerPayloadTypes.SET_TOGGLE_STATE:
      return {
        ...state,
        toggled: action.payload.toggle,
      };
    default:
      return state;
  }
};
