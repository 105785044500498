import { DocumentCreatorInitialState } from ".";
import { DocumentCreatorActions, DocumentCreatorPayloadTypes } from "./actions";

export const documentCreatorReducer = (
  state: DocumentCreatorInitialState,
  action: DocumentCreatorActions
): DocumentCreatorInitialState => {
  switch (action.type) {
    case DocumentCreatorPayloadTypes.SET_DOCUMENT_BASE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case DocumentCreatorPayloadTypes.SET_DOCUMENT_ID:
      return {
        ...state,
        documentId: action.payload.documentId,
      };
    case DocumentCreatorPayloadTypes.SET_DOCUMENT_NAME:
      return {
        ...state,
        documentName: action.payload.documentName,
      };
    case DocumentCreatorPayloadTypes.SET_DOCUMENT_DESCRIPTION:
      return {
        ...state,
        description: action.payload.description,
      };
    case DocumentCreatorPayloadTypes.SET_DOCUMENT_PACKAGE_RANK:
      return {
        ...state,
        packageRank: action.payload.packageRank,
      };
    case DocumentCreatorPayloadTypes.SET_DOCUMENT_PUBLISHED_VERSION:
      return {
        ...state,
        publishedVersion: action.payload.publishedVersion,
      };
    case DocumentCreatorPayloadTypes.SET_DOCUMENT_PUBLIC_STATE:
      return {
        ...state,
        isPublic: action.payload.isPublic,
      };
    case DocumentCreatorPayloadTypes.SET_DOCUMENT_TARGET_COMPANY_TYPE:
      return {
        ...state,
        targetCompanyType: action.payload.targetCompanyType,
      };
    case DocumentCreatorPayloadTypes.SET_INPUT_MODAL_CHILDREN:
      return {
        ...state,
        inputModalChildren: action.payload.inputModalChildren,
      };
    case DocumentCreatorPayloadTypes.TOGGLE_INPUT_MODAL:
      return {
        ...state,
        isInputModalToggled: action.payload.toggleInputModal,
      };

    case DocumentCreatorPayloadTypes.DELETE_FORM_NODE: {
      const foundFormNodeIndex = state.form.formNodes.findIndex(
        (formNode) => formNode.id === action.payload.formNode
      );

      if (foundFormNodeIndex < 0) {
        return {
          ...state,
        };
      }

      const updatedFormNodes = state.form.formNodes
        .slice(0, foundFormNodeIndex)
        .concat(
          state.form.formNodes.slice(
            foundFormNodeIndex + 1,
            state.form.formNodes.length
          )
        );

      return {
        ...state,
        form: {
          ...state.form,
          formNodes: updatedFormNodes,
        },
      };
    }

    case DocumentCreatorPayloadTypes.UPDATE_FORM_NODES:
      const updatedFormNodeIndex = state.form.formNodes.findIndex(
        (formNode) => action.payload.formNodes.id === formNode.id
      );

      if (updatedFormNodeIndex > -1) {
        const updatedFormNodes = [...state.form.formNodes];
        updatedFormNodes[updatedFormNodeIndex] = action.payload.formNodes;

        return {
          ...state,
          form: {
            ...state.form,
            formNodes: updatedFormNodes,
          },
        };
      }

      return {
        ...state,
      };

    case DocumentCreatorPayloadTypes.SET_FORM_NODES:
      return {
        ...state,
        form: {
          ...state.form,
          formNodes: action.payload.formNodes,
        },
      };
    case DocumentCreatorPayloadTypes.SET_FORM:
      return {
        ...state,
        form: action.payload.form,
      };
    case DocumentCreatorPayloadTypes.ADD_FORM_NODE:
      return {
        ...state,
        form: {
          ...state.form,
          formNodes: state.form.formNodes.concat(action.payload.formNode),
        },
      };
    case DocumentCreatorPayloadTypes.SET_DOCUMENT:
      return {
        ...state,
        html: action.payload.html,
        json: action.payload.json,
      };
    default:
      return state;
  }
};
