import { CompanyTypes, Subscription } from "@/apollo/queries/user/types";
import i18next from "@/i18n";

export const extractValueFromUserData = (
  userData: string | string[] | Subscription
): string | string[] => {
  const subscription = userData as Subscription;

  if (subscription?.product) {
    return subscription.product.name;
  }

  if (userData) {
    if ((userData as string[]).pop) {
      return userData as string | string[];
    }

    if (
      userData === CompanyTypes.CORPORATION ||
      userData === CompanyTypes.SOLE_PROPRIETORSHIP
    ) {
      return i18next.t(userData as string);
    }

    return userData as string;
  }

  return "-";
};
