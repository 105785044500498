import { Product } from "@/apollo/queries/products/types";
import i18n from "@/i18n";
import { Box, Typography } from "@mui/material";

export default function PackageDescription({ product }: { product: Product }) {
  const featuresList = Object.keys(product.features)
    .map((feature) => {
      let value = product.features[feature];

      if (typeof value === "boolean") {
        value = value ? "Tak" : "Nie";
      }

      return { name: feature, value, isBool: typeof value === "boolean" };
    })
    .filter((x) => x?.name !== "__typename" && x);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography>{product.shortDescription}</Typography>
        <Typography>{product.description}</Typography>
      </Box>
      <Box>
        {featuresList.map((feature) => (
          <Typography>
            {i18n.t("package." + feature?.name)}: {feature?.value}
          </Typography>
        ))}
      </Box>
      <Box>
        <Typography>Cena za miesiąc: {product.price.monthly} zł</Typography>
      </Box>
    </Box>
  );
}
