import { GeneratedDocument } from "@/apollo/queries/generatedDocuments/types";
import LexicalEditor from "../Lexical";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { LexicalEditorProvider } from "@/context/LexicalEditor/context";

export default function DocumentReader({
  data,
  generatedDocumentId,
  documentId,
}: {
  data: GeneratedDocument;
  generatedDocumentId?: string;
  documentId?: string;
}) {
  return (
    <div>
      <LexicalEditorProvider>
        <LexicalComposer
          initialConfig={{ namespace: "document-reader", onError: () => {} }}
        >
          <LexicalEditor
            readMode
            readerData={{
              generatedDocumentId,
              documentId,
              ...data,
            }}
          />
        </LexicalComposer>
      </LexicalEditorProvider>
    </div>
  );
}
