import FormContainer from "@/components/utils/Form/formContainer";
import { FormData } from "@/utils/form";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useDocumentCreator from "../../../hooks/useDocumentCreator";
import {
  FormNode,
  FormNodeField,
  NodeType,
} from "@/apollo/queries/forms/formTypes";
import Form from "@/components/utils/Form";
import { FormState } from "@/components/utils/Form/form";
import BaseFields from "../BaseFields/BaseFields";
import { isInputNode } from "./Input";

type InputFormProps = {
  preloadedData?: FormNode;
};

export default function InputForm({ preloadedData }: InputFormProps) {
  const {
    updateDocumentCreatorFormNodes,
    addDocumentCreatorFormNodes,
    documentFormNodes,
  } = useDocumentCreator();

  const [formNodeField, setFormNodeField] = useState<FormNodeField>(
    new FormNodeField({ label: "", value: "" })
  );

  const defaultFormat = "{{docName}} - {{value}}";
  const [extendDocumentField, setExtendDocumentField] = useState({
    enabled: false,
    format: "{{docName}} - {{value}}",
  });

  useEffect(() => {
    if (preloadedData?.fields) {
      const field = preloadedData.fields[0];
      setExtendDocumentField({
        format:
          preloadedData.inputProps?.input?.extendsDocumentName?.format ||
          defaultFormat,
        enabled:
          !!preloadedData.inputProps?.input?.extendsDocumentName?.enabled,
      });
      setFormNodeField(field);
    }
  }, [preloadedData]);

  const handleOnFormChange = useCallback(
    (formState: FormState) => {
      setFormNodeField(new FormNodeField({ ...formNodeField, ...formState }));
    },
    [formNodeField]
  );

  const parseData = (data: FormData): FormNode => {
    const formNodeData = new FormNode(data as unknown as FormNode);
    const finalData: FormNode = {
      ...formNodeData,
      inputProps: {
        input: {
          extendsDocumentName: {
            format: extendDocumentField.format,
            enabled: extendDocumentField.enabled,
          },
        },
      },
      fields: [formNodeField],
      id: preloadedData?.id || "",
    };

    return new FormNode(finalData);
  };

  const canExtendDocumentName = useMemo(() => {
    let canExtend = true;
    documentFormNodes.forEach((formNode) => {
      if (isInputNode(formNode)) {
        const formNodeExtendingName =
          formNode.inputProps?.input?.extendsDocumentName?.enabled;

        if (canExtend) {
          if (formNodeExtendingName && formNode.id !== preloadedData?.id) {
            canExtend = false;
          }
        }
      }
    });
    return canExtend;
  }, [documentFormNodes, preloadedData?.id]);

  return (
    <Form
      onSubmit={(formData) => {
        const parsedData = parseData(formData);
        if (preloadedData?.id) {
          updateDocumentCreatorFormNodes(parsedData);
        } else {
          addDocumentCreatorFormNodes(parsedData);
        }
      }}
      onChange={handleOnFormChange}
    >
      <FormContainer>
        <Box>
          <BaseFields preloadedData={preloadedData} nodeType={NodeType.Input} />
          <>
            <FormControlLabel
              disabled={!canExtendDocumentName}
              label="Zmienia nazwe dokumentu"
              control={
                <Checkbox
                  onChange={() => {
                    setExtendDocumentField({
                      ...extendDocumentField,
                      enabled: !extendDocumentField.enabled,
                    });
                  }}
                  value={extendDocumentField.enabled}
                  checked={extendDocumentField.enabled}
                />
              }
            />
            {extendDocumentField.enabled && (
              <TextField
                value={extendDocumentField.format}
                helperText={`{{docName}} - nazwa dokumentu, {{value}} - podana wartość`}
                onChange={(event) => {
                  setExtendDocumentField({
                    ...extendDocumentField,
                    format: event.currentTarget.value,
                  });
                }}
              />
            )}
          </>
        </Box>
        <Button type="submit">Submit</Button>
      </FormContainer>
    </Form>
  );
}
