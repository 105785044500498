import { LegalDocument } from "@/apollo/queries/documents/types";
import { CreateLegalDocumentCategoryInput } from "@/apollo/queries/legalDocumentsCategories/legalDocumentsCategoriesInputTypes";
import {
  GET_LEGAL_DOCUMENTS_CATEGORIES,
  GetLegalDocumentsCategories,
  SAVE_LEGAL_DOCUMENTS_CATEGORIES,
} from "@/apollo/queries/legalDocumentsCategories/legalDocumentsCategoriesQuery";
import { LegalDocumentCategory } from "@/apollo/queries/legalDocumentsCategories/types";
import { toKebabCase } from "@/utils/string";
import { useMutation, useQuery } from "@apollo/client";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

export default function DocumentCreatorSettings_Categories({
  preloadedData,
}: {
  preloadedData?: LegalDocument;
}) {
  const [search, setSearch] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<
    LegalDocumentCategory[]
  >([]);
  const [updatedCategories, updateCategories] = useState<
    LegalDocumentCategory[]
  >([]);
  const {
    data: categoriesData,
    loading,
    refetch,
  } = useQuery<GetLegalDocumentsCategories>(GET_LEGAL_DOCUMENTS_CATEGORIES);

  const [
    saveLegalDocumentCategories,
    { error: saveLegalDocumentCategoriesError },
  ] = useMutation(SAVE_LEGAL_DOCUMENTS_CATEGORIES);

  const availableCategories = useMemo(() => {
    return categoriesData?.legalDocumentsCategories.concat(updatedCategories);
  }, [categoriesData?.legalDocumentsCategories, updatedCategories]);

  const defaultCategories = useMemo(
    () => preloadedData?.categories,
    [preloadedData?.categories]
  );

  useEffect(() => {
    if (defaultCategories) {
      setSelectedCategories(defaultCategories);
    }
  }, [defaultCategories]);

  const onCategorySelect = (
    event: React.SyntheticEvent,
    options: LegalDocumentCategory[]
  ) => {
    setSelectedCategories(options);
  };

  const addCategory = () => {
    const category = search;
    updateCategories(
      updatedCategories.concat({
        slug: toKebabCase(category),
        label: category,
      })
    );
  };

  const saveCategories = async () => {
    try {
      await saveLegalDocumentCategories({
        variables: {
          createLegalDocumentsCategoriesInput: {
            legalDocumentId: preloadedData?.id,
            categories: selectedCategories.map(
              (category) => new CreateLegalDocumentCategoryInput(category)
            ),
          },
        },
      });

      refetch();
      toast.success("Zapisano kategorie");
    } catch (e) {
      toast.error("Nie udało się zapisać kategorii.");
    }
  };

  if (
    (loading && !availableCategories) ||
    !availableCategories ||
    !defaultCategories
  )
    return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Autocomplete
        onChange={onCategorySelect}
        options={availableCategories}
        getOptionLabel={(option) => option.label}
        multiple
        defaultValue={defaultCategories}
        isOptionEqualToValue={(option) => {
          return !!selectedCategories.find(
            (selectedCategory) => option.id === selectedCategory.id
          );
        }}
        noOptionsText={
          <Button
            onClick={addCategory}
            sx={{ textTransform: "none" }}
          >{`DODAJ KATEGORIE "${search}"`}</Button>
        }
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(event) => setSearch(event.target.value)}
            variant="outlined"
            label="Kategorie"
            placeholder="Kategorie"
          />
        )}
      />
      <Button onClick={saveCategories}>Zapisz kategorie</Button>
    </Box>
  );
}
