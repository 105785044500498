import { PropsWithChildren } from "react";
import classes from "./formContainer.module.scss";
import { Box, Grid, SxProps } from "@mui/material";

type FormContainerProps = {
  sx?: SxProps;
};

type Props = FormContainerProps & PropsWithChildren;

export default function FormGridContainer({ children, sx }: Props) {
  return (
    <Grid container sx={{ ...sx }} spacing={2}>
      {children}
    </Grid>
  );
}
