import {
  CSSProperties,
  ChangeEvent,
  Dispatch,
  FormEvent,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import Classes from "@/types/classes";
import { FormData, getFormData } from "@/utils/form";
import { Box } from "@mui/material";

export type FormState = { [key: string]: string };

export type ComponentProps = {
  classes?: Classes;
  onSubmit?: (formData: FormData) => any;
  getFormState?: FormState;
  onChange?: (
    formState: FormState,
    setFormState: Dispatch<SetStateAction<FormState>>
  ) => any;
  style?: CSSProperties;
};

export type Props = ComponentProps & PropsWithChildren;

export default function Form({
  children,
  classes,
  onSubmit,
  onChange,
  style,
}: Props) {
  const formRef = useRef<HTMLFormElement>(null);
  const [formState, setFormState] = useState<FormState>({});

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = getFormData(formRef.current);

    if (onSubmit) {
      onSubmit(formData);
    }
  };

  const handleOnFormChange = useCallback(
    (event: ChangeEvent<HTMLFormElement>) => {
      const target = event.target;
      let value = target.value;
      const key = target.id;

      if (target.type === "checkbox") {
        value = target.checked;
      }

      const newFormState = {
        ...formState,
        [key]: value,
      };

      if (onChange) {
        onChange(newFormState, setFormState);
      }

      if (onChange?.length || 2 < 2) {
        setFormState(newFormState);
      }
    },
    [formState, onChange, setFormState]
  );

  return (
    <form
      ref={formRef}
      noValidate
      className={classes?.root}
      onSubmit={handleOnSubmit}
      onChange={handleOnFormChange}
      style={style}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {children}
      </Box>
    </form>
  );
}
