"use client";
import { FindProductInput } from "@/apollo/queries/products/productsInputTypes";
import {
  GET_PRODUCT,
  GetProduct,
} from "@/apollo/queries/products/productsQuery";
import Spinner from "@/components/Spinner/spinner";
import PackageForm from "@/components/package/PackageForm";
import { PageName } from "@/utils/app";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

export default function ClientPackagePage() {
  const params = useParams<"slug">();
  const { data, loading } = useQuery<GetProduct>(GET_PRODUCT, {
    fetchPolicy: "cache-and-network",
    variables: {
      findProductInput: new FindProductInput({ slug: params.slug }),
    },
  });

  return (
    <Box sx={{ position: "relative" }}>
      <PageName>Pakiet</PageName>
      <Spinner loading={loading} data={data} />
      <PackageForm productSlug={params.slug} product={data?.product} />
    </Box>
  );
}
