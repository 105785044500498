import { GET_DOCUMENTS } from "@/apollo/queries/documents/documentsQuery";
import Spinner from "@/components/Spinner/spinner";
import { useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import dayjs from "dayjs";
import { Suspense } from "react";
import Loading from "./loading";
import LegalDocumentsList from "@/components/lists/legalDocuments/LegalDocumentsList";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default function AdminDocumentsPage() {
  const { data, loading } = useQuery(GET_DOCUMENTS, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <Suspense fallback={<Loading />}>
      <Card sx={{ minHeight: "4rem", position: "relative" }}>
        <CardHeader title="Documents" />
        <Divider />
        <CardContent>
          <Spinner loading={loading} data={data} />
          <LegalDocumentsList />
        </CardContent>
      </Card>
    </Suspense>
  );
}
