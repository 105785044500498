"use client";

import { MenuDrawerContext } from "@/context/MenuDrawer/menuDrawerContext";
import classes from "./sideBar.module.scss";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  SwipeableDrawer,
  Typography,
  useTheme,
} from "@mui/material";
import { PropsWithChildren, useCallback, useContext } from "react";
import { MenuDrawerReducerPayloadTypes } from "@/context/MenuDrawer/menuDrawerReducer";
import Settings from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

import useWindow from "@/hooks/useWindow";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import routes from "@/router/routes";

export default function SideBar({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { state: menuDrawerState, dispatch: menuDrawerDispatch } =
    useContext(MenuDrawerContext);

  const toggleDrawer = useCallback(
    (anchor: string, open: boolean) => () => {
      menuDrawerDispatch({
        type: MenuDrawerReducerPayloadTypes.TOGGLE,
        payload: null,
      });
    },
    [menuDrawerDispatch]
  );
  const anchor = "bottom";
  const window = useWindow();

  return window.check((x) => x <= 1024) ? (
    <SwipeableDrawer
      anchor={anchor}
      open={menuDrawerState.toggled || false}
      onClose={toggleDrawer(anchor, false)}
      onOpen={toggleDrawer(anchor, true)}
    >
      <Box sx={{ minHeight: "80vh", maxHeight: "80vh", padding: "1rem" }}>
        {children}
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate(routes.client.profile);
            }}
          >
            <ListItemIcon style={{ color: theme.palette.primary.main }}>
              <Settings />
            </ListItemIcon>
            <Typography>Settings</Typography>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              menuDrawerDispatch({
                type: MenuDrawerReducerPayloadTypes.TOGGLE,
                payload: null,
              });
              Cookies.remove("user");
              navigate(routes.signIn);
              navigate(0);
            }}
          >
            <ListItemIcon style={{ color: theme.palette.primary.main }}>
              <LogoutIcon />
            </ListItemIcon>
            <Typography>Logout</Typography>
          </ListItemButton>
        </ListItem>
      </Box>
    </SwipeableDrawer>
  ) : (
    <Box
      className={classes.root}
      sx={{
        backgroundColor: "white",
        position: "sticky",
        top: "4rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.2rem",
        padding: "1rem 0.5rem",
        minHeight: "99vh",
        minWidth: "12rem",
      }}
    >
      <List sx={{ padding: 0 }}>{children}</List>
    </Box>
  );
}
