"use client";

import {
  GET_GENERATED_DOCUMENTS,
  GetGeneratedDocuments,
} from "@/apollo/queries/generatedDocuments/generatedDocumentsQuery";
import Spinner from "@/components/Spinner/spinner";
import routes from "@/router/routes";
import { PageName } from "@/utils/app";
import { getFormattedDate } from "@/utils/date";
import { useQuery } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

export default function ClientGeneratedDocumentsPage() {
  const { data, loading } = useQuery<GetGeneratedDocuments>(
    GET_GENERATED_DOCUMENTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "document.name",
      headerName: "Document",
      flex: 6,
      renderCell: (params) => {
        return params.row?.displayName;
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
      renderCell: (params) => {
        if (!params.id) return;
        return getFormattedDate(params.row.createdAt, "DD/MM/YYYY HH:mm:ss");
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      renderCell: (params) => {
        if (!params.id) return;
        return (
          <Box sx={{ gap: 1, display: "flex" }}>
            <Button
              variant="contained"
              onClick={() =>
                navigate(
                  routes.client.generatedDocuments.id.make(params.id as string)
                )
              }
            >
              Zobacz
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                navigate(
                  routes.client.generatedDocuments.id.form.make(
                    params.id as string
                  )
                )
              }
            >
              formularz
            </Button>
          </Box>
        );
      },
    },
  ];

  const empty = { id: 0, document: { name: "Nie znaleziono " } };
  const rows = data?.generatedDocuments?.length
    ? data?.generatedDocuments
    : [empty];

  return (
    <Box sx={{ position: "relative", minHeight: "2rem" }}>
      <PageName>Wygenerowane dokumenty</PageName>
      <Spinner loading={loading} data={data} />
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[100]}
      />
    </Box>
  );
}
