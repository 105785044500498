import { FormNode } from "@/apollo/queries/forms/formTypes";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function MultipleSelectionList({ fields }: FormNode) {
  if (!fields) return null;

  return (
    <div>
      {fields.map(({ label, id, value }) => (
        <FormControlLabel
          key={id}
          label={label}
          control={<Checkbox id={id} inputProps={{ "aria-label": label }} />}
        />
      ))}
    </div>
  );
}
