"use client";
import { Typography } from "@mui/material";

export default function AdminPage() {
  return (
    <main>
      <Typography variant="h6">Admin dashboard</Typography>{" "}
    </main>
  );
}
