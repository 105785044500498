import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";
import { User } from "./queries/user/types";
import { isPageAuthListed } from "@/utils/auth";

export function ApolloWrapper({ children }: React.PropsWithChildren) {
  const authLink = setContext((_, data) => {
    // get the authentication token from local storage if it exists
    let access_token = "";
    if (isPageAuthListed(window.location.pathname)) {
      try {
        const userCookie = Cookies.get("user");
        const user: User = JSON.parse(userCookie as string);
        access_token = user.access_token;
      } catch (e) {
        console.error("Unable to login user, no cookie was found");
      }
    }

    return {
      headers: {
        credentials: "include",
        authorization:
          "Bearer " + access_token + " " + Cookies.get("sessionId"),
      },
    };
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
