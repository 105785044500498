import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  useTheme,
} from "@mui/material";

import ArticleIcon from "@mui/icons-material/Article";
import DocumentScanner from "@mui/icons-material/DocumentScanner";
import CreateIcon from "@mui/icons-material/Create";
import GroupIcon from "@mui/icons-material/Group";

import SideBar from "@/components/layout/SideBar";
import { Link } from "react-router-dom";
import routes from "@/router/routes";

export default function AdminSideBar() {
  const theme = useTheme();
  return (
    <SideBar>
      <Link to={routes.admin.documents[0]}>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon style={{ color: theme.palette.primary.main }}>
              <ArticleIcon />
            </ListItemIcon>
            <Typography>Dokumenty</Typography>
          </ListItemButton>
        </ListItem>
      </Link>
      <Link to={routes.admin.documents.creator}>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon style={{ color: theme.palette.primary.main }}>
              <CreateIcon />
            </ListItemIcon>
            <Typography>Kreator dokumentów</Typography>
          </ListItemButton>
        </ListItem>
      </Link>
      <Link to={routes.admin.generatedDocuments[0]}>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon style={{ color: theme.palette.primary.main }}>
              <DocumentScanner />
            </ListItemIcon>
            <Typography>Wygenerowane dokumenty</Typography>
          </ListItemButton>
        </ListItem>
      </Link>
      <Link to={routes.admin.clients[0]}>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon style={{ color: theme.palette.primary.main }}>
              <GroupIcon />
            </ListItemIcon>
            <Typography>Klienci</Typography>
          </ListItemButton>
        </ListItem>
      </Link>
    </SideBar>
  );
}
