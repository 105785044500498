import {
  DocumentForm,
  FormNode,
  NodeType,
} from "@/apollo/queries/forms/formTypes";
import { SUBMIT_FORM, SubmitForm } from "@/apollo/queries/forms/formsQuery";
import { User } from "@/apollo/queries/user/types";
import { UserContext } from "@/context/User/userContext";
import { FormData } from "@/utils/form";
import { useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";

export type SubmittedFormFieldData = {
  [key: string]: string | number | boolean | undefined;
  fieldId: string;
  value_bool?: boolean;
  value_string?: string;
  value_int?: number;
};

export type ParsedSubmittedFormInput = {
  formId: string;
  data: SubmittedFormFieldData[];
};

export type CompatiblityCheck = {
  isCompatible: boolean;
  invalidFields: FormNode[];
};
export default function useFormRenderer({
  formNodes,
  generatedDocumentId,
}: {
  formNodes: FormNode[];
  generatedDocumentId?: string;
}) {
  const { state: userState } = useContext(UserContext);
  const [submitForm, { loading: submittingForm }] =
    useMutation<SubmitForm>(SUBMIT_FORM);
  const [compatiblityCheck, setCompatibilityCheck] =
    useState<CompatiblityCheck | null>(null);
  const [representativesFields, setRepresentativesFields] = useState<
    FormNode[]
  >([]);

  useEffect(() => {
    const updatedCompatibilityCheck: CompatiblityCheck = {
      isCompatible: true,
      invalidFields: [],
    };
    const uniqueFormNodes: FormNode[] = [];

    if (userState) {
      formNodes.forEach((formNode) => {
        if (
          !uniqueFormNodes.find(
            (uniqueFormNode) => uniqueFormNode.id === formNode.id
          )
        ) {
          let key = formNode.id;

          if (
            { ...userState }.hasOwnProperty(key) &&
            (!userState[key] || !(userState[key] as string | string[])?.length)
          ) {
            let nextField = { ...formNode };

            if (formNode.id === "representatives") {
              nextField.id += ".0";
              setRepresentativesFields([nextField]);
            }

            updatedCompatibilityCheck.invalidFields.push(nextField);
          }

          uniqueFormNodes.push(formNode);
        }
      });
    }

    updatedCompatibilityCheck.isCompatible =
      updatedCompatibilityCheck.invalidFields.length === 0;
    setCompatibilityCheck(updatedCompatibilityCheck);
  }, [formNodes, userState]);

  const updateRepresentatives = useCallback(
    (fieldId: string) => {
      if (!compatiblityCheck) return;

      let updatedInvalidFields = compatiblityCheck.invalidFields;

      const updatedRepresentativesFields: FormNode[] = [
        ...representativesFields,
      ];

      let lastRepresentativesFieldIndex = -1;
      updatedInvalidFields.forEach((field, index) => {
        if (field.id.includes("representatives.")) {
          lastRepresentativesFieldIndex = index + 1;
        }
      });

      if (
        representativesFields.findIndex(
          (representativesField) => representativesField.id === fieldId
        ) ===
        representativesFields.length - 1
      ) {
        const newField: FormNode = {
          ...representativesFields[representativesFields.length - 1],
          id: "representatives." + representativesFields.length,
        };

        updatedRepresentativesFields.push(newField);

        // Add new field to form
        updatedInvalidFields = updatedInvalidFields
          .slice(0, lastRepresentativesFieldIndex)
          .concat(newField)
          .concat(
            updatedInvalidFields.slice(
              lastRepresentativesFieldIndex,
              updatedInvalidFields.length
            )
          );

        setCompatibilityCheck({
          ...compatiblityCheck,
          invalidFields: updatedInvalidFields,
        });

        setRepresentativesFields(updatedRepresentativesFields);
      }
    },
    [compatiblityCheck, representativesFields]
  );

  const onSubmit = useCallback(
    async (
      documentForm: DocumentForm,
      formData: FormData
    ): Promise<SubmitForm | undefined | null> => {
      const sf = await submitForm({
        variables: {
          submitFormInput: {
            formId: documentForm.id,
            generatedDocumentId,
            data: Object.entries(formData).map((formDataEntry) => {
              const returnedObject: SubmittedFormFieldData = {
                fieldId: formDataEntry[0],
              };

              let propertyName = "value";

              switch (typeof formDataEntry[1]) {
                case "boolean":
                  propertyName += "_bool";
                  break;
                case "string":
                  propertyName += "_string";
                  break;
                case "number":
                  propertyName += "_int";
                  break;
              }

              returnedObject[propertyName] = formDataEntry[1];
              return returnedObject;
            }),
          },
        },
      });

      return sf.data;
    },
    [generatedDocumentId, submitForm]
  );

  return {
    onSubmit,
    submittingForm,
    compatiblityCheck,
    updateRepresentatives,
  };
}
