import { gql } from "@apollo/client";
import { LegalDocument, LegalDocumentsGroupedByCategory } from "./types";

export type AddDocument = { addDocument: LegalDocument };
export type UpdateDocument = { updateDocument: LegalDocument };
export type PublishDocument = { publishDocument: { version: number } };
export type GetDocument = { document: LegalDocument };
export type GetLegalDocumentsGroupedByCategory = {
  legalDocumentsGroupedByCategory: LegalDocumentsGroupedByCategory;
};
export type GetDocuments = { documents: LegalDocument[] };

export const LIST_LEGAL_DOCUMENT_FIELDS = gql`
  fragment ListLegalDocumentFields on LegalDocument {
    id
    name
    description
    createdAt
    packageRank
    name
  }
`;

export const CORE_LEGAL_DOCUMENT_FIELDS = gql`
  fragment CoreLegalDocumentFields on LegalDocument {
    id
    name
    description
    targetCompanyType
    packageRank
    html
    json
    version
    publishedVersion
    isPublic
    categories {
      slug
      label
      id
    }
    form {
      id
      formNodes {
        id
        question
        variableName
        inputProps {
          date {
            format
          }
          conditions {
            fieldId
            list {
              fieldId
              mustBe
            }
          }
          input {
            extendsDocumentName {
              format
              enabled
            }
          }
        }
        fields {
          id
          label
          value
        }
        type
      }
    }
    createdAt
    updatedAt
  }
`;

const GET_DOCUMENTS = gql`
  query Documents {
    documents {
      ...ListLegalDocumentFields
    }
  }
  ${LIST_LEGAL_DOCUMENT_FIELDS}
`;

const GET_LEGAL_DOCUMENTS_GROUPED_BY_CATEGORY = gql`
  query LegalDocumentsGroupedByCategory {
    legalDocumentsGroupedByCategory {
      categories {
        category {
          id
          label
        }
        legalDocuments {
          ...ListLegalDocumentFields
        }
      }
    }
  }
  ${LIST_LEGAL_DOCUMENT_FIELDS}
`;

const GET_DOCUMENT = gql`
  query Document($findDocumentInput: FindDocumentInput!) {
    document(findDocumentInput: $findDocumentInput) {
      ...CoreLegalDocumentFields
    }
  }
  ${CORE_LEGAL_DOCUMENT_FIELDS}
`;

const SAVE_DOCUMENT = gql`
  mutation AddDocument($createDocumentInput: CreateDocumentInput!) {
    addDocument(createDocumentInput: $createDocumentInput) {
      ...CoreLegalDocumentFields
    }
  }
  ${CORE_LEGAL_DOCUMENT_FIELDS}
`;

const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($updateDocumentInput: UpdateDocumentInput!) {
    updateDocument(updateDocumentInput: $updateDocumentInput) {
      ...CoreLegalDocumentFields
    }
  }
  ${CORE_LEGAL_DOCUMENT_FIELDS}
`;

const PUBLISH_DOCUMENT = gql`
  mutation PublishDocument($publishDocumentInput: PublishDocumentInput!) {
    publishDocument(publishDocumentInput: $publishDocumentInput) {
      version
    }
  }
`;

export {
  GET_DOCUMENTS,
  GET_DOCUMENT,
  SAVE_DOCUMENT,
  UPDATE_DOCUMENT,
  PUBLISH_DOCUMENT,
  GET_LEGAL_DOCUMENTS_GROUPED_BY_CATEGORY,
};
