import { getFormattedDate } from "@/utils/date";
import { useQuery } from "@apollo/client";
import { Box, Button, List, ListItem, ListItemText } from "@mui/material";
import { GET_USERS, GetUsers } from "@/apollo/queries/user/userQuery";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { SearchComponent, usePagination } from "../filters";
import Spinner from "@/components/Spinner";
import routes from "@/router/routes";

export default function UsersList() {
  const [search, setSearch] = useState({ email: "", nip: "" });
  const { PaginationComponent, paginationData, updatePaginationData } =
    usePagination();

  const { data, loading } = useQuery<GetUsers>(GET_USERS, {
    variables: {
      filters: {
        email: search.email,
        nip: search.nip,
      },
      pagination: paginationData,
    },
    fetchPolicy: "cache-and-network",
    refetchWritePolicy: "overwrite",
  });

  useEffect(() => {
    if (!data?.users) return;
    updatePaginationData(data?.users.pagination);
  }, [data, updatePaginationData]);

  const navigate = useNavigate();

  const rows = data?.users.list || [];

  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }}>
        <SearchComponent
          placeholder="Wyszukaj po mailu"
          onChange={(value) => setSearch({ ...search, email: value })}
        />
        <SearchComponent
          placeholder="Wyszukaj po nipie"
          onChange={(value) => setSearch({ ...search, nip: value })}
        />
      </Box>
      <List>
        <Spinner loading={loading} data={data} />
        <ListItem sx={{ gap: "2%", userSelect: "none", pointerEvents: "none" }}>
          <ListItemText
            sx={{
              width: "5%",
              display: {
                xs: "none",
                md: "initial",
              },
            }}
          >
            ID
          </ListItemText>
          <ListItemText
            sx={{
              width: "10%",
              display: {
                xs: "none",
                md: "initial",
              },
            }}
          >
            Created at
          </ListItemText>
          <ListItemText sx={{ width: "40%" }}>Email</ListItemText>
          <ListItemText sx={{ width: "20%" }}>NIP</ListItemText>
          <ListItem sx={{ width: "25%" }}>Akcje</ListItem>
        </ListItem>
        {rows.map((row) => (
          <ListItem key={row.id} sx={{ gap: "2%", overflowX: "auto" }}>
            <ListItemText
              sx={{
                width: "5%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                wordBreak: "break-all",
                whiteSpace: "nowrap",
                maskImage:
                  "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 100%);",
                display: {
                  xs: "none",
                  md: "initial",
                },
              }}
            >
              {row.id}
            </ListItemText>
            <ListItemText
              sx={{
                width: "10%",
                display: {
                  xs: "none",
                  md: "initial",
                },
              }}
            >
              {getFormattedDate(row.createdAt, "DD/MM/YYYY HH:mm:ss")}
            </ListItemText>
            <ListItemText sx={{ width: "40%" }}>{row.email}</ListItemText>
            <ListItemText sx={{ width: "20%" }}>{row.nip}</ListItemText>
            <ListItem sx={{ width: "25%" }}>
              <Box sx={{ gap: 1, display: "flex" }}>
                <Button
                  variant="contained"
                  onClick={() => navigate(routes.admin.clients.id.make(row.id))}
                >
                  Wyświetl
                </Button>
              </Box>
            </ListItem>
          </ListItem>
        ))}
      </List>
      <PaginationComponent />
    </>
  );
}
