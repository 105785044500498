import { Suspense, useContext, useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { object, string } from "yup";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { SIGN_IN, SignInMutation } from "@/apollo/queries/auth/authQuery";
import { USER_ROLES } from "@/apollo/queries/user/types";
import Form from "@/components/utils/Form/form";
import { UserContext } from "@/context/User/userContext";
import { UserReducerPayloadTypes } from "@/context/User/userReducer";

import {
  FormErrors,
  getFormErrorsFromResponse,
  getFormErrorsFromValidation,
} from "@/utils/form";

import Loading from "../loading";
import classes from "./classes.module.scss";
import { toast } from "react-toastify";
import { PageName } from "@/utils/app";
import routes from "@/router/routes";

export default function SignInPage() {
  const signInSchema = object({
    email: string().email().required(),
    password: string().required(),
  });

  const [userEmail, setUserEmail] = useState("");
  const [errors, setErrors] = useState<FormErrors>();
  const [signIn, { data: signInData, error }] =
    useMutation<SignInMutation>(SIGN_IN);
  const navigate = useNavigate();
  const { dispatch: userDispatch } = useContext(UserContext);

  useEffect(() => {
    setErrors(getFormErrorsFromResponse(error));
  }, [error]);

  useEffect(() => {
    if (!signInData) return;
    const user = signInData?.signIn;
    const { role, access_token: token } = user;
    if (!token) return;

    Cookies.set("user", JSON.stringify(user), { expires: 7 });

    userDispatch({
      type: UserReducerPayloadTypes.SET_USER,
      payload: signInData?.signIn,
    });

    switch (role) {
      case USER_ROLES.ADMIN:
        navigate(routes.admin.documents[0]);
        break;
      case USER_ROLES.USER:
        const searchParams = new URLSearchParams(window.location.search);
        const goto = searchParams.get("goto");

        if (goto) return navigate(goto);

        navigate(routes.client.documents[0]);
        break;
      default:
        return;
    }
  }, [navigate, signInData, userDispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error.message, { autoClose: 5000 });
    }
  }, [error]);

  return (
    <Suspense fallback={<Loading />}>
      <PageName>Zaloguj się</PageName>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "99vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
            width: "20rem",
          }}
        >
          <Typography variant="h1">Logowanie</Typography>
          <Form
            style={{
              width: "100%",
            }}
            classes={classes}
            onSubmit={(formData) => {
              try {
                signInSchema.validateSync(formData, {
                  abortEarly: false,
                });

                setUserEmail(formData.email);

                signIn({
                  variables: {
                    signInInput: formData,
                  },
                });
              } catch (error) {
                setErrors(getFormErrorsFromValidation(error));
              }
            }}
          >
            <TextField
              id="email"
              label="E-Mail"
              error={!!errors?.get("email")}
              helperText={errors?.get("email")}
            />
            <TextField
              id="password"
              label="Hasło"
              type="password"
              error={!!errors?.get("password")}
              helperText={errors?.get("password")}
            />
            <Button type="submit" variant="contained">
              Zaloguj
            </Button>
          </Form>
          <Box>
            <Typography>
              Zapomniałeś hasła? Zresetuj je{" "}
              <Link to={`${routes.requestPasswordReset}?email=${userEmail}`}>
                tutaj
              </Link>
              .
            </Typography>
          </Box>
          <Box>
            <Typography>
              Lub załóż konto <Link to={routes.signUp}>tutaj</Link>.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Suspense>
  );
}
