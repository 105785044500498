import Form from "@/components/utils/Form/form";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";

import classes from "./classes.module.scss";
import { Suspense, useEffect, useState } from "react";
import Loading from "../loading";
import { object, string } from "yup";
import {
  FormErrors,
  getFormErrorsFromResponse,
  getFormErrorsFromValidation,
} from "@/utils/form";
import { toast } from "react-toastify";
import { REQUEST_PASSWORD_RESET } from "@/apollo/queries/user/userQuery";
import { useNavigate } from "react-router-dom";
import { PageName } from "@/utils/app";
import routes from "@/router/routes";

export default function RequestPasswordResetPage() {
  const navigate = useNavigate();
  const requestPasswordResetSchema = object({
    email: string().email().required(),
  });

  const [errors, setErrors] = useState<FormErrors>();

  const params = new URLSearchParams(new URL(window?.location.href).search);
  const preSetEmail = params.get("email");

  const [requestPasswordReset, { data: requestPasswordResetData, error }] =
    useMutation(REQUEST_PASSWORD_RESET);

  useEffect(() => {
    if (requestPasswordResetData && !error) {
      toast.success("Na podany adres e-mail wysłaliśmy dalsze instrukcje.", {
        autoClose: 5000,
        closeOnClick: false,
        closeButton: () => null,
        draggable: false,
      });
    }
  }, [error, requestPasswordResetData]);

  useEffect(() => {
    setErrors(getFormErrorsFromResponse(error));

    toast.error(error?.message, { autoClose: 4000 });
  }, [error]);

  return (
    <Suspense fallback={<Loading />}>
      <PageName>Zresetuj hasło</PageName>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "99vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            justifyContent: "center",
            alignItems: "center",
            width: "20rem",
          }}
        >
          <Typography variant="h1">Odzyskaj hasło</Typography>
          <Form
            style={{
              width: "100%",
            }}
            classes={classes}
            onSubmit={async (formData) => {
              const submitData = {
                ...formData,
              };
              try {
                await requestPasswordResetSchema.validate(submitData, {
                  abortEarly: false,
                });

                requestPasswordReset({
                  variables: {
                    ...submitData,
                  },
                });
              } catch (error) {
                setErrors(getFormErrorsFromValidation(error));
              }
            }}
          >
            <TextField
              id="email"
              label="Email"
              defaultValue={preSetEmail}
              error={!!errors?.get("email")}
              helperText={errors?.get("email")}
            />
            <Button type="submit" variant="contained">
              Resetuj
            </Button>
          </Form>
          <Button
            type="submit"
            sx={{ width: "20rem" }}
            onClick={() => navigate(routes.signIn)}
          >
            Wróć
          </Button>
        </Box>
      </Box>
    </Suspense>
  );
}
