import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Admin
import AdminDocumentPage from "@/pages/admin/documents/[id]/page";
import AdminCreatorPage from "@/pages/admin/documents/creator/page";
import AdminDocumentsPage from "@/pages/admin/documents/page";
import AdminPage from "@/pages/admin/page";
import AdminFormsPage from "@/pages/admin/forms/page";
import AdminFormPage from "@/pages/admin/forms/[id]/page";
import AdminGeneratedDocumentsPage from "@/pages/admin/generated-documents/page";
import AdminGeneratedDocumentPage from "@/pages/admin/generated-documents/[id]/page";
import AdminGeneratedDocumentFormPage from "@/pages/admin/generated-documents/[id]/form/page";

// Client
import ClientDocumentsPage from "@/pages/client/browse/page";
import ClientDocumentPage from "@/pages/client/browse/[id]/page";
import ClientFormPage from "@/pages/client/forms/[id]/page";
import ClientGeneratedDocumentsPage from "@/pages/client/generated-documents/page";
import ClientGeneratedDocumentPage from "@/pages/client/generated-documents/[id]/page";
import ClientGeneratedDocumentFormPage from "@/pages/client/generated-documents/[id]/form/page";
import ClientPackagesPage from "@/pages/client/package/page";
import ClientPackagePage from "@/pages/client/package/[slug]/page";
import ClientProfilePage from "@/pages/client/profile/page";
import TransactionsPage from "@/pages/client/transactions/page";

// Global
import SignInPage from "@/pages/sign-in/page";
import SignUpPage from "@/pages/sign-up/page";
import AccountConfirmationPage from "@/pages/account-confirmation/page";

// Wrappers
import AuthWrapper from "./wrappers/AuthWrapper/AuthWrapper";
import LayoutWrapper from "./wrappers/LayoutWrapper/LayoutWrapper";
import ResetPasswordPage from "@/pages/reset-password/page";
import RequestPasswordResetPage from "@/pages/reset-password-request/page";
import AdminClientsPage from "@/pages/admin/clients/page";
import AdminClientPage from "@/pages/admin/clients/[id]/page";
import AdminClientTransactionsPage from "@/pages/admin/clients/transactions/page";
import RoleWrapper from "./wrappers/RoleWrapper";
import TransactionPage from "@/pages/client/transactions/[id]/page";
import routes from "./routes";

export default function AppRouter() {
  return (
    <Router>
      <AuthWrapper>
        <RoleWrapper>
          <LayoutWrapper>
            <Routes>
              <Route path={routes.admin[0]} Component={AdminPage} />
              <Route
                path={routes.admin.documents[0]}
                Component={AdminDocumentsPage}
              />
              <Route
                path={routes.admin.documents.id[0]}
                Component={AdminDocumentPage}
              />
              <Route
                path={routes.admin.documents.creator}
                Component={AdminCreatorPage}
              />
              <Route
                path={routes.admin.clients[0]}
                Component={AdminClientsPage}
              />
              <Route
                path={routes.admin.clients.id[0]}
                Component={AdminClientPage}
              />
              <Route
                path={routes.admin.clients.id.transactions.id}
                Component={AdminClientTransactionsPage}
              />
              <Route path={routes.admin.forms[0]} Component={AdminFormsPage} />
              <Route
                path={routes.admin.forms.id[0]}
                Component={AdminFormPage}
              />
              <Route
                path={routes.admin.generatedDocuments[0]}
                Component={AdminGeneratedDocumentsPage}
              />
              <Route
                path={routes.admin.generatedDocuments.id[0]}
                Component={AdminGeneratedDocumentPage}
              />
              <Route
                path={routes.admin.generatedDocuments.id.form}
                Component={AdminGeneratedDocumentFormPage}
              />

              <Route
                path={routes.client.documents[0]}
                Component={ClientDocumentsPage}
              />
              <Route
                path={routes.client.documents.id[0]}
                Component={ClientDocumentPage}
              />
              <Route
                path={routes.client.forms.id[0]}
                Component={ClientFormPage}
              />
              <Route
                path={routes.client.generatedDocuments[0]}
                Component={ClientGeneratedDocumentsPage}
              />
              <Route
                path={routes.client.generatedDocuments.id[0]}
                Component={ClientGeneratedDocumentPage}
              />
              <Route
                path={routes.client.generatedDocuments.id.form[0]}
                Component={ClientGeneratedDocumentFormPage}
              />
              <Route
                path={routes.client.packages[0]}
                Component={ClientPackagesPage}
              />
              <Route
                path={routes.client.packages.slug[0]}
                Component={ClientPackagePage}
              />
              <Route
                path={routes.client.profile}
                Component={ClientProfilePage}
              />
              <Route
                path={routes.client.transactions[0]}
                Component={TransactionsPage}
              />
              <Route
                path={routes.client.transactions.id[0]}
                Component={TransactionPage}
              />

              <Route path={routes.signIn} Component={SignInPage} />
              <Route path={routes.signUp} Component={SignUpPage} />
              <Route
                path={routes.accountConfirmation}
                Component={AccountConfirmationPage}
              />
              <Route
                path={routes.resetPassword}
                Component={ResetPasswordPage}
              />
              <Route
                path={routes.requestPasswordReset}
                Component={RequestPasswordResetPage}
              />
            </Routes>
          </LayoutWrapper>
        </RoleWrapper>
      </AuthWrapper>
    </Router>
  );
}
