import { USER_ROLES } from "@/apollo/queries/user/types";
import { UserContext } from "@/context/User/userContext";
import routes from "@/router/routes";
import { PropsWithChildren, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function RoleWrapper(props: PropsWithChildren) {
  const { state: userState } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const path = pathname.split("/").filter((notEmpty) => notEmpty);

  if (userState?.role === USER_ROLES.USER && path[0] === "admin") {
    navigate(routes.client.documents[0]);
  }

  return <>{props.children}</>;
}
