import { useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { UserContext } from "@/context/User/userContext";
import { DELETE_USER } from "@/apollo/queries/user/userQuery";
import { useMutation } from "@apollo/client";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { PageName } from "@/utils/app";
import i18next from "@/i18n";
import { extractValueFromUserData } from "@/utils/user";
import routes from "@/router/routes";

export default function ClientProfilePage() {
  const { state: userState } = useContext(UserContext);
  const [deleteUserMutation] = useMutation(DELETE_USER);

  if (!userState) return null;
  const { access_token, subscription, role, id, ...displayData } = userState;

  return (
    <Card sx={{ minHeight: "4rem", position: "relative" }}>
      <PageName>Profil</PageName>
      <CardHeader title="Profil" />
      <Divider />
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Grid container spacing={2}>
          {Object.entries(displayData).map((userData) => {
            return (
              <Grid key={userData[0]} item xs={12} sm={6} md={3}>
                <Typography sx={{ color: grey[500] }} variant="h6">
                  {i18next.t(userData[0])}
                </Typography>
                <Typography>{extractValueFromUserData(userData[1])}</Typography>
              </Grid>
            );
          })}
        </Grid>
        <Box>
          <Link to={routes.client.transactions[0]}>
            <Button onClick={() => {}} variant="outlined">
              Zobacz swoje transakcje
            </Button>
          </Link>
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{ background: red[400], color: "white" }}
            onClick={async () => {
              await deleteUserMutation({
                variables: { deleteUserInput: { id: userState.id } },
              });
              Cookies.remove("user");

              window.location.href = `https://biznescentrum.com/`;
            }}
          >
            Usuń Konto
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
