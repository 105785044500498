import { gql } from "@apollo/client";
import { User } from "./types";
import { PaginationResult } from "@/apollo/paginationTypes";
import { CORE_PRODUCT_FIELDS_SMALL } from "../products/productsQuery";

export type SignUpMutation = {
  signUp: User;
};

export type GetUserDetails = { userDetails: User };
export type GetUsers = {
  users: { list: User[]; pagination: PaginationResult };
};

export type UpdateUserCompanyData = { updateUserCompanyData: User };

const SIGN_UP = gql`
  mutation SignUp($signUpInput: CreateUserInput!) {
    signUp(signUpInput: $signUpInput) {
      id
      role
      name
      subscription {
        id
      }
    }
  }
`;

const GET_USER_DETAILS = gql`
  query UserDetails($getUserDetailsInput: GetUserDetailsInput) {
    userDetails(getUserDetailsInput: $getUserDetailsInput) {
      id
      role
      name
      email
      subscription {
        id
        product {
          ...CoreProductFieldsSmall
        }
      }
      companyType
      companyName
      nip
      regon
      krs
      street
      buildingNumber
      apartmentNumber
      zip
      city
      representatives
      name
      lastName
      shareCapital
      registrationCourt
      billing_companyName
      billing_nip
      billing_street
      billing_buildingNumber
      billing_apartmentNumber
      billing_zip
      billing_city
      createdAt
      updatedAt
    }
  }
  ${CORE_PRODUCT_FIELDS_SMALL}
`;

const DELETE_USER = gql`
  mutation DeleteUser($deleteUserInput: DeleteUserInput!) {
    deleteUser(deleteUserInput: $deleteUserInput) {
      id
    }
  }
`;

const UPDATE_USER_COMPANY_DATA = gql`
  mutation UpdateUserCompanyData(
    $updateUserCompanyDataInput: UpdateUserCompanyDataInput!
  ) {
    updateUserCompanyData(
      updateUserCompanyDataInput: $updateUserCompanyDataInput
    ) {
      name
      companyType
      companyName
      nip
      regon
      krs
      street
      buildingNumber
      apartmentNumber
      city
      zip
      name
      lastName
      representatives
      shareCapital
      registrationCourt
      createdAt
      updatedAt
    }
  }
`;

const CONFIRM_USER_ACCOUNT = gql`
  mutation confirmUserAccount($token: String!) {
    confirmUserAccount(token: $token) {
      id
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($resetPasswordInput: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput) {
      id
    }
  }
`;

const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      id
    }
  }
`;

const GET_USERS = gql`
  query GetAllUsers($pagination: Pagination!, $filters: UserFilters) {
    users(pagination: $pagination, filters: $filters) {
      list {
        id
        email
        name
        lastName
        nip
      }
      pagination {
        count
        pages
      }
    }
  }
`;

export {
  SIGN_UP,
  GET_USER_DETAILS,
  DELETE_USER,
  UPDATE_USER_COMPANY_DATA,
  CONFIRM_USER_ACCOUNT,
  RESET_PASSWORD,
  REQUEST_PASSWORD_RESET,
  GET_USERS,
};
