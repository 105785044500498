import { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import { DocumentCreatorActions } from "./actions";
import { documentCreatorReducer } from "./reducers";
import { DocumentForm } from "@/apollo/queries/forms/formTypes";
import { CompanyTypes } from "@/apollo/queries/user/types";

export type DocumentCreatorInitialState = {
  documentId: string;
  documentName: string;
  packageRank: number;
  targetCompanyType: CompanyTypes;
  description: string;
  form: DocumentForm;
  inputModalChildren: JSX.Element | null;
  isInputModalToggled: boolean;
  html: string;
  json: string;
  version: number;
  publishedVersion: number;
  isPublic: boolean;
};

const initialState: DocumentCreatorInitialState = {
  documentId: "",
  documentName: "",
  description: "",
  packageRank: 1,
  targetCompanyType: CompanyTypes.SOLE_PROPRIETORSHIP,
  form: new DocumentForm(),
  inputModalChildren: null,
  isInputModalToggled: false,
  html: "",
  json: "",
  version: -1,
  publishedVersion: -1,
  isPublic: true,
};

export const DocumentCreatorContext = createContext<{
  state: DocumentCreatorInitialState;
  dispatch: Dispatch<DocumentCreatorActions>;
}>({ state: initialState, dispatch: () => null });

export const DocumentCreatorProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(documentCreatorReducer, initialState);

  return (
    <DocumentCreatorContext.Provider value={{ state, dispatch }}>
      {children}
    </DocumentCreatorContext.Provider>
  );
};
