import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useContext } from "react";
import { DocumentCreatorContext } from "../../../context";
import { DocumentCreatorPayloadTypes } from "../../../context/actions";
import ConditionalMultipleSelectionListForm from "./ConditionalMultipleSelectionListForm";
import { FormNode } from "@/apollo/queries/forms/formTypes";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

type ConditionalMultipleSelectionListProps = {
  preloadedData?: FormNode;
};

export default function ConditionalMultipleSelectionList({
  preloadedData,
}: ConditionalMultipleSelectionListProps) {
  const { dispatch: documentCreatorDispatch } = useContext(
    DocumentCreatorContext
  );

  const addQuestionToDocument = () => {
    documentCreatorDispatch({
      type: DocumentCreatorPayloadTypes.TOGGLE_INPUT_MODAL,
      payload: {
        toggleInputModal: true,
      },
    });

    documentCreatorDispatch({
      type: DocumentCreatorPayloadTypes.SET_INPUT_MODAL_CHILDREN,
      payload: {
        inputModalChildren: (
          <ConditionalMultipleSelectionListForm preloadedData={preloadedData} />
        ),
      },
    });
  };

  const label =
    preloadedData?.variableName || "Pole warunkowego wielokrotnego wyboru";

  return (
    <ListItemButton onClick={addQuestionToDocument}>
      <ListItemIcon>
        <IndeterminateCheckBoxIcon />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
}
