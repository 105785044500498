import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet";

export const getPageName = (pageName?: string) => {
  if (pageName) return `${process.env.REACT_APP_NAME} - ${pageName}`;
  return process.env.REACT_APP_NAME;
};

export const PageName = ({ children }: PropsWithChildren) => {
  return (
    <Helmet>
      <title>{getPageName(children as string)}</title>
    </Helmet>
  );
};
