import UsersList from "@/components/lists/users/UsersList";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

export default function AdminClientsPage() {
  return (
    <Card sx={{ minHeight: "4rem", position: "relative" }}>
      <CardHeader title="Użytkownicy" />
      <Divider />
      <CardContent>
        <UsersList />
      </CardContent>
    </Card>
  );
}
