import { ActionMap } from "@/types/context";
import { UserContextInitialState } from "./userContext";
import { User } from "@/apollo/queries/user/types";

export enum UserReducerPayloadTypes {
  SET_USER,
  UPDATE_USER,
  CLEAR_USER,
}

type UserReducerPayload = {
  [UserReducerPayloadTypes.SET_USER]: User;
  [UserReducerPayloadTypes.UPDATE_USER]: User;
  [UserReducerPayloadTypes.CLEAR_USER]: null;
};

export type UserReducerActions =
  ActionMap<UserReducerPayload>[keyof ActionMap<UserReducerPayload>];

export const userReducer = (
  state: UserContextInitialState,
  action: UserReducerActions
): UserContextInitialState => {
  switch (action.type) {
    case UserReducerPayloadTypes.SET_USER:
      return {
        ...action.payload,
      };
    case UserReducerPayloadTypes.UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case UserReducerPayloadTypes.CLEAR_USER:
      return null;
    default:
      return state;
  }
};
