import FormContainer from "@/components/utils/Form/formContainer";
import { FieldTypes, FormData } from "@/utils/form";
import { Button, TextField, debounce } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useDocumentCreator from "../../../hooks/useDocumentCreator";
import {
  FORM_NODE_FIELD_ID_LENGTH,
  FormNode,
  FormNodeField,
  NodeType,
} from "@/apollo/queries/forms/formTypes";
import Form from "@/components/utils/Form/form";
import BaseFields from "../BaseFields/BaseFields";

type MultipleChoiceBoxFormProps = {
  preloadedData?: FormNode;
};

export default function MultipleChoiceBoxFormProps({
  preloadedData,
}: MultipleChoiceBoxFormProps) {
  const {
    updateDocumentCreatorFormNodes,
    addDocumentCreatorFormNodes,
    toggleInputModal,
  } = useDocumentCreator();

  const [formNodeFields, setFormNodeFields] = useState<FormNodeField[]>([
    new FormNodeField({ label: "", value: "" }),
  ]);

  useEffect(() => {
    if (preloadedData?.fields) {
      setFormNodeFields(
        preloadedData.fields.map((field) => new FormNodeField(field))
      );
    }
  }, [preloadedData]);

  const addFormNodeField = () => {
    setFormNodeFields(
      formNodeFields.concat(new FormNodeField({ label: "", value: "" }))
    );
  };

  const handleOptionLabelChange = debounce((event, formNodeIndex) => {
    const value = event.target.value;
    const newFormNodeFields = [...formNodeFields];
    newFormNodeFields[formNodeIndex] = new FormNodeField({
      ...newFormNodeFields[formNodeIndex],
      label: value,
    });

    setFormNodeFields(newFormNodeFields);
  }, 200);

  const handleOptionValueChange = debounce((event, formNodeIndex) => {
    const value = event.target.value;
    const newFormNodeFields = [...formNodeFields];
    newFormNodeFields[formNodeIndex] = new FormNodeField({
      ...newFormNodeFields[formNodeIndex],
      value,
    });

    setFormNodeFields(newFormNodeFields);
  }, 200);

  const parseData = (data: FormData): FormNode => {
    const fields: FormNodeField[] = [];
    const formNodeData = new FormNode(data as unknown as FormNode);

    Object.keys(data).map((key) => {
      if (key.includes("grouped_")) {
        const groupedStringLength = FieldTypes.GROUPED.length;
        const keyLength = key.length;
        const fieldId = key.slice(
          groupedStringLength,
          keyLength + 1 - FORM_NODE_FIELD_ID_LENGTH + 1
        );

        const type = key.slice(
          groupedStringLength + FORM_NODE_FIELD_ID_LENGTH + 1,
          keyLength
        );

        const createdOption = {
          [type]: data[key],
          id: fieldId,
        };

        const foundIndex = fields.findIndex((option) => option.id === fieldId);

        if (foundIndex < 0) {
          fields.push(createdOption as unknown as FormNodeField);
        } else {
          fields[foundIndex] = {
            ...fields[foundIndex],
            ...createdOption,
          };
        }

        delete data[key];
      }
    });

    const finalData: FormNode = {
      ...formNodeData,
      fields,
      id: preloadedData?.id || "",
    };

    return new FormNode(finalData);
  };

  return (
    <Form
      onSubmit={(formData) => {
        if (formData) {
          const parsedData = parseData(formData);
          if (preloadedData?.id) {
            updateDocumentCreatorFormNodes(parsedData);
          } else {
            addDocumentCreatorFormNodes(parsedData);
          }
        }
      }}
    >
      <FormContainer>
        <BaseFields
          preloadedData={preloadedData}
          nodeType={NodeType.MultipleSelectionList}
        />
        {formNodeFields.map((formNodeField, formNodeFieldIndex) => (
          <div key={formNodeField.id}>
            <TextField
              sx={{ width: "48%" }}
              label="Opcja"
              id={FieldTypes.GROUPED + formNodeField.id + "_label"}
              onChange={(event) =>
                handleOptionLabelChange(event, formNodeFieldIndex)
              }
              defaultValue={formNodeField.label}
            />
            <TextField
              sx={{ width: "48%" }}
              label="Wartość"
              id={FieldTypes.GROUPED + formNodeField.id + "_value"}
              multiline
              onChange={(event) =>
                handleOptionValueChange(event, formNodeFieldIndex)
              }
              defaultValue={formNodeField.value}
            />
          </div>
        ))}
        <Button
          onClick={addFormNodeField}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          Dodaj kolejne
        </Button>
        <Button type="submit">Submit</Button>
      </FormContainer>
    </Form>
  );
}
