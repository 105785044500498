import { FormNode, NodeType } from "@/apollo/queries/forms/formTypes";
import { TextField } from "@mui/material";

export default function BaseFields({
  preloadedData,
  nodeType,
}: {
  preloadedData?: FormNode;
  nodeType: NodeType;
}) {
  return (
    <>
      <TextField
        sx={{
          display: "none",
        }}
        id="type"
        value={nodeType}
      />
      <TextField
        id="variableName"
        placeholder={"Np. Dane podawane na stronie"}
        label={"Nazwa zmiennej wyświetlanej w dokumencie"}
        multiline
        sx={{
          width: "100%",
        }}
        defaultValue={preloadedData?.variableName}
      />
      <TextField
        id="question"
        placeholder={"Treść pytania"}
        multiline
        sx={{
          width: "100%",
        }}
        defaultValue={preloadedData?.question}
      />
    </>
  );
}
