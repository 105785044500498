import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  SwipeableDrawer,
  Switch,
} from "@mui/material";
import { useState } from "react";
import useDocumentCreator from "../hooks/useDocumentCreator";
import { LegalDocument } from "@/apollo/queries/documents/types";

import Categories from "./Categories";
import CompanyType from "./CompanyType";
import PackageRank from "./PackageRank";

export default function DocumentCreatorSettings({
  preloadedData,
}: {
  preloadedData?: LegalDocument;
}) {
  const [isDrawerToggled, toggleDrawer] = useState(false);

  const { setDocumentPublicState } = useDocumentCreator();

  return (
    <Box sx={{ marginTop: "1rem", display: "flex", gap: 2 }}>
      <SwipeableDrawer
        anchor={"right"}
        open={isDrawerToggled}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: 2,
            minWidth: "45vw",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FormControlLabel
                label="Widoczność"
                control={
                  <Switch
                    defaultChecked={preloadedData?.isPublic}
                    onChange={(event) => {
                      setDocumentPublicState(event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={5}>
              <PackageRank preloadedData={preloadedData} />
            </Grid>
            <Grid item xs={5}>
              <CompanyType preloadedData={preloadedData} />
            </Grid>
            <Grid item xs={12}>
              <Categories preloadedData={preloadedData} />
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>
      <Button onClick={() => toggleDrawer(true)} variant="outlined">
        Ustawienia
      </Button>
    </Box>
  );
}
