import React, {
  createContext,
  useReducer,
  Dispatch,
  PropsWithChildren,
  useEffect,
} from "react";
import { LexicalEditorActions, lexicalEditorReducer } from "./reducers";
import { LexicalEditor } from "lexical";

export type LexicalEditorInitialState = {
  editor: LexicalEditor | null;
};

const initialState: LexicalEditorInitialState = {
  editor: null,
};

const LexicalEditorContext = createContext<{
  state: LexicalEditorInitialState;
  dispatch: Dispatch<LexicalEditorActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const LexicalEditorProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(lexicalEditorReducer, initialState);

  return (
    <LexicalEditorContext.Provider value={{ state, dispatch }}>
      {children}
    </LexicalEditorContext.Provider>
  );
};

export { LexicalEditorProvider, LexicalEditorContext };
