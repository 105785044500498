import {
  DocumentForm,
  FormNode,
  NodeType,
} from "@/apollo/queries/forms/formTypes";
import MultipleSelectionList from "./Fields/MultipleSelectionList/MultipleSelectionList";
import { Box, Button, InputLabel, Typography } from "@mui/material";
import Input from "./Fields/Input";
import DateInput from "./Fields/DateInput";
import SingleSelectionList from "./Fields/SingleSelectionList/SingleSelectionList";
import Form from "../utils/Form";
import useFormRenderer from "./hooks/useFormRenderer";
import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_FORM } from "@/apollo/queries/forms/formsQuery";
import { FindFormInput } from "@/apollo/queries/forms/formInputTypes";
import ConditionalMultipleSelectionList from "./Fields/ConditionalMultipleSelectionList/ConditionalMultipleSelectionList";
import classes from "./formRenderer.module.scss";
import { object, string } from "yup";
import { FormErrors, getFormErrorsFromValidation } from "@/utils/form";
import { useNavigate } from "react-router-dom";
import { DataCompatibilityModal } from "./DataCompatibilityModal";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import routes from "@/router/routes";

type FormProps = {
  documentId?: string;
  preloadedForm?: DocumentForm;
  isClient?: boolean;
  hideValidation?: boolean;
  generatedDocumentId?: string;
};

export default function FormRenderer({
  documentId = "0",
  isClient,
  preloadedForm,
  generatedDocumentId,
  hideValidation = false,
}: FormProps) {
  const [errors, setErrors] = useState<FormErrors>();

  const navigate = useNavigate();
  const { data, error } = useQuery<{ form: DocumentForm }>(GET_FORM, {
    variables: {
      findFormInput: new FindFormInput({ documentId }),
    },
    skip: !!preloadedForm,
    fetchPolicy: "cache-and-network",
  });

  const documentForm = useMemo(
    () => data?.form || preloadedForm,
    [data?.form, preloadedForm]
  );

  const { onSubmit, submittingForm, compatiblityCheck } = useFormRenderer({
    formNodes: documentForm?.formNodes || [],
    generatedDocumentId,
  });

  const formSchema = useMemo(() => {
    const formSchemaObject = {};
    documentForm?.formNodes.forEach((formNode) => {
      formNode?.fields?.forEach((field) => {
        if (
          formNode.type !== NodeType.DateInput &&
          formNode.type !== NodeType.Input
        )
          return;

        (formSchemaObject as any)[field.id] = string().required();
      });
    });

    return object(formSchemaObject);
  }, [documentForm?.formNodes]);

  const RenderFields = useCallback(
    ({ documentForm }: { documentForm: DocumentForm }) => {
      const selectFieldComponent = (formNode: FormNode) => {
        const label = (
          <InputLabel key={formNode.question}>{formNode.question}</InputLabel>
        );
        let renderedField: JSX.Element | null = null;

        switch (formNode.type) {
          case NodeType.SingleSelectionList: {
            renderedField = (
              <SingleSelectionList key={formNode.id} {...formNode} />
            );
            break;
          }
          case NodeType.MultipleSelectionList: {
            renderedField = (
              <MultipleSelectionList key={formNode.id} {...formNode} />
            );
            break;
          }
          case NodeType.ConditionalMultipleSelectionList: {
            renderedField = (
              <ConditionalMultipleSelectionList
                key={formNode.id}
                {...formNode}
                hideValidation={hideValidation}
              />
            );
            break;
          }
          case NodeType.Input: {
            renderedField = (
              <Input key={formNode.id} {...formNode} errors={errors} />
            );
            break;
          }
          case NodeType.DateInput: {
            renderedField = (
              <DateInput key={formNode.id} {...formNode} errors={errors} />
            );
            break;
          }
        }

        return [label, renderedField];
      };

      const mappedFormNodes = documentForm.formNodes
        .map((formNode) => {
          const fieldComponent = selectFieldComponent(formNode);
          if (!fieldComponent[1]) return null;
          return (
            <div key={formNode.id} style={{ marginBottom: "1rem" }}>
              {fieldComponent}
            </div>
          );
        })
        .filter((x) => x);

      return (
        <Box>
          <Form
            classes={{
              root: classes.root,
            }}
            onSubmit={async (formData) => {
              try {
                await formSchema.validate(formData, { abortEarly: false });
                const generatedDocument = await onSubmit(
                  documentForm,
                  formData
                );
                const prefix = isClient ? "client" : "admin";
                if (!generatedDocument || !generatedDocument.submitForm.id) {
                  throw new Error("Nie udało się wygenerować formularza.");
                }

                toast.success("Wygenerowano dokument");
                navigate(
                  routes[prefix].generatedDocuments.id.make(
                    generatedDocument.submitForm.id
                  )
                );
              } catch (error) {
                toast.error("Nie udało się wygenerować dokumentu");
                setErrors(getFormErrorsFromValidation(error));
              }
            }}
          >
            <div>{mappedFormNodes}</div>
            <div>
              <Button
                type="submit"
                variant="contained"
                disabled={!compatiblityCheck?.isCompatible}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Box>
      );
    },
    [
      compatiblityCheck?.isCompatible,
      errors,
      formSchema,
      hideValidation,
      isClient,
      navigate,
      onSubmit,
    ]
  );

  if (!documentForm && !error) return <Spinner />;

  if (documentForm) {
    return (
      <Box>
        <Spinner loading={submittingForm} />
        <DataCompatibilityModal documentForm={documentForm} />
        <RenderFields documentForm={documentForm} />
      </Box>
    );
  }

  return <Typography>Nie udało się znaleźć szukanego formularza.</Typography>;
}
