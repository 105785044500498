"use client";

import React from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";

import { SettingsContext, useSettings } from "./context/SettingsContext";
import { SharedAutocompleteContext } from "./context/SharedAutocompleteContext";
import { SharedHistoryContext } from "./context/SharedHistoryContext";
import Editor from "./Editor";
import PlaygroundNodes from "./nodes/PlaygroundNodes";
import { TableContext } from "./plugins/TablePlugin";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import Reader, { ReaderProps } from "./Reader";

type LexicalEditorProps = {
  readMode?: boolean;
  readerData?: ReaderProps;
};
export const lexicalComposerInitialConfig = {
  namespace: "Playground",
  nodes: [...PlaygroundNodes],
  onError: (error: Error) => {
    throw error;
  },
  theme: PlaygroundEditorTheme,
};

function EditorComponent(): JSX.Element {
  return (
    <LexicalComposer initialConfig={lexicalComposerInitialConfig}>
      <SharedHistoryContext>
        <TableContext>
          <SharedAutocompleteContext>
            <div className="editor-shell">
              <Editor />
            </div>
          </SharedAutocompleteContext>
        </TableContext>
      </SharedHistoryContext>
    </LexicalComposer>
  );
}

type ReaderComponentProps = {
  data: ReaderProps;
};

function ReaderComponent({ data }: ReaderComponentProps): JSX.Element {
  const initialConfig = {
    namespace: "Playground",
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <TableContext>
          <SharedAutocompleteContext>
            <div className="reader-shell">
              <Reader {...data} />
            </div>
          </SharedAutocompleteContext>
        </TableContext>
      </SharedHistoryContext>
    </LexicalComposer>
  );
}

export default function LexicalEditor({
  readMode = false,
  readerData,
}: LexicalEditorProps): JSX.Element {
  return (
    <SettingsContext>
      {readMode && readerData ? (
        <ReaderComponent data={readerData} />
      ) : (
        <EditorComponent />
      )}
    </SettingsContext>
  );
}
