"use client";

import { MenuDrawerContextProvider } from "@/context/MenuDrawer/menuDrawerContext";
import { UserContextProvider } from "@/context/User/userContext";
import theme from "@/mui/theme";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export function AppProviders({ children }: { children: React.ReactNode }) {
  return (
    <UserContextProvider>
      <MenuDrawerContextProvider>
        <ThemeProvider theme={theme}>
          <ToastContainer
            position="top-right"
            autoClose={500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            theme="light"
          />
          {children}
        </ThemeProvider>
      </MenuDrawerContextProvider>
    </UserContextProvider>
  );
}
