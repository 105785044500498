import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import Form from "../utils/Form/form";
import {
  CREATE_SUBSCRIPTION,
  CreateSubscription,
} from "@/apollo/queries/subscriptions/subscriptionsQuery";
import { useMutation } from "@apollo/client";
import FormGridContainer from "../utils/Form/formGridContainer";
import PackageDescription from "./PackageDescription";
import { Product } from "@/apollo/queries/products/types";
import { object, string } from "yup";
import { useContext, useEffect, useState } from "react";
import {
  FormData,
  FormErrors,
  getFormErrorsFromValidation,
} from "@/utils/form";
import { CreateSubscriptionInput } from "@/apollo/queries/subscriptions/subscriptionsInputTypes";
import validateNIP from "@/utils/validateNIP";
import { UserContext } from "@/context/User/userContext";
import { toast } from "react-toastify";

export default function PackageForm({
  productSlug,
  product,
}: {
  productSlug?: string;
  product?: Product;
}) {
  const { state: userState } = useContext(UserContext);
  const [errors, setErrors] = useState<FormErrors>();

  const packageFormSchema = object({
    billing_companyName: string().required(),
    billing_nip: string().required(),
    billing_street: string().required(),
    billing_buildingNumber: string().required(),
    billing_apartmentNumber: string(),
    billing_zip: string().required(),
    billing_city: string().required(),
  });

  const [createSubscriptionMutation, { error }] =
    useMutation<CreateSubscription>(CREATE_SUBSCRIPTION);

  useEffect(() => {
    if (error) {
      toast.error("Nie udało się dokonać płatności.");
    }
  }, [error]);

  const onSubmit = async (formData: FormData) => {
    try {
      try {
        packageFormSchema.validateSync(formData, {
          abortEarly: false,
        });
        validateNIP(formData.billing_nip, "billing_nip");

        const { data } = await createSubscriptionMutation({
          variables: {
            createSubscriptionInput: new CreateSubscriptionInput({
              ...(formData as CreateSubscriptionInput),
              productSlug,
            }),
          },
        });

        const token = data?.createSubscription.token;
        window.location.href = `https://sandbox.przelewy24.pl/trnRequest/${token}`;
      } catch (error) {
        setErrors(getFormErrorsFromValidation(error));
      }
    } catch (e) {}
  };

  if (!product) return null;

  return (
    <Card sx={{ minHeight: "4rem", position: "relative" }}>
      <CardHeader title={`Pakiet ${product?.name}`} />
      <Divider />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item lg={6} md={12}>
            <PackageDescription product={product} />
          </Grid>
          <Grid item lg={6} md={12}>
            <Form onSubmit={async (formData) => await onSubmit(formData)}>
              <FormGridContainer>
                <Grid item lg={8} md={8} xs={12}>
                  <TextField
                    id="billing_companyName"
                    label="Nazwa firmy"
                    sx={{ width: "100%" }}
                    error={!!errors?.get("billing_companyName")}
                    helperText={errors?.get("billing_companyName")}
                    defaultValue={userState?.billing_companyName}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    id="billing_nip"
                    label="NIP"
                    sx={{ width: "100%" }}
                    disabled={!!userState?.billing_nip}
                    error={!!errors?.get("billing_nip")}
                    helperText={errors?.get("billing_nip")}
                    defaultValue={userState?.billing_nip}
                  />
                </Grid>
                <Grid item lg={7} xs={12}>
                  <TextField
                    id="billing_street"
                    label="Ulica"
                    sx={{ width: "100%" }}
                    error={!!errors?.get("billing_street")}
                    helperText={errors?.get("billing_street")}
                    defaultValue={userState?.billing_street}
                  />
                </Grid>
                <Grid item lg={2.5} xs={6}>
                  <TextField
                    id="billing_buildingNumber"
                    label="Numer budynku"
                    sx={{ width: "100%" }}
                    error={!!errors?.get("billing_buildingNumber")}
                    helperText={errors?.get("billing_buildingNumber")}
                    defaultValue={userState?.billing_buildingNumber}
                  />
                </Grid>
                <Grid item lg={2.5} xs={6}>
                  <TextField
                    id="billing_apartmentNumber"
                    label="Lokal"
                    sx={{ width: "100%" }}
                    error={!!errors?.get("billing_apartmentNumber")}
                    helperText={errors?.get("billing_apartmentNumber")}
                    defaultValue={userState?.billing_apartmentNumber}
                  />
                </Grid>
                <Grid item lg={3} md={3} xs={12}>
                  <TextField
                    id="billing_zip"
                    label="Kod pocztowy"
                    sx={{ width: "100%" }}
                    error={!!errors?.get("billing_zip")}
                    helperText={errors?.get("billing_zip")}
                    defaultValue={userState?.billing_zip}
                  />
                </Grid>
                <Grid item lg={9} md={9} xs={12}>
                  <TextField
                    id="billing_city"
                    label="Miasto"
                    sx={{ width: "100%" }}
                    error={!!errors?.get("billing_city")}
                    helperText={errors?.get("billing_city")}
                    defaultValue={userState?.billing_city}
                  />
                </Grid>
              </FormGridContainer>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button type="submit" variant="contained">
                  Zapłać
                </Button>
              </Box>
            </Form>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
