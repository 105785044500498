import i18n from "i18next";

const resources = {
  pl: {
    translation: {
      errorValidationType: {
        required: "Pole jest wymagane",
      },
      CORPORATION: "Spółka",
      SOLE_PROPRIETORSHIP: "Jednoosobowa działalność gospodarcza",
      apartmentNumber: "Numer lokalu",
      billing_apartmentNumber: "Numer lokalu",
      billing_buildingNumber: "Numer budynku",
      billing_city: "Miasto",
      billing_companyName: "Nazwa firmy",
      billing_nip: "NIP",
      billing_street: "Ulica",
      billing_zip: "Kod pocztowy",
      buildingNumber: "Numer budynku",
      city: "Miasto",
      companyName: "Nazwa firmy",
      companyType: "Rodzaj działalności",
      createdAt: "Stworzono",
      email: "E-mail",
      krs: "KRS",
      lastName: "Nazwisko",
      name: "Imię",
      nip: "NIP",
      registrationCourt: "Sąd rejestracyjny",
      regon: "REGON",
      representatives: "Reprezentanci",
      shareCapital: "Kapitał zakładowy",
      street: "Ulica",
      updatedAt: "Zaktualizowano o",
      zip: "Kod pocztowy",
      package: {
        maximumEntitiesAmount: "Maksymalna ilość podmiotów",
        maximumMonthlyGenerations:
          "Maksymalna liczba wygenerowanych dokumentów (miesięcznie)",
        canExportToPDF: "Możliwość eksportowania do PDF",
        canExportToWord: "Możliwość eksportowania do Word",
        canExportToHTML: "Możliwość eksportowania do HTML",
        canUseScript:
          "Dostęp do usługi umożliwiającej automatyczną publikację dokumentów na stronie internetowej",
        documentsAutoUpdate: "Automatyczna aktualizacja dokumentów",
      },
    },
  },
};

i18n.init({
  resources,
  lng: "pl",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
