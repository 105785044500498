import GeneratedDocumentsList from "@/components/lists/generatedDocuments/GeneratedDocumentsList";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

export default function AdminGeneratedDocumentsPage() {
  return (
    <Card sx={{ position: "relative", minHeight: "2rem" }}>
      <CardHeader title="Generated documents" />
      <Divider />
      <CardContent>
        <GeneratedDocumentsList />
      </CardContent>
    </Card>
  );
}
