import {
  $applyNodeReplacement,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  TextNode,
} from "lexical";
import utils from "@lexical/utils";
import { SerializedLexicalNodeWithProps } from "../../types";
import theme from "../../themes/PlaygroundEditorTheme";

export class InputPropsCondtions {
  fieldId: string;
  list: Array<{
    fieldId: string;
    mustBe: string;
  }>;

  constructor(data: InputPropsCondtions) {
    this.fieldId = data.fieldId;
    this.list = data.list.map((input) => ({
      fieldId: input.fieldId,
      mustBe: input.mustBe,
    }));
  }
}

export class DocumentFormInputNodeProps_InputProps {
  date?: {
    format: string;
  };
  input?: {
    extendsDocumentName?: {
      format: string;
      enabled: boolean;
    };
  };
  conditions?: InputPropsCondtions[];

  constructor(data: DocumentFormInputNodeProps_InputProps) {
    this.date = {
      format: data.date?.format ?? "",
    };
    this.input = {
      extendsDocumentName: {
        format: data.input?.extendsDocumentName?.format ?? "",
        enabled: data.input?.extendsDocumentName?.enabled ?? false,
      },
    };
    this.conditions = data.conditions?.map(
      (condition) => new InputPropsCondtions(condition)
    );
  }
}

export class DocumentFormInputNodeProps {
  id: string;
  nodeKey?: string;
  label: string;
  inputProps?: DocumentFormInputNodeProps_InputProps;
  changeTo?: string;

  constructor(data: DocumentFormInputNodeProps) {
    this.id = data.id;
    this.nodeKey = data.nodeKey;
    this.label = data.label;
    this.inputProps = data.inputProps;
    this.changeTo = data.changeTo;
  }
}

export class DocumentFormInputNode extends TextNode {
  props: DocumentFormInputNodeProps;

  static getType(): string {
    return "document-form-input";
  }

  static clone(node: DocumentFormInputNode): DocumentFormInputNode {
    return new DocumentFormInputNode(node.props, node.__text, node.__key);
  }

  static importJSON(
    serializedNode: SerializedLexicalNodeWithProps<DocumentFormInputNodeProps>
  ): DocumentFormInputNode {
    serializedNode.props = {
      ...serializedNode.props,
      label: serializedNode.props.changeTo
        ? serializedNode.props.changeTo
        : serializedNode.props.label,
    };

    const node = $createDocumentFormInputNode(serializedNode.props);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(props: DocumentFormInputNodeProps, text?: string, key?: NodeKey) {
    super(text ?? props.label, key);
    this.props = props;
  }

  exportJSON(): SerializedLexicalNodeWithProps<DocumentFormInputNodeProps> {
    return {
      ...super.exportJSON(),
      type: "document-form-input",
      props: this.props,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.className = "document-form-input";
    utils.addClassNamesToElement(dom, config.theme.documentFormInput);
    return dom;
  }

  exportDOM(config: LexicalEditor): DOMExportOutput {
    const domOutput = super.exportDOM(config);
    const element = domOutput.element;
    if (element) {
      if (element.classList.contains(theme.documentFormInput)) {
        element.classList.remove(theme.documentFormInput);
      } else {
        const cl = element.querySelector("." + theme.documentFormInput);
        cl?.classList.remove(theme.documentFormInput);
      }
    }
    return domOutput;
  }

  setLabel(label: string) {
    const writable = this.getWritable();
    const props = {
      ...this.props,
      label,
    };
    writable.replace($createDocumentFormInputNode(props));
    writable.props.label = label;
  }

  isUnmergeable(): true {
    return true;
  }

  isTextEntity(): true {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

export function $createDocumentFormInputNode(
  props: DocumentFormInputNodeProps
): DocumentFormInputNode {
  const documentFormInputNode = new DocumentFormInputNode(props);
  documentFormInputNode.setMode("segmented").toggleDirectionless();
  return $applyNodeReplacement(documentFormInputNode);
}

export function $isDocumentFormInputNode(node?: LexicalNode): boolean {
  return node?.__type === DocumentFormInputNode.getType();
}
