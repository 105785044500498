import { gql } from "@apollo/client";

export type CreateSubscription = { createSubscription: any };

const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription(
    $createSubscriptionInput: CreateSubscriptionInput!
  ) {
    createSubscription(createSubscriptionInput: $createSubscriptionInput) {
      token
    }
  }
`;

export { CREATE_SUBSCRIPTION };
