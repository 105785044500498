import { FormConfig, FormNode } from "@/apollo/queries/forms/formTypes";
import { CompanyTypes } from "@/apollo/queries/user/types";
import { ActionMap } from "@/types/context.js";

export type DocumentCreatorActions =
  ActionMap<DocumentCreatorPayload>[keyof ActionMap<DocumentCreatorPayload>];

export enum DocumentCreatorPayloadTypes {
  SET_DOCUMENT_BASE_DATA,
  SET_DOCUMENT_ID,
  SET_DOCUMENT_NAME,
  SET_DOCUMENT_DESCRIPTION,
  SET_DOCUMENT_PACKAGE_RANK,
  SET_DOCUMENT_TARGET_COMPANY_TYPE,
  SET_DOCUMENT_VERSION,
  SET_DOCUMENT_PUBLISHED_VERSION,
  SET_DOCUMENT_PUBLIC_STATE,
  UPDATE_FORM_NODES,
  ADD_FORM_NODE,
  SET_FORM_NODES,
  DELETE_FORM_NODE,
  SET_FORM,
  SET_INPUT_MODAL_CHILDREN,
  TOGGLE_INPUT_MODAL,
  SET_DOCUMENT,
}

export type DocumentCreatorPayload = {
  [DocumentCreatorPayloadTypes.SET_DOCUMENT_BASE_DATA]: {
    documentId: string;
    documentName: string;
    version: number;
    publishedVersion: number;
    form: FormConfig;
    description: string;
    packageRank: number;
    targetCompanyType: CompanyTypes;
    isPublic: boolean;
  };
  [DocumentCreatorPayloadTypes.SET_DOCUMENT_ID]: {
    documentId: string;
  };
  [DocumentCreatorPayloadTypes.SET_DOCUMENT_NAME]: {
    documentName: string;
  };
  [DocumentCreatorPayloadTypes.SET_DOCUMENT_DESCRIPTION]: {
    description: string;
  };
  [DocumentCreatorPayloadTypes.SET_DOCUMENT_PACKAGE_RANK]: {
    packageRank: number;
  };
  [DocumentCreatorPayloadTypes.SET_DOCUMENT_VERSION]: {
    version: number;
  };
  [DocumentCreatorPayloadTypes.SET_DOCUMENT_PUBLISHED_VERSION]: {
    publishedVersion: number;
  };
  [DocumentCreatorPayloadTypes.SET_DOCUMENT_PUBLIC_STATE]: {
    isPublic: boolean;
  };
  [DocumentCreatorPayloadTypes.SET_DOCUMENT_TARGET_COMPANY_TYPE]: {
    targetCompanyType: CompanyTypes;
  };
  [DocumentCreatorPayloadTypes.UPDATE_FORM_NODES]: {
    formNodes: FormNode;
  };
  [DocumentCreatorPayloadTypes.SET_FORM_NODES]: {
    formNodes: FormNode[];
  };
  [DocumentCreatorPayloadTypes.DELETE_FORM_NODE]: {
    formNode: FormNode["id"];
  };
  [DocumentCreatorPayloadTypes.SET_FORM]: {
    form: FormConfig;
  };
  [DocumentCreatorPayloadTypes.ADD_FORM_NODE]: {
    formNode: FormNode;
  };
  [DocumentCreatorPayloadTypes.SET_INPUT_MODAL_CHILDREN]: {
    inputModalChildren: JSX.Element;
  };
  [DocumentCreatorPayloadTypes.TOGGLE_INPUT_MODAL]: {
    toggleInputModal: boolean;
  };
  [DocumentCreatorPayloadTypes.SET_DOCUMENT]: {
    html: string;
    json: string;
  };
};
