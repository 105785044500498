import { List, TextField } from "@mui/material";
import MultipleSelectionBox from "./lib/MultipleSelectionList";
import SingleSelectionBox from "./lib/SingleSelectionList";
import Input from "./lib/Input";
import ConditionalMultipleSelectionList from "./lib/ConditionalMultipleSelectionList/conditionalMultipleSelectionList";
import DateInput from "./lib/DateInput/DateInput";
import DateInsertField from "./lib/DateInsert";
import CompanyInsert from "./lib/CompanyInsert/CompanyInsert";

export default function NodesInputList() {
  return (
    <div>
      <List>
        <Input />
        <CompanyInsert />
        <DateInput />
        <DateInsertField />
        <ConditionalMultipleSelectionList />
        <MultipleSelectionBox />
        <SingleSelectionBox />
      </List>
    </div>
  );
}
