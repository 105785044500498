import { Product } from "../products/types";

export enum CompanyTypes {
  SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP",
  CORPORATION = "CORPORATION",
}

export enum USER_ROLES {
  USER = "USER",
  ADMIN = "ADMIN",
}

export type Subscription = {
  id: string;
  product: Product;
};

export class User {
  [key: string]: User[keyof User];
  access_token: string;
  id: string;
  role: USER_ROLES;
  name: string;
  email: string;
  subscription: Subscription;
  apartmentNumber: string;
  billing_apartmentNumber: string;
  billing_buildingNumber: string;
  billing_city: string;
  billing_companyName: string;
  billing_nip: string;
  billing_street: string;
  billing_zip: string;
  buildingNumber: string;
  city: string;
  companyName: string;
  companyType: CompanyTypes;
  createdAt: string;
  krs: string;
  lastName: string;
  nip: string;
  registrationCourt: string;
  regon: string;
  representatives: string[];
  shareCapital: string;
  street: string;
  updatedAt: string;

  constructor(data: User) {
    this.access_token = data.access_token;
    this.id = data?.id;
    this.name = data.name;
    this.email = data.email;
    this.subscription = data.subscription;
    this.apartmentNumber = data.apartmentNumber;
    this.billing_apartmentNumber = data.billing_apartmentNumber;
    this.billing_buildingNumber = data.billing_buildingNumber;
    this.billing_city = data.billing_city;
    this.billing_companyName = data.billing_companyName;
    this.billing_nip = data.billing_nip;
    this.billing_street = data.billing_street;
    this.billing_zip = data.billing_zip;
    this.buildingNumber = data.buildingNumber;
    this.city = data.city;
    this.companyName = data.companyName;
    this.createdAt = data.createdAt;
    this.krs = data.krs;
    this.lastName = data.lastName;
    this.name = data.name;
    this.nip = data.nip;
    this.registrationCourt = data.registrationCourt;
    this.regon = data.regon;
    this.representatives = data.representatives;
    this.role = data.role ?? USER_ROLES.USER;
    this.shareCapital = data.shareCapital;
    this.street = data.street;
    this.updatedAt = data.updatedAt;
    this.companyType = data.companyType ?? CompanyTypes.SOLE_PROPRIETORSHIP;
  }
}
