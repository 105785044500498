import { Box, Button, Grid, useTheme } from "@mui/material";
import NodesInputList from "../Nodes/NodesInputList";
import { useCallback, useState } from "react";
import useDocumentCreator from "../hooks/useDocumentCreator";

import classes from "./styles.module.css";
import NodesOutputList from "../Nodes/NodesOutputList";

export default function DocumentCreatorSidebar() {
  const theme = useTheme();
  const [showFields, toggleShowFields] = useState(false);

  const {
    saveDocument,
    updatingDocument,
    publishDocument,
    documentCreatorState,
  } = useDocumentCreator();

  const { version, publishedVersion } = documentCreatorState;

  const handlePublishDocument = useCallback(() => {
    const hasConfirmed = window.confirm(
      "Opublikowanie dokumentu wiąże się z poinformowaniem użytkowników o jego aktualizacji. Czy jesteś pewien, że teraz chcesz opublikować dokument?"
    );
    if (hasConfirmed) {
      return publishDocument();
    }
  }, [publishDocument]);

  return (
    <Box
      sx={{
        background: theme.palette.grey[100],
        position: "sticky",
        top: "5rem",
        border: "1px solid " + theme.palette.grey[200],
      }}
    >
      <Box
        sx={{
          display: "flex",
          background: "white",
          overflow: "hidden",
        }}
      >
        <Button
          disabled={!showFields}
          sx={{ flex: 1 }}
          onClick={() => toggleShowFields(false)}
          variant="outlined"
        >
          Przegladaj
        </Button>
        <Button
          disabled={showFields}
          sx={{ flex: 1 }}
          onClick={() => toggleShowFields(true)}
          variant="outlined"
        >
          Pola
        </Button>
      </Box>
      <Box sx={{ position: "relative", height: "80vh" }}>
        <div
          className={
            !showFields ? classes.menuElement : classes.menuElement_hidden
          }
        >
          <NodesInputList />
        </div>
        <div
          className={
            showFields ? classes.menuElement : classes.menuElement_hidden
          }
        >
          <NodesOutputList />
        </div>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button
            disabled={updatingDocument}
            onClick={saveDocument}
            variant="contained"
            sx={{ width: "100%" }}
          >
            Zapisz
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={version === publishedVersion || updatingDocument}
            onClick={handlePublishDocument}
            variant="contained"
            sx={{ width: "100%" }}
          >
            Publikuj
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
