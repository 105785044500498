import { gql } from "@apollo/client";
import { DocumentForm } from "./formTypes";

export type GetForm = { form: DocumentForm };
export type SubmitForm = { submitForm: DocumentForm };

const GET_FORM = gql`
  query Form($findFormInput: FindFormInput!) {
    form(findFormInput: $findFormInput) {
      id
      createdAt
      updatedAt
      formNodes {
        question
        type
        id
        fields {
          id
          label
          value
        }
        inputProps {
          conditions {
            fieldId
            list {
              fieldId
              mustBe
            }
          }
          date {
            format
          }
        }
      }
    }
  }
`;

const SUBMIT_FORM = gql`
  mutation SubmitForm($submitFormInput: SubmitFormInput!) {
    submitForm(submitFormInput: $submitFormInput) {
      id
    }
  }
`;

export { GET_FORM, SUBMIT_FORM };
