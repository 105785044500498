import { gql } from "@apollo/client";
import { Product } from "./types";

export type GetProducts = { products: Product[] };
export type GetProduct = { product: Product };

export const CORE_PRODUCT_FIELDS = gql`
  fragment CoreProductFields on Product {
    id
    name
    shortDescription
    description
    packageRank
    slug
    features {
      maximumEntitiesAmount
      maximumMonthlyGenerations
      canExportToPDF
      canExportToWord
      canExportToHTML
      canUseScript
      documentsAutoUpdate
    }
    price {
      annual
      monthly
    }
    createdAt
    updatedAt
  }
`;

export const CORE_PRODUCT_FIELDS_SMALL = gql`
  fragment CoreProductFieldsSmall on Product {
    name
    packageRank
    price {
      annual
      monthly
    }
  }
`;

const GET_PRODUCTS = gql`
  query Products {
    products {
      ...CoreProductFields
    }
  }
  ${CORE_PRODUCT_FIELDS}
`;

const GET_PRODUCT = gql`
  query Product($findProductInput: FindProductInput!) {
    product(findProductInput: $findProductInput) {
      ...CoreProductFields
    }
  }
  ${CORE_PRODUCT_FIELDS}
`;

export { GET_PRODUCTS, GET_PRODUCT };
