import { SignInInput } from "@/apollo/queries/auth/authInputTypes";
import { SIGN_IN } from "@/apollo/queries/auth/authQuery";
import { User } from "@/apollo/queries/user/types";
import routes from "@/router/routes";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
// import { User } from "@/apollo/queries/user/types";

type LoginArgs = {
  input: SignInInput;
};

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const login = async (args: LoginArgs) => {
  await new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  }).mutate({
    mutation: SIGN_IN,
    variables: {
      signInInput: args.input,
    },
  });
};

export type AuthState = {
  authed: boolean;
  signInPath: string;
};
export default async function auth(): Promise<AuthState> {
  const userCookie = Cookies.get("user");
  const signInPath = routes.signIn;
  const location = window.location;

  let user: User | null = null;
  let authed = false;
  let resolveData = { authed, signInPath };

  try {
    if (!userCookie) return resolveData;
    user = JSON.parse(userCookie);
  } catch (e) {
    console.error("Cookie not set");
  }

  try {
    if (!user) return resolveData;
    const authData = await fetch(
      (process.env.REACT_APP_BACKEND_URI + "/auth/authenticate") as string,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_token: user.access_token,
          role: user.role,
        }),
      }
    ).then((res) => res.json());

    if (authData?.statusCode >= 400) {
      throw Error("Unauthorized");
    }

    const isLoggedIn = authData?.authenticated || false;
    const isAdminSignInPath = location.pathname === signInPath;
    const setLoginMode = !isLoggedIn && !isAdminSignInPath;

    if (setLoginMode) {
      return resolveData;
    }
    authed = true;
  } catch (e) {
    Cookies.remove("user");
    authed = false;
  }

  resolveData = { authed, signInPath };
  return resolveData;
}

export function isPageAuthListed(pathname: string) {
  const pagesOffAuth = [
    routes.signIn,
    routes.signUp,
    routes.accountConfirmation,
    routes.requestPasswordReset,
    routes.resetPassword,
  ];
  return !pagesOffAuth.some((page) => page === pathname);
}
