import { FormNodeField, NodeType } from "@/apollo/queries/forms/formTypes";
import SingleSelectionList from "@/components/FormRenderer/Fields/SingleSelectionList/SingleSelectionList";
import { InputPropsCondtions } from "@/components/Lexical/nodes/DocumentFormInputNode/DocumentFormInputNode";
import Form from "@/components/utils/Form/form";
import FormContainer from "@/components/utils/Form/formContainer";
import { booleanFromString } from "@/utils/string";
import { Box, Button, Modal, SxProps, Theme, Typography } from "@mui/material";
import { useMemo } from "react";

type ConditionsConfigureModal = {
  formNodeConditions?: InputPropsCondtions[];
  fields: FormNodeField[];
  onClose: () => void;
  onSubmit: (formData: InputPropsCondtions) => void;
  open: boolean;
  selectedFieldId: string;
};

type Props = ConditionsConfigureModal;

export default function ConditionsConfigureModal({
  fields,
  onClose,
  onSubmit,
  open,
  selectedFieldId,
  formNodeConditions,
}: Props) {
  const style: SxProps<Theme> = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const selectedField = useMemo(
    () => fields.find((field) => field.id === selectedFieldId),
    [fields, selectedFieldId]
  );

  const fieldsWithoutSelectedField = useMemo(
    () => fields.filter((field) => field.id !== selectedFieldId),
    [fields, selectedFieldId]
  );

  const singleSelectionListFields: any[][] = useMemo(() => {
    let preloadedConditionsArray = formNodeConditions;
    // const fieldsWithoutSelectedField = [];
    const f: any[][] = [];
    fieldsWithoutSelectedField.forEach((field) => {
      let foundField;

      if (preloadedConditionsArray) {
        const foundFieldId = preloadedConditionsArray?.findIndex(
          (soughtField) => soughtField.fieldId == selectedFieldId
        );

        if (foundFieldId > -1) {
          foundField = preloadedConditionsArray[foundFieldId];
          preloadedConditionsArray = preloadedConditionsArray
            .slice(0, foundFieldId - 1)
            .concat(
              preloadedConditionsArray.slice(
                foundFieldId,
                preloadedConditionsArray.length
              )
            );
        }
      }

      const affectedField = foundField?.list.find(
        (condition) => condition.fieldId === field.id
      );

      const value = booleanFromString(affectedField?.mustBe);

      const preparedFields = [
        {
          ...field,
          id: field.id + "_mustBeChecked",
          label: field.label + " musi być zaznaczone ",
          value: value,
        },
        {
          ...field,
          id: field.id + "_mustNotBeChecked",
          label: field.label + " nie może być zaznaczone ",
          value: typeof value == "boolean" && !value,
        },
      ];

      f.push(preparedFields);
    });

    return f;
  }, [fieldsWithoutSelectedField, formNodeConditions, selectedFieldId]);

  if (!open) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Form
        onSubmit={(formData) => {
          const listConditions: any[] = [];
          Object.keys(formData).forEach((fieldKey) => {
            let mustBe;
            const splitFieldKey = fieldKey.split("_");
            const fieldType = splitFieldKey.slice(-1)[0];
            const fieldId = splitFieldKey.slice(0, -1).join("_");

            const field = formData[fieldKey];
            if (!!field) {
              if (fieldType === "mustBeChecked") {
                mustBe = true;
              } else {
                mustBe = false;
              }

              listConditions.push({
                fieldId,
                mustBe: String(mustBe),
              });
            }
          });

          onSubmit({ fieldId: selectedFieldId, list: listConditions });
        }}
      >
        <FormContainer>
          <Box sx={style}>
            <Typography variant="h6" component="h1">
              Warunki dla {selectedField?.label}
            </Typography>
            {singleSelectionListFields.map((fields, index) => (
              <SingleSelectionList
                id=""
                variableName=""
                type={NodeType.SingleSelectionList}
                fields={fields as FormNodeField[]}
                key={index}
              />
            ))}
            <Button type="submit" variant="outlined">
              Zapisz
            </Button>
          </Box>
        </FormContainer>
      </Form>
    </Modal>
  );
}
