import { gql } from "@apollo/client";
import { User } from "../user/types";

export type SignInMutation = {
  signIn: User;
};

const SIGN_IN = gql`
  mutation SignIn($signInInput: SignInInput!) {
    signIn(signInInput: $signInInput) {
      access_token
      id
      role
      name
      subscription {
        id
        product {
          packageRank
        }
      }
      companyType
      companyName
      nip
      regon
      krs
      street
      buildingNumber
      apartmentNumber
      zip
      city
      representatives
      name
      lastName
      shareCapital
      registrationCourt
      billing_companyName
      billing_nip
      billing_street
      billing_buildingNumber
      billing_apartmentNumber
      billing_zip
      billing_city
      createdAt
      updatedAt
    }
  }
`;

export { SIGN_IN };
