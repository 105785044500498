import { FindGeneratedDocumentInput } from "@/apollo/queries/generatedDocuments/generatedDocumentInputTypes";
import {
  GET_GENERATED_DOCUMENT_WITH_FORM,
  GetGeneratedDocumentWithForm,
} from "@/apollo/queries/generatedDocuments/generatedDocumentsQuery";
import FormRenderer from "@/components/FormRenderer/FormRenderer";
import Spinner from "@/components/Spinner";
import { PageName } from "@/utils/app";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

export default function ClientGeneratedDocumentFormPage() {
  const params = useParams<"id">();
  const { data, loading } = useQuery<GetGeneratedDocumentWithForm>(
    GET_GENERATED_DOCUMENT_WITH_FORM,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        findGeneratedDocumentInput: new FindGeneratedDocumentInput({
          id: params.id,
        }),
      },
    }
  );

  const submittedForm =
    data?.generatedDocumentWithForm?.generatedDocument?.submittedForm;
  const form = data?.generatedDocumentWithForm.form;

  if (!submittedForm || !form) return null;

  return (
    <Box>
      <Spinner loading={loading} data={data} />
      <PageName>Formularz</PageName>
      <FormRenderer
        generatedDocumentId={params.id}
        isClient
        preloadedForm={form}
      />
    </Box>
  );
}
