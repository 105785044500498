import {
  GET_USER_DETAILS,
  GetUserDetails,
} from "@/apollo/queries/user/userQuery";
import { useQuery } from "@apollo/client";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Link, useParams } from "react-router-dom";
import i18next from "@/i18n";
import { extractValueFromUserData } from "@/utils/user";
import routes from "@/router/routes";

export default function AdminClientPage() {
  const params = useParams<"id">();
  const id = params.id;
  const { data } = useQuery<GetUserDetails>(GET_USER_DETAILS, {
    fetchPolicy: "cache-and-network",
    variables: {
      getUserDetailsInput: {
        id: params.id,
      },
    },
    skip: !id,
  });

  if (!data?.userDetails) return null;

  const {
    access_token,
    subscription,
    role,
    id: userId,
    ...displayData
  } = data?.userDetails;

  return (
    <Card sx={{ minHeight: "4rem", position: "relative" }}>
      <CardHeader title={`Użytkownik ${displayData.email}`} />
      <Divider />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Grid container gap={1}>
          {Object.entries(displayData).map((userData) => {
            return (
              <Grid key={userData[0]} item xl={2} xs={6}>
                <Typography sx={{ color: grey[500] }} variant="h6">
                  {i18next.t(userData[0])}
                </Typography>
                <Typography>{extractValueFromUserData(userData[1])}</Typography>
              </Grid>
            );
          })}
        </Grid>
        <Link to={routes.admin.clients.id.transactions.make(userId)}>
          <Button variant="outlined">Transakcje użytkownika</Button>
        </Link>
      </CardContent>
    </Card>
  );
}
