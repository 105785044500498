import FormRenderer from "@/components/FormRenderer";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import Loading from "../loading";
import { Suspense } from "react";
import { useParams } from "react-router-dom";

export default function AdminFormPage() {
  const params = useParams<"id">();

  return (
    <Suspense fallback={<Loading />}>
      <Card sx={{ minHeight: "4rem", position: "relative" }}>
        <CardHeader title="Documents" />
        <Divider />
        <CardContent>
          <FormRenderer documentId={params.id} />
        </CardContent>
      </Card>
    </Suspense>
  );
}
