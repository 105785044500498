import { createTheme } from "@mui/material";
import { blue, green, grey, orange, red, yellow } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  status: {
    danger: orange[500],
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1300,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variant: "body1",
      },
    },
    MuiDivider: {
      defaultProps: {
        variant: "fullWidth",
      },
      styleOverrides: {
        fullWidth: {
          "::after": {
            display: "none",
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          boxShadow: "0 0 10px #00000008",
          borderRadius: "1rem",
          border: "1px solid #0000000d",
          overflow: "initial",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "1.5rem",
        },
        title: {
          fontSize: "1.125rem",
          fontWeight: 500,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "1.5rem",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: ".75rem",
          ":hover": {
            background: blue[50],
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: "1rem",
        },
      },
    },
  },
  typography: {
    allVariants: {
      color: "rgb(18, 25, 38)",
    },
    fontFamily: ["Roboto"].join(","),
    body1: {
      fontSize: "1rem",
    },
    h1: {
      lineHeight: "1.5rem",
      fontSize: "2rem",
      fontWeight: 500,
    },
    h2: {
      lineHeight: "1.5rem",
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    caption: {
      fontSize: ".9rem",
      color: grey[800],
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: ".9rem",
    },
  },
  mixins: {},
  palette: {
    primary: {
      main: blue[500],
      light: blue[50],
      dark: blue[900],
      contrastText: "white",
    },
    secondary: { main: blue[100] },
    error: {
      main: red[500],
    },
    warning: {
      main: yellow[700],
    },
    info: {
      main: blue[300],
    },
    success: {
      main: green[500],
    },
    background: {
      default: grey["50"],
    },
    common: {},
    text: {},
    action: {},
  },
});

export default theme;
