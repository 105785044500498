"use client";
import { FindGeneratedDocumentInput } from "@/apollo/queries/generatedDocuments/generatedDocumentInputTypes";
import {
  GET_GENERATED_DOCUMENT,
  GetGeneratedDocument,
} from "@/apollo/queries/generatedDocuments/generatedDocumentsQuery";
import DocumentReader from "@/components/DocumentReader/DocumentReader";
import Spinner from "@/components/Spinner";
import { PageName } from "@/utils/app";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

export default function ClientGeneratedDocumentPage() {
  const params = useParams<"id">();

  const { data, loading } = useQuery<GetGeneratedDocument>(
    GET_GENERATED_DOCUMENT,
    {
      variables: {
        findGeneratedDocumentInput: new FindGeneratedDocumentInput({
          id: params.id,
        }),
      },
      fetchPolicy: "network-only",
    }
  );

  if (!data) return <Spinner loading={loading} data={data} />;

  return (
    <Box>
      <PageName>{data.generatedDocument.displayName}</PageName>
      <DocumentReader
        data={data.generatedDocument}
        generatedDocumentId={params.id}
      />
    </Box>
  );
}
