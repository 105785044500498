import UserTransactionsList from "@/components/lists/users/UserTransactionsList";
import { PageName } from "@/utils/app";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

export default function TransactionsPage() {
  return (
    <Card sx={{ minHeight: "4rem", position: "relative" }}>
      <PageName>Transakcje</PageName>
      <CardHeader title="Documents" />
      <Divider />
      <CardContent>
        <UserTransactionsList />
      </CardContent>
    </Card>
  );
}
