import FormContainer from "@/components/utils/Form/formContainer";
import { FieldTypes, FormData } from "@/utils/form";
import { Button, TextField, debounce } from "@mui/material";
import { useEffect, useState } from "react";
import useDocumentCreator from "../../../hooks/useDocumentCreator";
import {
  FORM_NODE_FIELD_ID_LENGTH,
  FormNode,
  FormNodeField,
  NodeType,
} from "@/apollo/queries/forms/formTypes";
import ConditionsConfigureModal from "./ConditionsConfigureModal/conditionsConfigureModal";
import Form from "@/components/utils/Form/form";
import BaseFields from "../BaseFields/BaseFields";
import { InputPropsCondtions } from "@/components/Lexical/nodes/DocumentFormInputNode/DocumentFormInputNode";

type ConditionalMultipleSelectionListFormProps = {
  preloadedData?: FormNode;
};

export default function ConditionalMultipleSelectionListForm({
  preloadedData,
}: ConditionalMultipleSelectionListFormProps) {
  const { updateDocumentCreatorFormNodes, addDocumentCreatorFormNodes } =
    useDocumentCreator();
  const [formNodeConditions, setFormNodeConditions] = useState<
    InputPropsCondtions[]
  >([]);
  const [isConditionsModalToggled, toggleConditionsModal] = useState(false);
  const [selectedFieldId, selectFieldId] = useState<string>("");
  const [formNodeFields, setFormNodeFields] = useState<FormNodeField[]>([
    new FormNodeField({ label: "", value: "" }),
  ]);

  useEffect(() => {
    if (preloadedData?.fields) {
      setFormNodeFields(
        preloadedData.fields.map((field) => new FormNodeField(field))
      );

      if (preloadedData.inputProps?.conditions) {
        setFormNodeConditions(preloadedData.inputProps?.conditions);
      }
    }
  }, [preloadedData]);

  const addFormNodeField = () => {
    setFormNodeFields(
      formNodeFields.concat(new FormNodeField({ label: "", value: "" }))
    );
  };

  const handleOptionLabelChange = debounce((event, formNodeIndex) => {
    const value = event.target.value;
    const newFormNodeFields = [...formNodeFields];
    newFormNodeFields[formNodeIndex] = new FormNodeField({
      ...newFormNodeFields[formNodeIndex],
      label: value,
    });

    setFormNodeFields(newFormNodeFields);
  }, 200);

  const handleOptionValueChange = debounce((event, formNodeIndex) => {
    const value = event.target.value;
    const newFormNodeFields = [...formNodeFields];
    newFormNodeFields[formNodeIndex] = new FormNodeField({
      ...newFormNodeFields[formNodeIndex],
      value,
    });

    setFormNodeFields(newFormNodeFields);
  }, 200);

  const onModalOpen = (id: string) => {
    toggleConditionsModal(true);
    selectFieldId(id);
  };

  const parseData = (data: FormData): FormNode => {
    const fields: FormNodeField[] = [];
    const formNodeData = new FormNode(data as unknown as FormNode);

    Object.keys(data).map((key) => {
      if (key.includes("grouped_")) {
        const groupedStringLength = FieldTypes.GROUPED.length;
        const keyLength = key.length;

        const fieldId = key.slice(
          groupedStringLength,
          groupedStringLength + FORM_NODE_FIELD_ID_LENGTH
        );

        const type = key.slice(
          groupedStringLength + FORM_NODE_FIELD_ID_LENGTH + 1,
          keyLength
        );

        const foundIndex = fields.findIndex((option) => option.id === fieldId);

        const createdOption = {
          [type]: data[key],
          id: fieldId,
        };

        if (foundIndex < 0) {
          fields.push(createdOption as unknown as FormNodeField);
        } else {
          fields[foundIndex] = {
            ...fields[foundIndex],
            ...createdOption,
          };
        }

        delete data[key];
      }
    });

    const finalData: FormNode = {
      ...formNodeData,
      fields,
      id: preloadedData?.id || "",
      inputProps: {
        conditions: formNodeConditions,
      },
    };

    return new FormNode(finalData);
  };

  return (
    <>
      <Form
        onSubmit={(formData) => {
          if (formData) {
            const parsedData = parseData(formData);

            if (preloadedData?.id) {
              updateDocumentCreatorFormNodes(parsedData);
            } else {
              addDocumentCreatorFormNodes(parsedData);
            }
          }
        }}
      >
        <FormContainer>
          <BaseFields
            preloadedData={preloadedData}
            nodeType={NodeType.ConditionalMultipleSelectionList}
          />
          {formNodeFields.map((formNodeField, formNodeFieldIndex) => (
            <FormContainer
              sx={{
                width: "100%",
              }}
              key={formNodeField.id}
            >
              <TextField
                sx={{ width: "31%" }}
                label="Opcja"
                id={FieldTypes.GROUPED + formNodeField.id + "_label"}
                onChange={(event) =>
                  handleOptionLabelChange(event, formNodeFieldIndex)
                }
                defaultValue={formNodeField.label}
              />
              <TextField
                sx={{ width: "31%" }}
                label="Wartość"
                id={FieldTypes.GROUPED + formNodeField.id + "_value"}
                multiline
                onChange={(event) =>
                  handleOptionValueChange(event, formNodeFieldIndex)
                }
                defaultValue={formNodeField.value}
              />
              <Button
                disabled={
                  formNodeFields.length < 2 ||
                  formNodeField?.value?.length < 1 ||
                  formNodeField?.label?.length < 1 ||
                  formNodeFields.some((field) => !field.label || !field.value)
                }
                sx={{ width: "24%", maxHeight: "4rem" }}
                variant="contained"
                onClick={() => onModalOpen(formNodeField.id)}
              >
                Skonfiguruj warunki
              </Button>
            </FormContainer>
          ))}
          <Button
            onClick={addFormNodeField}
            variant="outlined"
            sx={{ width: "100%" }}
          >
            Dodaj kolejne
          </Button>
          <Button type="submit">Submit</Button>
        </FormContainer>
      </Form>
      <ConditionsConfigureModal
        open={isConditionsModalToggled}
        onClose={() => toggleConditionsModal(false)}
        fields={formNodeFields}
        formNodeConditions={formNodeConditions}
        selectedFieldId={selectedFieldId}
        onSubmit={(formData) => {
          const editedFormNodeId = formData.fieldId;
          const existingFormNodeConditionIndex = formNodeConditions.findIndex(
            (formNodeCondtion) => formNodeCondtion.fieldId === editedFormNodeId
          );

          if (existingFormNodeConditionIndex >= 0) {
            const updatedFormCondtion = formNodeConditions
              .slice(0, existingFormNodeConditionIndex)
              .concat(formData)
              .concat(
                formNodeConditions.slice(
                  existingFormNodeConditionIndex + 1,
                  formNodeConditions.length
                )
              );
            setFormNodeConditions(updatedFormCondtion);
          } else {
            setFormNodeConditions(formNodeConditions?.concat(formData));
          }
        }}
      />
    </>
  );
}
