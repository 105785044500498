import FormContainer from "@/components/utils/Form/formContainer";
import { FormData } from "@/utils/form";
import { Button, TextField } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import useDocumentCreator from "../../../hooks/useDocumentCreator";
import {
  FormNode,
  FormNodeField,
  NodeType,
} from "@/apollo/queries/forms/formTypes";
import Form from "@/components/utils/Form";
import { FormState } from "@/components/utils/Form/form";
import BaseFields from "../BaseFields/BaseFields";

type DateInputFormProps = {
  preloadedData?: FormNode;
};

export default function DateInputForm({ preloadedData }: DateInputFormProps) {
  const { updateDocumentCreatorFormNodes, addDocumentCreatorFormNodes } =
    useDocumentCreator();

  const [formNodeField, setFormNodeField] = useState<FormNodeField>(
    new FormNodeField({ label: "", value: "" })
  );

  useEffect(() => {
    if (preloadedData?.fields) {
      setFormNodeField(preloadedData.fields[0]);
    }
  }, [preloadedData]);

  const handleOnFormChange = useCallback(
    (formState: FormState) => {
      setFormNodeField(new FormNodeField({ ...formNodeField, ...formState }));
    },
    [formNodeField]
  );

  const parseData = (data: FormData): FormNode => {
    const formNodeData = new FormNode(data as unknown as FormNode);
    const format = data.format as string;
    const finalData: FormNode = {
      ...formNodeData,
      fields: [formNodeField],
      id: preloadedData?.id || "",
      inputProps: {
        date: {
          format,
        },
      },
    };

    return new FormNode(finalData);
  };

  return (
    <Form
      onSubmit={(formData) => {
        const parsedData = parseData(formData);
        if (preloadedData?.id) {
          updateDocumentCreatorFormNodes(parsedData);
        } else {
          addDocumentCreatorFormNodes(parsedData);
        }
      }}
      onChange={handleOnFormChange}
    >
      <FormContainer>
        <BaseFields
          preloadedData={preloadedData}
          nodeType={NodeType.DateInput}
        />
        <TextField
          id="format"
          placeholder={"Np. DD/MM/YYYY lub DD/MM/YYYY HH:mm:ss"}
          label={"Format daty"}
          multiline
          sx={{
            width: "100%",
          }}
          defaultValue={preloadedData?.inputProps?.date?.format || "DD/MM/YYYY"}
        />
        <Button type="submit">Submit</Button>
      </FormContainer>
    </Form>
  );
}
