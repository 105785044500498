export class CreatePagination {
  perPage: number;
  page: number;

  constructor(data: CreatePagination) {
    this.perPage = data.perPage;
    this.page = data.page;
  }
}

export class PaginationResult {
  count: number;
  pages: number;

  constructor(data: PaginationResult) {
    this.count = data.count;
    this.pages = data.pages;
  }
}
