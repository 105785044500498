import {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import auth, { AuthState, isPageAuthListed } from "@/utils/auth";
import { useLocation } from "react-router-dom";
import { UserContext } from "@/context/User/userContext";
import { UserReducerPayloadTypes } from "@/context/User/userReducer";

export default function AuthWrapper({ children }: PropsWithChildren) {
  const [authState, setAuthState] = useState<AuthState>();
  const location = useLocation();
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const shouldPageBeAuthed = useMemo(
    () => isPageAuthListed(location.pathname),
    [location.pathname]
  );

  useEffect(() => {
    (async function () {
      const authData = await auth();

      setAuthState(authData);

      if (!authData.authed) {
        userDispatch({
          type: UserReducerPayloadTypes.CLEAR_USER,
          payload: null,
        });

        if (shouldPageBeAuthed) {
          window.location.href = `${authData.signInPath}?goto=${window.location.pathname}`;
        }
      }
    })();
  }, [
    authState?.signInPath,
    location.pathname,
    shouldPageBeAuthed,
    userDispatch,
  ]);

  if (shouldPageBeAuthed && !userState?.access_token) return null;

  return <>{children}</>;
}
