type NIPError_Causes = "missing" | "invalid";

export class NIPError extends Error {
  cause: string;
  path: string;
  constructor(message: string, cause: NIPError_Causes, path: string) {
    super(message);

    Object.setPrototypeOf(this, NIPError.prototype);
    this.cause = cause;
    this.path = path;
  }
}

export default function validateNIP(nip: string, fieldName = "") {
  if (typeof nip !== "string")
    throw new NIPError(
      "NIP must be a string, received: " + typeof nip,
      "missing",
      fieldName
    );

  nip = nip.replace(/[-]/gi, "");

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  if (sum % 11 !== controlNumber) {
    throw new NIPError("Invalid NIP!", "invalid", fieldName);
  }

  return sum % 11 === controlNumber;
}
