import { CreatePagination, PaginationResult } from "@/apollo/paginationTypes";
import { Pagination, TextField, debounce } from "@mui/material";
import { ChangeEvent, useCallback, useState } from "react";

export function SearchComponent({
  onChange,
  placeholder,
}: {
  onChange?: (result: string) => void;
  placeholder: string;
}) {
  const [search, setSearch] = useState("");

  const onSearch = debounce(
    useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        if (onChange) {
          onChange(event.target.value);
        }
      },
      [onChange]
    ),
    500
  );

  return (
    <TextField id="search" placeholder={placeholder} onChange={onSearch} />
  );
}

export function usePagination() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState(1);

  const updatePaginationData = useCallback(
    (paginationResult: PaginationResult) => {
      setPages(paginationResult.pages);
    },
    []
  );

  return {
    updatePaginationData,
    paginationData: new CreatePagination({ page: currentPage, perPage }),
    PaginationComponent: () => <Pagination count={pages} />,
  };
}
