import { Box, Grid, TextField, useTheme } from "@mui/material";
import LexicalEditor from "@/components/Lexical";
import InputModal from "./InputModal";
import useDocumentCreator from "./hooks/useDocumentCreator";
import { LegalDocument } from "@/apollo/queries/documents/types";
import DocumentCreatorSidebar from "./Sidebar/Sidebar";
import useWindow from "@/hooks/useWindow";
import Settings from "./Settings/Settings";

type DocumentCreatorProps = {
  preloadedData?: LegalDocument;
};

export default function DocumentCreator({
  preloadedData,
}: DocumentCreatorProps) {
  const { setDocumentNameDebounce, setDocumentDescriptionDebounce } =
    useDocumentCreator({
      preloadedData,
    });
  const theme = useTheme();
  const window = useWindow();

  return (
    <Box>
      <Grid container spacing={2}>
        {window.check((x) => x >= theme.breakpoints.values["xl"]) && (
          <Grid item xl={2}>
            <DocumentCreatorSidebar />
          </Grid>
        )}
        <Grid item xl={10} sx={{ overflowX: "hidden", width: "100%" }}>
          <TextField
            sx={{
              width: "100%",
              background: "white",
            }}
            variant="outlined"
            id="documentName"
            label="Nazwa dokumentu"
            defaultValue={preloadedData?.name || ""}
            onChange={(event) => setDocumentNameDebounce(event.target.value)}
          />
          <TextField
            multiline
            sx={{
              width: "100%",
              background: "white",
              marginTop: "1rem",
            }}
            variant="outlined"
            id="description"
            label="Opis dokumentu"
            defaultValue={preloadedData?.description || ""}
            onChange={(event) =>
              setDocumentDescriptionDebounce(event.target.value)
            }
          />
          <Settings preloadedData={preloadedData} />
          <LexicalEditor />
        </Grid>
      </Grid>
      <InputModal />
    </Box>
  );
}
