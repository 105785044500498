import { DocumentCreatorProvider } from "@/components/admin/DocumentCreator/context";
import { LexicalEditorProvider } from "@/context/LexicalEditor/context";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

import { Suspense } from "react";
import Loading from "../loading";

import DocumentCreator from "@/components/admin/DocumentCreator/DocumentCreator";

export default function AdminCreatorPage() {
  return (
    <Suspense fallback={<Loading />}>
      <LexicalEditorProvider>
        <DocumentCreatorProvider>
          <Card>
            <CardHeader title="Document creator" />
            <Divider />
            <CardContent>
              <DocumentCreator />
            </CardContent>
          </Card>
        </DocumentCreatorProvider>
      </LexicalEditorProvider>
    </Suspense>
  );
}
