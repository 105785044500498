// import { i18n } from "next-i18next";
import { ApolloError } from "@apollo/client";
import { ValidationError } from "yup";
import { NIPError } from "./validateNIP";
import i18next from "@/i18n";

export enum FieldTypes {
  GROUPED = "grouped_",
}

// type FormInput = {
//   id: string;
//   value: any;
//   type: "checkbox" | "text";
// };

type FormInput = HTMLInputElement;

type FormError = {
  id: string;
  message: string;
};

export type FormErrors = Map<FormError["id"], FormError["message"]>;

export type FormData = {
  [key: string]: any;
};

export const getFormData = (formRef: HTMLFormElement | null): FormData => {
  const data: FormData = {};

  if (!formRef) return data;

  const inputs = Object.values(formRef) as FormInput[];

  inputs.forEach((input) => {
    const key = input.id;
    let value: string | boolean = input.value;

    if (input.hasOwnProperty("checked")) {
      value = input.checked;
    }

    if (!key) return;

    data[key] = value;
  });

  return data;
};

export const getFormErrorsFromValidation = (
  unknownError: unknown
): FormErrors => {
  const errorsMap = new Map();
  if (unknownError instanceof ValidationError) {
    const error = unknownError;

    const errorsData = error.inner;
    if (!errorsData) return errorsMap;

    errorsData.forEach((vEr) => {
      const path = vEr.path;
      errorsMap.set(path, i18next.t("errorValidationType." + vEr.type));
    });
  }

  if (unknownError instanceof NIPError) {
    errorsMap.set(unknownError.path, unknownError.message);
  }
  console.log(errorsMap);
  return errorsMap;
};

type OriginalError = {
  error: string;
  message: string[];
  statusCode: number;
};

export const getFormErrorsFromResponse = (error?: ApolloError): FormErrors => {
  const errorsMap = new Map();
  const errorsData = error?.graphQLErrors[0].extensions
    .originalError as OriginalError;

  if (!errorsData || !errorsData.message || !errorsData?.message?.forEach)
    return errorsMap;

  errorsData.message?.forEach((errorMessage: any) => {
    const field = errorMessage.split(" ")[0];
    errorsMap.set(field, errorMessage);
  });

  return errorsMap;
};
