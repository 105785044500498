import { FormConfig } from "../forms/formTypes";

export class CreateSubscriptionInput {
  productSlug?: string;
  billing_companyName: string;
  billing_nip: string;
  billing_street: string;
  billing_buildingNumber: string;
  billing_apartmentNumber: string;
  billing_zip: string;
  billing_city: string;

  constructor({
    productSlug,
    billing_companyName,
    billing_nip,
    billing_street,
    billing_buildingNumber,
    billing_apartmentNumber,
    billing_zip,
    billing_city,
  }: CreateSubscriptionInput) {
    this.productSlug = productSlug;
    this.billing_companyName = billing_companyName;
    this.billing_nip = billing_nip;
    this.billing_street = billing_street;
    this.billing_buildingNumber = billing_buildingNumber;
    this.billing_apartmentNumber = billing_apartmentNumber;
    this.billing_zip = billing_zip;
    this.billing_city = billing_city;
  }
}

export class FindSubscriptionInput {
  id: string;

  constructor({ id }: FindSubscriptionInput) {
    this.id = id;
  }
}
