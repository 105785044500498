import { FormNode, FormNodeField } from "@/apollo/queries/forms/formTypes";
import { booleanFromString } from "@/utils/string";
import { useCallback, useEffect, useState } from "react";

type RenderedFormNodeField = {
  disabled?: boolean;
} & FormNodeField;

export default function useConditionalMultipleSelectionList({
  fields,
  inputProps,
  hideValidation,
}: FormNode) {
  const [editableFields, setEditableFieds] = useState<RenderedFormNodeField[]>(
    []
  );
  const [checkedBoxes, setCheckedBoxes] = useState<{ [key: string]: boolean }>(
    {}
  );

  const validateField = useCallback(
    (field: FormNodeField, checkedBoxes: any) => {
      const fieldConditions = inputProps?.conditions?.find(
        (condition) => condition.fieldId === field.id
      );

      if (!fieldConditions || !fieldConditions?.list?.length) return;

      let i = 0;
      const fieldsToBeDisabled = [];
      while (i >= 0 && i < fieldConditions.list.length) {
        const fieldId = fieldConditions.list[i].fieldId;
        const expectedCheckboxValue = booleanFromString(
          fieldConditions.list[i].mustBe
        );
        if (checkedBoxes[fieldId] !== expectedCheckboxValue) {
          fieldsToBeDisabled.push(field.id);
          checkedBoxes[field.id] = false;
        }
        i++;
      }

      return [checkedBoxes, fieldsToBeDisabled];
    },
    [inputProps?.conditions]
  );

  const disableValidatedFields = useCallback(
    (fields: FormNodeField[] & RenderedFormNodeField[], checkedBoxes: any) => {
      let fieldsToBeDisabled: string[][] = [];
      let flattenFieldsToBeDisabled = [];
      let updatedCheckedBoxes = checkedBoxes;

      const updatedFields: FormNodeField[] = [];

      fields.forEach((field) => {
        if (hideValidation) return;
        const fieldToBeDisabled = validateField(field, updatedCheckedBoxes);
        if (fieldToBeDisabled && fieldToBeDisabled.length) {
          updatedCheckedBoxes = fieldToBeDisabled[0];
          fieldsToBeDisabled.push(fieldToBeDisabled[1]);
        }

        flattenFieldsToBeDisabled = Array.from(
          new Set(fieldsToBeDisabled.flat())
        );

        field = { ...field };
        const foundField = flattenFieldsToBeDisabled.find(
          (fieldId) => field.id === fieldId
        );
        if (foundField) {
          (field as any).disabled = true;
        } else {
          (field as any).disabled = false;
        }

        return updatedFields.push(field);
      });

      hideValidation
        ? setEditableFieds(fields)
        : setEditableFieds([...updatedFields]);

      return updatedCheckedBoxes;
    },
    [hideValidation, validateField]
  );

  useEffect(() => {
    const initialCheckedBoxes: { [key: string]: any } = {};
    if (!fields) return;

    fields.forEach((field) => {
      const value = !!booleanFromString(field.value);
      initialCheckedBoxes[field.id] = value;
    });

    disableValidatedFields(fields, initialCheckedBoxes);

    setCheckedBoxes(initialCheckedBoxes);
  }, [disableValidatedFields, fields, validateField]);

  return {
    validateField,
    disableValidatedFields,
    editableFields,
    checkedBoxes,
    setCheckedBoxes,
  };
}
