import { gql } from "@apollo/client";
import { LegalDocument } from "../documents/types";
import { GeneratedDocument } from "./types";
import { DocumentForm } from "../forms/formTypes";

const GET_GENERATED_DOCUMENTS = gql`
  query GeneratedDocuments(
    $findGeneratedDocumentsInput: FindGeneratedDocumentsInput!
  ) {
    generatedDocuments(
      findGeneratedDocumentsInput: $findGeneratedDocumentsInput
    ) {
      id
      createdAt
      updatedAt
      displayName
      requiresManualRegeneration
      legalDocument {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_GENERATED_DOCUMENT = gql`
  query GeneratedDocument(
    $findGeneratedDocumentInput: FindGeneratedDocumentInput!
  ) {
    generatedDocument(findGeneratedDocumentInput: $findGeneratedDocumentInput) {
      id
      json
      createdAt
      updatedAt
      displayName
      legalDocument {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;

const GET_GENERATED_DOCUMENT_WITH_FORM = gql`
  query GeneratedDocumentWithForm(
    $findGeneratedDocumentInput: FindGeneratedDocumentInput!
  ) {
    generatedDocumentWithForm(
      findGeneratedDocumentInput: $findGeneratedDocumentInput
    ) {
      form {
        id
        createdAt
        updatedAt
        formNodes {
          question
          type
          id
          inputProps {
            date {
              format
            }
            conditions {
              fieldId
              list {
                fieldId
                mustBe
              }
            }
            input {
              extendsDocumentName {
                format
                enabled
              }
            }
          }
          fields {
            id
            label
            value
          }
        }
      }
      generatedDocument {
        displayName
        submittedForm
        id
        json
        createdAt
        updatedAt
        legalDocument {
          id
          createdAt
          updatedAt
          name
        }
      }
    }
  }
`;

const EXPORT_GENERATED_DOCUMENT = gql`
  mutation ExportGeneratedDocument(
    $exportGeneratedDocumentInput: ExportGeneratedDocumentInput!
  ) {
    exportGeneratedDocument(
      exportGeneratedDocumentInput: $exportGeneratedDocumentInput
    ) {
      type
      file
    }
  }
`;

export {
  GET_GENERATED_DOCUMENT,
  GET_GENERATED_DOCUMENTS,
  GET_GENERATED_DOCUMENT_WITH_FORM,
  EXPORT_GENERATED_DOCUMENT,
};

export type GetGeneratedDocument = { generatedDocument: GeneratedDocument };
export type GetGeneratedDocumentWithForm = {
  generatedDocumentWithForm: {
    generatedDocument: LegalDocument;
    form: DocumentForm;
  };
};
export type ExportGeneratedDocument = {
  exportGeneratedDocument: {
    file: string;
  };
};
export type GetGeneratedDocuments = { generatedDocuments: GeneratedDocument[] };
