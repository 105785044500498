import "./App.css";

import AppRouter from "./router/AppRouter";
import { AppProviders } from "./providers/AppProviders";
import { ApolloWrapper } from "./apollo/ApolloWrapper";
import { Helmet } from "react-helmet";
import { getPageName } from "./utils/app";

function App() {
  return (
    <>
      <Helmet>
        <title>{getPageName()}</title>
      </Helmet>
      <ApolloWrapper>
        <AppProviders>
          <AppRouter />
        </AppProviders>
      </ApolloWrapper>
    </>
  );
}

export default App;
