import { FormNode, FormNodeField } from "@/apollo/queries/forms/formTypes";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import useConditionalMultipleSelectionList from "./useConditionalMultipleSelectionList";

export default function ConditionalMultipleSelectionList({
  fields,
  inputProps,
  hideValidation,
  ...restProps
}: FormNode) {
  const {
    checkedBoxes,
    setCheckedBoxes,
    editableFields,
    disableValidatedFields,
  } = useConditionalMultipleSelectionList({
    fields,
    inputProps,
    hideValidation,
    ...restProps,
  });

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, field: FormNodeField) => {
      const id = event.target.id;
      const updatedCheckedboxes = { ...checkedBoxes };

      let selectedCheckbox = updatedCheckedboxes[id];
      if (!selectedCheckbox) {
        selectedCheckbox = true;
      } else {
        selectedCheckbox = !selectedCheckbox;
      }
      updatedCheckedboxes[id] = selectedCheckbox;

      const disabledFieldsIds = disableValidatedFields(
        editableFields,
        updatedCheckedboxes
      );

      setCheckedBoxes(disabledFieldsIds);
    },
    [checkedBoxes, disableValidatedFields, editableFields, setCheckedBoxes]
  );

  return (
    <div>
      {editableFields.map((field) => (
        <FormControlLabel
          key={field.id}
          label={field.label}
          control={
            <Checkbox
              disabled={field.disabled}
              checked={!!checkedBoxes[field.id]}
              onChange={(event) => handleOnChange(event, field)}
              id={field.id}
              inputProps={{ "aria-label": field.label }}
            />
          }
        />
      ))}
    </div>
  );
}
