import React, {
  createContext,
  useReducer,
  Dispatch,
  PropsWithChildren,
  useEffect,
} from "react";
import {
  UserReducerActions,
  UserReducerPayloadTypes,
  userReducer,
} from "./userReducer";
import Cookies from "js-cookie";
import {
  GET_USER_DETAILS,
  GetUserDetails,
} from "@/apollo/queries/user/userQuery";
import { useQuery } from "@apollo/client";
import { User } from "@/apollo/queries/user/types";
import { isPageAuthListed } from "@/utils/auth";

export type UserContextInitialState = User | null;
const initialState: UserContextInitialState = null;

const UserContext = createContext<{
  state: UserContextInitialState;
  dispatch: Dispatch<UserReducerActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const UserContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const { data, loading } = useQuery<GetUserDetails>(GET_USER_DETAILS, {
    fetchPolicy: "cache-and-network",
    skip: !isPageAuthListed(window.location.pathname),
  });

  useEffect(() => {
    const userCookie = Cookies.get("user");
    Cookies.set("sessionId", (Math.random() * 10 ** 16).toString(16));

    if (userCookie) {
      const user: User = JSON.parse(userCookie);
      dispatch({
        type: UserReducerPayloadTypes.SET_USER,
        payload: { ...user, ...data?.userDetails },
      });
    }
  }, [data]);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContextProvider, UserContext };
