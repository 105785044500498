import { FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormNode } from "@/apollo/queries/forms/formTypes";
import { FormErrors } from "@/utils/form";

type DateInputProps = {
  errors?: FormErrors;
} & FormNode;

export default function DateInput({
  fields,
  errors,
  inputProps,
}: DateInputProps) {
  if (!fields) return null;
  const { id, label, value } = fields[0];

  return (
    <FormControl id={id}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format={
            inputProps?.date?.format.length
              ? inputProps?.date?.format
              : "DD/MM/YYYY"
          }
          label={label}
          // defaultValue={dayjs(value, "DD/MM/YYYY")}
          slotProps={{
            textField: {
              id: id,
              error: !!errors?.get(id),
              helperText: errors?.get(id),
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
