import { FormNode } from "@/apollo/queries/forms/formTypes";
import { booleanFromString } from "@/utils/string";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ChangeEvent, useEffect, useState } from "react";

export default function SingleSelectionList({ fields }: FormNode) {
  const [prevCheckedBoxId, setPrevCheckedBoxId] = useState("");
  const [checkedBoxes, setCheckedBoxes] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    const initialCheckedBoxes: { [key: string]: any } = {};
    if (!fields) return;

    fields.forEach((field) => {
      const value = booleanFromString(field.value);
      initialCheckedBoxes[field.id] = value;
      if (value) {
        setPrevCheckedBoxId(field.id);
      }
    });
    setCheckedBoxes(initialCheckedBoxes);
  }, [fields]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id;

    const updatedCheckedboxes = { ...checkedBoxes };
    updatedCheckedboxes[id] = true;

    if (prevCheckedBoxId) {
      if (prevCheckedBoxId === id && !checkedBoxes[id]) {
      } else {
        updatedCheckedboxes[prevCheckedBoxId] = false;
      }
    }

    setCheckedBoxes(updatedCheckedboxes);
    setPrevCheckedBoxId(id);
  };

  if (!fields) return null;

  return (
    <div>
      {fields.map(({ label, id }) => (
        <FormControlLabel
          key={id}
          label={label}
          control={
            <Checkbox
              value={!!checkedBoxes[id]}
              checked={!!checkedBoxes[id]}
              onChange={handleOnChange}
              id={id}
              inputProps={{ "aria-label": label }}
            />
          }
        />
      ))}
    </div>
  );
}
