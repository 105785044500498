import { LexicalEditor } from "lexical";
import { LexicalEditorInitialState } from "./context";
import { ActionMap } from "@/types/context";

export enum LexicalEditorPayloadTypes {
  SET_EDITOR,
}

type LexicalEditorPayload = {
  [LexicalEditorPayloadTypes.SET_EDITOR]: {
    editor: LexicalEditor;
  };
};

export type LexicalEditorActions =
  ActionMap<LexicalEditorPayload>[keyof ActionMap<LexicalEditorPayload>];

export const lexicalEditorReducer = (
  state: LexicalEditorInitialState,
  action: LexicalEditorActions
): LexicalEditorInitialState => {
  switch (action.type) {
    case LexicalEditorPayloadTypes.SET_EDITOR:
      return {
        ...state,
        editor: action.payload.editor,
      };
    default:
      return state;
  }
};
