import { DocumentFormInputNodeProps_InputProps } from "@/components/Lexical/nodes/DocumentFormInputNode/DocumentFormInputNode";
import { IConditions } from "@/utils/conditional/types";
import { nanoid } from "nanoid";

export const FORM_NODE_FIELD_ID_LENGTH = 8;
export const FORM_NODE_ID_LENGTH = 8;

export enum NodeType {
  Input = "Input",
  CompanyInsert = "CompanyInsert",
  DateInsert = "DateInsert",
  DateInput = "DateInput",
  MultipleSelectionList = "MultipleSelectionList",
  ConditionalMultipleSelectionList = "ConditionalMultipleSelectionList",
  SingleSelectionList = "SingleSelectionList",
}

export class FormVariables {
  name: string;
  value: string;

  constructor(data: FormVariables) {
    this.name = data.name;
    this.value = data.value;
  }
}

export class FormConfig {
  id?: string;
  formNodes: FormNode[];
  variables?: FormVariables[];

  constructor(data: FormConfig) {
    this.id = data?.id || undefined;
    this.formNodes = data?.formNodes || [];
    this.variables = data?.variables;
  }
}

interface FormNodeFieldOptionsConditions extends IConditions {
  fieldId: string;
}

type FormNodeFieldOptions = {
  type: string;
  conditions: FormNodeFieldOptionsConditions[];
};

type FormNodeFieldInput = {
  label: string;
  value: string;
  id?: string;
};

export class FormNodeField {
  label: string;
  value: string;
  id: string;

  constructor(data: FormNodeFieldInput) {
    this.label = data.label;
    this.value = data.value;

    this.id = data?.id || nanoid(FORM_NODE_FIELD_ID_LENGTH);
  }
}

export class FormNode {
  [key: string]: string | FormNodeField[] | any;
  id: string;
  variableName: string;
  question?: string;
  type: NodeType;
  fields?: FormNodeField[];
  inputProps?: DocumentFormInputNodeProps_InputProps;

  constructor({
    type,
    fields,
    question,
    id,
    variableName,
    inputProps,
  }: FormNode) {
    this.type = type;
    this.fields = fields?.map((field) => new FormNodeField(field));
    this.question = question;
    this.id = id || nanoid(FORM_NODE_ID_LENGTH);
    this.variableName = variableName;
    this.inputProps = inputProps
      ? new DocumentFormInputNodeProps_InputProps(inputProps)
      : undefined;
  }
}

export class DocumentForm {
  id?: string;
  formNodes: FormNode[];

  constructor(data?: DocumentForm) {
    this.id = data?.id || undefined;
    this.formNodes = data?.formNodes || [];
  }
}
