import UserGeneratedDocumentsList from "@/components/lists/users/UserGeneratedDocumentsList";
import { PageName } from "@/utils/app";
import { Box, Card, CardContent, CardHeader, Divider } from "@mui/material";
import { useParams } from "react-router-dom";

export default function ClientGeneratedDocumentsPage() {
  const params = useParams<"id">();

  if (!params.id) return null;

  return (
    <Card sx={{ minHeight: "4rem", position: "relative" }}>
      <PageName>Wygenerowane dokumenty</PageName>
      <CardHeader title={`Wygenerowane dokumenty`} />
      <Divider />
      <CardContent>
        <Box sx={{ position: "relative" }}>
          <UserGeneratedDocumentsList documentId={params.id} />
        </Box>
      </CardContent>
    </Card>
  );
}
