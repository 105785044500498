import { Box, Modal, SxProps, Theme } from "@mui/material";
import { PropsWithChildren, useContext } from "react";
import { DocumentCreatorContext } from "./context";
import { DocumentCreatorPayloadTypes } from "./context/actions";

type Props = PropsWithChildren;

export default function InputModal({ children }: Props) {
  const { state: documentCreatorState, dispatch: documentCreatorDispatch } =
    useContext(DocumentCreatorContext);

  const handleClose = () => {
    documentCreatorDispatch({
      type: DocumentCreatorPayloadTypes.TOGGLE_INPUT_MODAL,
      payload: {
        toggleInputModal: false,
      },
    });
  };

  const style: SxProps<Theme> = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={documentCreatorState.isInputModalToggled}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{documentCreatorState.inputModalChildren}</Box>
    </Modal>
  );
}
