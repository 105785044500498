const adminEntry = "/admin";
const clientEntry = "/klient";
// with admin entry
const wae = (path: string) => adminEntry + path;

// with client entry
const wce = (path: string) => clientEntry + path;

const routes = {
  admin: {
    0: wae("/"),
    documents: {
      0: wae("/dokumenty"),
      id: {
        0: wae("/dokumenty/:id"),
        make: (id: string) => wae(`/dokumenty/${id}`),
      },
      creator: wae("/dokumenty/kreator"),
    },
    clients: {
      0: wae("/klienci"),
      id: {
        0: wae("/klienci/:id"),
        make: (id: string) => wae(`/klienci/${id}`),
        transactions: {
          id: wae("/klienci/:id/transakcje"),
          make: (id: string) => wae(`/klienci/${id}/transakcje`),
        },
      },
    },
    forms: {
      0: wae("/formularze"),
      id: {
        0: wae("/formularze/:id"),
        make: (id: string) => wae(`/formularze/${id}`),
      },
    },
    generatedDocuments: {
      0: wae("/wygenerowane-dokumenty"),
      id: {
        0: wae("/wygenerowane-dokumenty/:id"),
        make: (id: string) => wae(`/wygenerowane-dokumenty/${id}`),
        form: wae("/wygenerowane-dokumenty/formularz/:id"),
      },
    },
  },
  client: {
    0: wce("/"),
    documents: {
      0: wce("/przegladaj"),
      id: {
        0: wce("/przegladaj/:id"),
        make: (id: string) => wce(`/przegladaj/${id}`),
      },
    },
    forms: {
      0: wce("/formularze"),
      id: {
        0: wce("/formularze/:id"),
        make: (id: string) => wce(`/formularze/${id}`),
      },
    },
    generatedDocuments: {
      0: wce("/wygenerowane-dokumenty"),
      id: {
        0: wce("/wygenerowane-dokumenty/:id"),
        make: (id: string) => wce(`/wygenerowane-dokumenty/${id}`),
        form: {
          0: wce("/wygenerowane-dokumenty/:id/formularz"),
          make: (id: string) => wce(`/wygenerowane-dokumenty/${id}/formularz`),
        },
      },
    },
    packages: {
      0: wce("/pakiety"),
      slug: {
        0: wce("/pakiety/:slug"),
        make: (slug: string) => wce(`/pakiety/${slug}`),
      },
    },
    profile: wce("/profil"),
    transactions: {
      0: wce("/transakcje"),
      id: {
        0: wce("/transakcje/:id"),
        make: (id: string) => wce(`/transakcje/${id}`),
      },
    },
  },
  signIn: "/logowanie",
  signUp: "/rejestracja",
  accountConfirmation: "/potwierdzenie-konta",
  resetPassword: "/resetowanie-hasla",
  requestPasswordReset: "/zresetuj-haslo",
};

export default routes;
