import UserTransactionsList from "@/components/lists/users/UserTransactionsList";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

export default function AdminClientTransactionsPage() {
  return (
    <Card sx={{ minHeight: "4rem", position: "relative" }}>
      <CardHeader title="Documents" />
      <Divider />
      <CardContent>
        <UserTransactionsList />
      </CardContent>
    </Card>
  );
}
