import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useContext } from "react";
import { DocumentCreatorContext } from "../../../context";
import { DocumentCreatorPayloadTypes } from "../../../context/actions";
import MultipleChoiceBoxForm from "./MultipleSelectionListForm";
import { FormNode } from "@/apollo/queries/forms/formTypes";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

type MultipleChoiceBoxProps = {
  preloadedData?: FormNode;
};

export default function MultipleChoiceBox({
  preloadedData,
}: MultipleChoiceBoxProps) {
  const { dispatch: documentCreatorDispatch } = useContext(
    DocumentCreatorContext
  );

  const addQuestionToDocument = () => {
    documentCreatorDispatch({
      type: DocumentCreatorPayloadTypes.TOGGLE_INPUT_MODAL,
      payload: {
        toggleInputModal: true,
      },
    });

    documentCreatorDispatch({
      type: DocumentCreatorPayloadTypes.SET_INPUT_MODAL_CHILDREN,
      payload: {
        inputModalChildren: (
          <MultipleChoiceBoxForm preloadedData={preloadedData} />
        ),
      },
    });
  };

  const label = preloadedData?.variableName || "Pole wielokrotnego wyboru";

  return (
    <ListItemButton onClick={addQuestionToDocument}>
      <ListItemIcon>
        <CheckBoxIcon />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
}
