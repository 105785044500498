import {
  GET_GENERATED_DOCUMENTS,
  GetGeneratedDocuments,
} from "@/apollo/queries/generatedDocuments/generatedDocumentsQuery";
import Spinner from "@/components/Spinner/spinner";
import routes from "@/router/routes";
import { getFormattedDate } from "@/utils/date";
import { useQuery } from "@apollo/client";
import { Box, Button, List, ListItem, ListItemText } from "@mui/material";
import dayjs from "dayjs";
import { Suspense } from "react";
import { useNavigate } from "react-router-dom";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default function GeneratedDocumentsList() {
  const { data, loading } = useQuery<GetGeneratedDocuments>(
    GET_GENERATED_DOCUMENTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const navigate = useNavigate();

  const rows = data?.generatedDocuments || [];

  return (
    <Suspense fallback={<Spinner />}>
      <Spinner loading={loading} data={data} />
      <List>
        <ListItem sx={{ gap: "2%", userSelect: "none", pointerEvents: "none" }}>
          <ListItemText
            sx={{
              width: "5%",
              display: {
                xs: "none",
                md: "initial",
              },
            }}
          >
            ID
          </ListItemText>
          <ListItemText
            sx={{
              width: "15%",
              display: {
                xs: "none",
                md: "initial",
              },
            }}
          >
            Created at
          </ListItemText>
          <ListItemText sx={{ width: "60%" }}>Nazwa</ListItemText>
          <ListItem sx={{ width: "20%" }}>Akcje</ListItem>
        </ListItem>
        {rows.map((row) => (
          <ListItem key={row.id} sx={{ gap: "2%", overflowX: "auto" }}>
            <ListItemText
              sx={{
                width: "5%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                wordBreak: "break-all",
                whiteSpace: "nowrap",
                maskImage:
                  "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 100%);",
                display: {
                  xs: "none",
                  md: "initial",
                },
              }}
            >
              {row.id}
            </ListItemText>
            <ListItemText
              sx={{
                width: "15%",
                display: {
                  xs: "none",
                  md: "initial",
                },
              }}
            >
              {getFormattedDate(row.createdAt, "DD/MM/YYYY HH:mm:ss")}
            </ListItemText>
            <ListItemText sx={{ width: "60%" }}>{row.displayName}</ListItemText>
            <ListItem sx={{ width: "20%" }}>
              <Box sx={{ gap: 1, display: "flex" }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(routes.admin.documents.id.make(row.id))
                  }
                >
                  Edytuj
                </Button>
                <Button
                  variant="contained"
                  onClick={() => navigate(routes.admin.forms.id.make(row.id))}
                >
                  formularz
                </Button>
              </Box>
            </ListItem>
          </ListItem>
        ))}
      </List>
    </Suspense>
  );
}
