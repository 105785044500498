import Form from "@/components/utils/Form/form";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";

import classes from "./classes.module.scss";
import { Suspense, useEffect, useState } from "react";
import Loading from "../loading";
import {
  RESET_PASSWORD,
  SignUpMutation,
} from "@/apollo/queries/user/userQuery";
import { object, string } from "yup";
import {
  FormErrors,
  getFormErrorsFromResponse,
  getFormErrorsFromValidation,
} from "@/utils/form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PageName } from "@/utils/app";
import routes from "@/router/routes";

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const params = new URLSearchParams(new URL(window?.location.href).search);
  const token = params.get("t");

  const resetPasswordSchema = object({
    newPassword: string().required(),
  });

  const [errors, setErrors] = useState<FormErrors>();

  const [resetPassword, { data: resetPasswordData, error }] =
    useMutation<SignUpMutation>(RESET_PASSWORD);

  useEffect(() => {
    if (resetPasswordData && !error) {
      toast.success("Twoje hasło zostało zaktualizowane", {
        autoClose: 5000,
        closeOnClick: false,
        closeButton: () => null,
        draggable: false,
      });

      toast.info("Za chwilę nastąpi przekierowanie.", { autoClose: 4000 });

      setTimeout(() => {
        navigate(routes.signIn);
      }, 4000);
    }
  }, [resetPasswordData, error, navigate]);

  useEffect(() => {
    if (error) {
      toast.error(
        "Nie udało się zresetować Twojego hasła. Upewnij się, że poprawnie wypełniłeś formularz lub link dalej jest ważny.",
        {
          autoClose: 10000,
          closeOnClick: false,
          closeButton: () => null,
          draggable: false,
        }
      );
    }
  }, [resetPasswordData, error, navigate]);

  useEffect(() => {
    setErrors(getFormErrorsFromResponse(error));
  }, [error]);

  return (
    <Suspense fallback={<Loading />}>
      <PageName>Zresetuj hasło</PageName>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "99vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
            width: "20rem",
          }}
        >
          <Typography variant="h1">Resetuj hasło</Typography>
          <Form
            style={{
              width: "100%",
            }}
            classes={classes}
            onSubmit={async (formData) => {
              const submitData = {
                ...formData,
              };
              try {
                await resetPasswordSchema.validate(submitData, {
                  abortEarly: false,
                });

                resetPassword({
                  variables: {
                    resetPasswordInput: {
                      ...submitData,
                      token,
                    },
                  },
                });
              } catch (error) {
                setErrors(getFormErrorsFromValidation(error));
              }
            }}
          >
            <TextField
              id="newPassword"
              label="Nowe hasło"
              error={!!errors?.get("newPassword")}
              helperText={errors?.get("newPassword")}
            />
            <Button type="submit" variant="contained">
              Resetuj
            </Button>
          </Form>
        </Box>
      </Box>
    </Suspense>
  );
}
