"use client";
import { FindGeneratedDocumentInput } from "@/apollo/queries/generatedDocuments/generatedDocumentInputTypes";
import {
  GET_GENERATED_DOCUMENT,
  GetGeneratedDocument,
} from "@/apollo/queries/generatedDocuments/generatedDocumentsQuery";
import DocumentReader from "@/components/DocumentReader/DocumentReader";
import { useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { Suspense } from "react";
import Loading from "../loading";
import { useParams } from "react-router-dom";

export default function AdminGeneratedDocumentPage() {
  const params = useParams<"id">();

  const { data, loading } = useQuery<GetGeneratedDocument>(
    GET_GENERATED_DOCUMENT,
    {
      variables: {
        findGeneratedDocumentInput: new FindGeneratedDocumentInput({
          id: params.id,
        }),
      },
    }
  );

  if (!data) return null;

  return (
    <Suspense fallback={<Loading />}>
      <Card sx={{ minHeight: "4rem", position: "relative" }}>
        <CardHeader title={data.generatedDocument.displayName} />
        <Divider />
        <CardContent>
          <DocumentReader
            data={data.generatedDocument}
            generatedDocumentId={params.id}
          />
        </CardContent>
      </Card>
    </Suspense>
  );
}
