import { Suspense, useCallback, useContext } from "react";
import classes from "./topBar.module.scss";
import Cookies from "js-cookie";
import { Box, Typography, useTheme } from "@mui/material";
import Logo from "@/components/Logo/Logo";
import Settings from "@mui/icons-material/Settings";

import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";

import { MenuDrawerContext } from "@/context/MenuDrawer/menuDrawerContext";
import { MenuDrawerReducerPayloadTypes } from "@/context/MenuDrawer/menuDrawerReducer";
import useWindow from "@/hooks/useWindow";
import { UserContext } from "@/context/User/userContext";
import { USER_ROLES } from "@/apollo/queries/user/types";
import { useNavigate } from "react-router-dom";
import routes from "@/router/routes";

export default function TopBar() {
  const window = useWindow();
  const theme = useTheme();
  const navigate = useNavigate();
  const { dispatch: menuDrawerDispatch } = useContext(MenuDrawerContext);
  const { state: userState } = useContext(UserContext);
  const isClient = userState?.role === USER_ROLES.USER;
  const prefix = isClient ? "client" : "admin";

  const toggleDrawer = useCallback(() => {
    menuDrawerDispatch({
      type: MenuDrawerReducerPayloadTypes.TOGGLE,
      payload: null,
    });
  }, [menuDrawerDispatch]);

  return (
    <Suspense fallback={<a>tests</a>}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          display: "flex",
          backgroundColor: "#ffffffdb",
          backdropFilter: "blur(4px)",
          borderBottom: "1px solid rgb(0 0 0 / 5%)",
          zIndex: 2,
        }}
        className={classes.root}
      >
        <div className={classes.head}>
          <Logo />
          {window.check((x) => x < 1024) && (
            <div onClick={toggleDrawer} className={classes.drawerToggle}>
              <MenuIcon
                sx={{
                  background: theme.palette.primary.light,
                  padding: 1,
                  color: theme.palette.primary.dark,
                  borderRadius: ".5rem",
                }}
              />
            </div>
          )}
        </div>
        <div className={classes.tail}>
          {window.check((x) => x > 1024) && (
            <>
              {isClient && (
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(routes.client.profile);
                  }}
                >
                  <Settings style={{ color: theme.palette.primary.main }} />
                </Box>
              )}
              <Typography
                variant="body1"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  Cookies.remove("user");
                  navigate(routes.signIn);
                  navigate(0);
                }}
              >
                <LogoutIcon />
              </Typography>
            </>
          )}
        </div>
      </Box>
    </Suspense>
  );
}
