"use client";
import FormRenderer from "@/components/FormRenderer";
import { UserContext } from "@/context/User/userContext";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { useParams } from "react-router-dom";

export default function ClientFormPage() {
  const params = useParams<"id">();
  const { state: userState } = useContext(UserContext);
  if (!userState?.subscription)
    return (
      <>
        <Typography>
          Musisz posiadać subskrypcję żeby móc tworzyć dokumenty!
        </Typography>
      </>
    );
  return <FormRenderer isClient documentId={params.id} />;
}
