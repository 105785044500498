import { gql } from "@apollo/client";
import { PaymentTransaction } from "./types";

export type GetPaymentTransactions = {
  paymentTransactions: PaymentTransaction[];
};

export type DownloadInvoice = {
  downloadInvoice: {
    file: string;
  };
};

const GET_PAYMENT_TRANSACTIONS = gql`
  query GetPaymentTransactions(
    $getPaymentTransactionsInput: GetPaymentTransactionsInput
  ) {
    paymentTransactions(
      getPaymentTransactionsInput: $getPaymentTransactionsInput
    ) {
      id
      product {
        name
      }
      status
      createdAt
    }
  }
`;

const DOWNLOAD_INVOICE = gql`
  mutation DownloadInvoice($downloadInvoiceInput: DownloadInvoiceInput!) {
    downloadInvoice(downloadInvoiceInput: $downloadInvoiceInput) {
      file
    }
  }
`;

export { GET_PAYMENT_TRANSACTIONS, DOWNLOAD_INVOICE };
