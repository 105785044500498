import dayjs from "dayjs";
import { Suspense, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  GET_GENERATED_DOCUMENTS,
  GetGeneratedDocuments,
} from "@/apollo/queries/generatedDocuments/generatedDocumentsQuery";
import Spinner from "@/components/Spinner/spinner";
import { getFormattedDate } from "@/utils/date";
import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { UserContext } from "@/context/User/userContext";
import routes from "@/router/routes";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

type UserGeneratedDocumentsListProps = {
  documentId: string;
};

export default function UserGeneratedDocumentsList({
  documentId,
}: UserGeneratedDocumentsListProps) {
  const { state: userState } = useContext(UserContext);

  const { data, loading } = useQuery<GetGeneratedDocuments>(
    GET_GENERATED_DOCUMENTS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        findGeneratedDocumentsInput: {
          documentId: documentId,
        },
      },
      skip: !userState?.id,
    }
  );

  const navigate = useNavigate();

  const rows = data?.generatedDocuments || [];

  if (!data?.generatedDocuments?.length && !loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 2,
        }}
      >
        <Typography>Nie posiadasz wygenerowanych dokumentów.</Typography>
        <Link to={routes.client.forms.id.make(documentId)}>
          <Button variant="contained">Wygeneruj dokument</Button>
        </Link>
      </Box>
    );
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Spinner loading={loading} data={data} />
      <List>
        <ListItem sx={{ gap: "2%", userSelect: "none", pointerEvents: "none" }}>
          <ListItemText
            sx={{
              width: "10%",
              display: {
                xs: "none",
                md: "initial",
              },
            }}
          >
            Created at
          </ListItemText>
          <ListItemText sx={{ width: "70%" }}>Nazwa</ListItemText>
          <ListItem sx={{ width: "20%" }}>Akcje</ListItem>
        </ListItem>
        {rows.map((row) => (
          <ListItem key={row.id} sx={{ gap: "2%", overflowX: "auto" }}>
            <ListItemText
              sx={{
                width: "10%",
                display: {
                  xs: "none",
                  md: "initial",
                },
              }}
            >
              {getFormattedDate(row.createdAt, "DD/MM/YYYY HH:mm:ss")}
            </ListItemText>
            <ListItemText sx={{ width: "70%" }}>{row.displayName}</ListItemText>
            <ListItem sx={{ width: "20%" }}>
              <Box sx={{ gap: 1, display: "flex" }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(routes.client.generatedDocuments.id.make(row.id))
                  }
                >
                  Zobacz
                </Button>
                <Button
                  color={row.requiresManualRegeneration ? "warning" : "primary"}
                  variant="contained"
                  onClick={() =>
                    navigate(
                      routes.client.generatedDocuments.id.form.make(row.id)
                    )
                  }
                >
                  {row.requiresManualRegeneration ? "Wygeneruj" : "Formularz"}
                </Button>
              </Box>
            </ListItem>
          </ListItem>
        ))}
      </List>
    </Suspense>
  );
}
