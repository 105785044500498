import { gql } from "@apollo/client";
import { LegalDocumentCategory } from "./types";

export type GetLegalDocumentsCategories = {
  legalDocumentsCategories: LegalDocumentCategory[];
};
export type SaveLegalDocumentsCategories = {
  legalDocumentsCategories: LegalDocumentCategory[];
};

const GET_LEGAL_DOCUMENTS_CATEGORIES = gql`
  query LegalDocumentsCategories {
    legalDocumentsCategories {
      id
      label
      slug
    }
  }
`;

const SAVE_LEGAL_DOCUMENTS_CATEGORIES = gql`
  mutation SaveLegalDocumentsCategories(
    $createLegalDocumentsCategoriesInput: CreateLegalDocumentsCategoriesInput!
  ) {
    saveLegalDocumentsCategories(
      createLegalDocumentsCategoriesInput: $createLegalDocumentsCategoriesInput
    ) {
      label
      slug
    }
  }
`;

export { GET_LEGAL_DOCUMENTS_CATEGORIES, SAVE_LEGAL_DOCUMENTS_CATEGORIES };
