"use client";
import { FindDocumentInput } from "@/apollo/queries/documents/documentInputTypes";
import {
  GET_DOCUMENT,
  GetDocument,
} from "@/apollo/queries/documents/documentsQuery";
import Spinner from "@/components/Spinner/spinner";
import { DocumentCreatorProvider } from "@/components/admin/DocumentCreator/context";
import { LexicalEditorProvider } from "@/context/LexicalEditor/context";
import { useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { Suspense } from "react";
import Loading from "../loading";

import DocumentCreator from "@/components/admin/DocumentCreator/DocumentCreator";
import { useParams } from "react-router-dom";

export default function AdminDocumentPage() {
  const params = useParams<"id">();

  const { data, loading } = useQuery<GetDocument>(GET_DOCUMENT, {
    variables: {
      findDocumentInput: new FindDocumentInput({ id: params.id }),
    },
    fetchPolicy: "network-only",
  });

  if (!data) return null;

  return (
    <Suspense fallback={<Loading />}>
      <Card sx={{ minHeight: "4rem", position: "relative" }}>
        <CardHeader title="Document creator" />
        <Divider />
        <CardContent>
          <Spinner loading={loading} data={data} />
          <LexicalEditorProvider>
            <DocumentCreatorProvider>
              <DocumentCreator preloadedData={data.document} />
            </DocumentCreatorProvider>
          </LexicalEditorProvider>
        </CardContent>
      </Card>
    </Suspense>
  );
}
